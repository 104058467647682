import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { SiteService } from '../../service/site.service';
import { NgForm } from '@angular/forms';
import { HttpService } from '@services/http.service';
import { DataService } from '@shared/data-service.service';
import { AddTrialComponent } from '../../add-trial/add-trial.component';
import { ColumnDataTypes, ColumnHeader } from '@shared/interfaces/tables';
import { ProductForm } from '@shared/interfaces/products';
import { InvoiceCoupon } from '@shared/interfaces/invoices';
import { StripeSubscription, StripeSubscriptionItem } from '@shared/interfaces/subscriptions';

@Component({
  selector: 'app-downgrade-product',
  templateUrl: './downgrade-product.component.html',
  styleUrls: ['./downgrade-product.component.css'],
})
export class DowngradeProductComponent implements OnInit {
  @Input() selectedSiteId;
  checkboxKeys: object;
  columnActions: Object;
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  currentSubscriptionColumnHeaders: ColumnHeader[];
  errorMessages: { [key: string]: string } = {};
  siteIsLock;
  @ViewChild('formRef') productForm: NgForm;
  @Output() backToCancel=new EventEmitter<any>();
  unableToDowngradeError: string;
  showError = false;
  siteId;
  products = [];
  coupons: InvoiceCoupon[] = [];
  selectedCoupon: Partial<InvoiceCoupon> = {};
  currentStripeSubscription: StripeSubscription;
  downgradePackages = [];
  filteredDowngradePackagesSubscription: StripeSubscriptionItem[];
  selectedPackage = null;
  currentRole;
  downgradeProducts = [];
  downgradeOn;
  isDowngrade;

  constructor(
    private siteService: SiteService,
    public modalService: NgbModal,
    public toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    public dataService: DataService) {

  }

  ngOnInit() {
    this.siteIsLock = this.siteService.siteIsLock;
    this.activatedRoute.params.subscribe(params => {
      if (params && params.siteId) {
        this.siteId = params.siteId;
        this.getData(params.siteId);
        this.getCurrentWebsiteSubscription();
      } else {
        this.siteId=this.selectedSiteId;
        this.getData(this.selectedSiteId);
        this.getCurrentWebsiteSubscription();
      }
      this.getCoupons();
    });

    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1].path;
    })

    this.getTableData();
  }

  get hasProducts(): boolean {
    return this.products.length > 0;
  }

  addPlanInformationToSubscriptionData(data: StripeSubscription): StripeSubscription {
    for (let i = 0; i < data.items.data.length; i++) {
      const {nickname, amount} = data.items.data[i].plan;

      data.items.data[i]['nickname'] = nickname;
      data.items.data[i]['amount'] = amount / 100;
    }

    return data;
  }

  getData(siteId){
    if(this.siteIsLock)
      this.getDownGradeProduct(siteId);
    else
      this.getPackagesAvailableToDowngradeTo(siteId);
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnActions = {
      nameAndDescription: this.updateQuantity.bind(this),
    };

    this.checkboxKeys = {
      nameAndDescription: 'checkbox',
    };

    this.columnDataTypes = {
      amount: 'currency',
      listPrice: 'currency',
      nameAndDescription: 'checkboxWithDescription',
      quantity: 'number',
      totalPrice: 'currency',
      unitPrice: 'currency',
    };

    this.columnHeaders = [
      { field: 'nameAndDescription', header: 'Name', width: 30 },
      { field: 'listPrice', header: 'List Price', filter: 'none' },
      { field: 'unitPrice', header: 'Unit Price', filter: 'none' },
      { field: 'quantity', header: 'Quantity', filter: 'none' },
      { field: 'totalPrice', header: 'Total Price', filter: 'none' },
    ];

    this.currentSubscriptionColumnHeaders = [
      { field: 'nickname', header: 'Product Name' },
      { field: 'amount', header: 'Unit Price', width: 15 },
      { field: 'quantity', header: 'Quantity', width: 15 },
    ];
  }

  resetPackage() {
    if (this.selectedPackage == null) {
      this.products = [];
    }
  }

  getPackagesAvailableToDowngradeTo(siteId) {
    this.siteService.getPackagesAvailableToDowngradeTo(siteId).subscribe(res => {
      if (res) {
        this.downgradePackages = res
      }
    }, err => {
      this.unableToDowngradeError = err.error.message;
    })
  }

  getProduct(siteId) {
    this.siteService.GetWebsiteProductToBeDowngradedIn(siteId).subscribe(res => {
      if (res) {
        this.downgradePackages = res;
      }
    }, (err) => {
      this.httpService.openErrorPopup(err.error.message);
    });
  }

  /**
   * Selects a new package to downgrade and retrieves the related products
   */
  submit(): void {
    if (this.productForm.valid) {
      this.products = [];
      const selectedPackage = JSON.parse(this.selectedPackage);

      this.siteService.getDowngradeProduct(
        this.siteId ? this.siteId : this.selectedSiteId,
        selectedPackage.packageId,
        selectedPackage.type
      ).subscribe(
        (res) => {
          if (res) {
            this.products = res;

            for (let i = 0; i < this.products.length; i++) {
              const { name, productCode, quantity, unitPrice } = this.products[i];
              this.products[i]['checkbox'] = false;
              this.products[i]['nameAndDescription'] = {
                text: name,
                description: productCode,
              };

              this.products[i]['totalPrice'] = quantity * unitPrice;
            }
          }
        },
        (err) => {
          this.httpService.openErrorPopup(err.error.message);
        }
      );
    } else {
      this.productForm.controls['product'].markAsTouched();
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
  }

  saveTableData() {
    let selectedPackage = JSON.parse(this.selectedPackage);
    let body = {
      // only send the product with a quantity. Endpoint now only expects a single product.
      downgradeProductInfo: this.products.find(x => x.quantity > 0),
      SiteId: this.siteId ? this.siteId : this.selectedSiteId,
      IntakeTypeId: selectedPackage.id,
    };

    if (this.selectedCoupon && this.selectedCoupon?.id) {
      body['StripeCouponId'] = this.selectedCoupon.id;
    }

    var model = this.modalService.open(AddTrialComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
      model.componentInstance.type = 'date';
      model.componentInstance.isMandatory = true;
      model.result.then((modelData) => {
        if(modelData.value){
          body['ToBeDowngradedOn'] = modelData.value;
          body['Prorate']=modelData.prorate;
          body['IsDowngrade']=true;
          this.siteService.downgradeProduct(body).subscribe(res => {
            if (res) {
              this.toastrService.success('Downgrade Scheduled', 'Success');
              this.siteIsLock=true;
              this.siteService.siteIsLock=this.siteIsLock;
              this.getDownGradeProduct(this.siteId);
            }
          }, err => {
            this.httpService.openErrorPopup(err.error.message);
          })
        }
      }, (reason) => {
        // console.log(`Dismissed: ${reason}`);
      });
  }

  callFromParent(site) {
    this.selectedSiteId = site.id;
    this.siteIsLock = site.isLock;
    this.selectedPackage = null;
    this.downgradePackages = [];
    this.products = [];
    this.downgradeProducts = [];
    this.getData(this.selectedSiteId);
    this.getCurrentWebsiteSubscription();
  }

  updateQuantity(product: ProductForm, quantity?): void {
    this.showError = false;

    if (product.isSelected == null || product.isSelected == undefined) {
      product.isSelected = false;
    }

    quantity ?
      product.isSelected = product.quantity == 0 ? false : true :
      product.quantity = product.isSelected ? product.quantity || 1 : 0;
      product.unitPrice=product.isSelected?
                      (product.family=='Basic'||product.family=='Enhanced'||product.family=='Premium')?
                        product.listPrice:product.unitPrice:0;

    product.totalPrice = product.unitPrice * product.quantity;

    // Sets the quantity of a product to 0
    for (let i=0; i < this.products.length; i++) {
      if (this.products[i].id != product.id) {
        this.products[i].isSelected = false;
        this.products[i].quantity = 0;
      }

      if(this.products[i].quantity<0||this.products[i].unitPrice<0|| this.products[i].quantity*this.products[i].unitPrice>=1000000){
        this.showError = true;
        this.httpService.openErrorPopup('Total Amount Must be between $ 0 and $1000000');
        break;
      }
    }
  }

  getDownGradeProduct(SiteId){
    this.siteService.getDownGradeProduct(this.siteId).subscribe(res=>{
      if (res) {
        this.downgradeProducts=res;
        this.downgradeOn=res[0].listDowngradeOn;
        this.isDowngrade=res[0].listIsDowngrade;

        this.filterSubscriptions();
      }
    },
    err => {
      this.httpService.openErrorPopup(err.error);
    });
  }

  async filterSubscriptions() {
    // Ensure we have the current subscription before proceeding
    if (!this.currentStripeSubscription || !this.currentStripeSubscription.items || !this.currentStripeSubscription.items.data) {
      await this.getCurrentWebsiteSubscription(); // Await for the subscription if it's not already available
    }
    
    // Now apply filtering if the subscription exists
    if (this.currentStripeSubscription && this.currentStripeSubscription.items && this.currentStripeSubscription.items.data) {
      this.filteredDowngradePackagesSubscription = this.currentStripeSubscription.items.data.filter(subscription =>
        subscription.plan.nickname.includes('Additional Location') || 
        subscription.plan.nickname.includes('ReviewGen')
      );
    }
  }
  
  

  isProductSelected(){
    return this.products.some(product => product.isSelected == true);
  }

  /**
   * Gets the current subscription to a package product (includes the current coupon)
   */
  getCurrentWebsiteSubscription(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.siteService.getCurrentPackageProductSubscription(this.siteId ? this.siteId : this.selectedSiteId).subscribe(
        (res) => {
          if (res) {
            this.currentStripeSubscription = this.addPlanInformationToSubscriptionData(res);

            // Find the coupon of the subscription in the list of coupons if there is one.
            if (this.currentStripeSubscription.discount != null) {
              const currentSubscriptionCouponIndex = this.coupons.findIndex(
                coupon => coupon.id === this.currentStripeSubscription.discount.coupon.id
              );

              if (currentSubscriptionCouponIndex > -1) {
                this.coupons[currentSubscriptionCouponIndex].couponName =
                  this.coupons[currentSubscriptionCouponIndex].couponName.concat(' - Current coupon');

                this.selectedCoupon = this.coupons.filter(
                  coupon => coupon.id === this.currentStripeSubscription.discount.coupon.id
                )[0];
              }
            }
          }
          resolve(); // Resolve the promise when done
        },
        (err) => {
          this.errorMessages['currentStripeSubscription'] = 'There has been an error retrieving' +
            ' Stripe Subscriptions.';
          this.httpService.handleError(true, err.error);
          reject(err); // Reject the promise in case of an error
        }
      );
    });
  }


  getCoupons() {
    this.siteService.getAllCoupons().subscribe(res => {
      if (res) {
        this.coupons = res;
      }}, err => {
      throw err;
    })
  }

  getCouponsForProduct(product: any): string[] {
    if (!product || !product.couponId) {
      return ["No Coupon Applied"];
    }
  
    let applicableCoupons = this.coupons.filter(coupon => product.couponId.includes(coupon.id));
  
    return applicableCoupons.map(coupon => coupon.couponName);
  }  

  getTotalDiscountedAfterCouponForProduct(product: any, valueFromOtherItem?: number): number {
    // Use product.totalPrice if valueFromOtherItem is null or undefined
    const basePrice = (valueFromOtherItem !== null && valueFromOtherItem !== undefined) 
      ? valueFromOtherItem 
      : product.totalPrice;
  
    if (!product || !product.couponId) {
      return basePrice; // Return original totalPrice if no coupons or product is invalid
    }
  
    let applicableCoupons = this.coupons
      .filter(coupon => product.couponId.includes(coupon.id)); // Filter coupons by matching ID
    let discountedTotalPrice = basePrice; // Start with the base price
  
    applicableCoupons.forEach(coupon => {
      if (coupon.amount !== null && coupon.amount !== undefined) {
        // If coupon amount is not null or undefined, subtract the fixed amount
        discountedTotalPrice -= coupon.amount;
      } else {
        // Otherwise, apply percentage discount
        let percentOff = coupon.percentOff;
        discountedTotalPrice -= (discountedTotalPrice * percentOff) / 100;
      }
    });
  
    // Ensure the discounted total price doesn't go below zero
    return Math.max(discountedTotalPrice, 0); 
  }  
}
