import * as _ from 'lodash';
import { IntakesService } from '../../../../services/intakes.service';


export class Associations {
    intakeId: number;
    tradeAssociations: any;
    otherAssociation: string;
    ltradeAssociations: object;
    affiliateLogo: string;


    constructor(info, intakeId) {
        this.intakeId = intakeId;
        this.affiliateLogo = info.affiliateLogo;
        this.tradeAssociations = info.ltradeAssociations ? this.getTradeAssociationsId(info.ltradeAssociations) : null;
        this.ltradeAssociations = info.ltradeAssociations ? this.getTradeAssociations(info.ltradeAssociations) : null;
        this.otherAssociation = info.otherAssociation || this.getTradeOtherValue(info.ltradeAssociations);
    }

/**
 * Filters the trade associations to include only those that are checked, 
 * then extracts their IDs and returns them as a comma-separated string.
 * 
 * @param tradeAssociation - An array of trade associations, each containing an `id` and `checked` property.
 * @returns A string of selected trade association IDs, separated by commas.
 * 
 * Example:
 * const tradeAssociations = [
 *   { id: 1, name: 'Association A', checked: true },
 *   { id: 2, name: 'Association B', checked: false },
 *   { id: 3, name: 'Association C', checked: true }
 * ];
 * 
 * getTradeAssociationsId(tradeAssociations); // Returns: "1, 3"
 */
    getTradeAssociationsId(tradeAssociation): string {
        return _.map(_.filter(tradeAssociation, { checked: true }), 'id').join(', ');
    }
    
    getTradeAssociations(tradeAssociation) {
        let tAssocation = _.filter(tradeAssociation, (association) => {
            return association.checked;
        });
        // tAssocation = _.toString(_.map(tAssocation, 'id'));
        return tAssocation;
    }

    getTradeOtherValue(tradeAssociation){
        let otherTradeAssoc = _.find(tradeAssociation, (association) => {
            return !_.isEmpty(association.otherTradeAssociation) && association.name.toLowerCase() == 'other';
        })
        return otherTradeAssoc ? otherTradeAssoc.otherTradeAssociation : '';
    }

}

