import { Component, OnInit, Input, Output, EventEmitter,Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { IntakesService } from '../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { SectionStatus } from '../basic/review/section-model';
import { Smm } from './smm-model';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../services/http.service';
@Component({
  selector: 'app-social-media-marketing',
  templateUrl: './social-media-marketing.component.html',
  styleUrls: ['./social-media-marketing.component.css']
})
export class SocialMediaMarketingComponent implements OnInit {

  @Input() submenu;
  @Input() intakeTypeId;
  @Input() menuId;
  @Output() changeStatus= new EventEmitter<any>(); 
  intakeId;
  smmForm: UntypedFormGroup;
  fieldData;
  fieldOther;
  specialServiceOther;
  hearingAidOther;
  selectedIntakeData;
  specialServiceData;
  hearingAidData;
  existingData;
  @Output() continue = new EventEmitter<any>();  
  checkRequiredField:boolean=false;
  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private _renderer2: Renderer2,
    private httpService: HttpService,
    public dataService:DataService,
  ) { 
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        this.getIntakeProductList(params.id);
      }
    });
  }

  getIntakeProductList(intakeId) {
    this.intakesService.getIntakeProductList(intakeId).subscribe((res) => {
      if (res) {
        this.selectedIntakeData = res;
        
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }


ngOnInit() {
  this.getSmmIntakeData(this.intakeId);
  this.initializeForm();
  this.getFieldData();
  this.getSpecialServiceData();
  this.getHearingAidData();
}

getSmmIntakeData(intakeId){
  this.intakesService.getSmmIntake(intakeId).subscribe((res) => {
    if (res) {
      this.existingData = res;
      
      this.initializeForm(this.existingData);
      this.setFieldControl();
      this.setSpecialServiceControl();
      this.setHearingAidControl();
    }
  },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
}

initializeForm(data?){
  this.smmForm = this.formBuilder.group({
    intakeId: this.intakeId,
    date : new UntypedFormControl(data && data.date ? new Date(data.date):null),
    website: new UntypedFormControl(data ? data.website : null),
    email: new UntypedFormControl(data ? data.email : null),
    fields: new UntypedFormArray([]),
    specialtyServices: new UntypedFormArray([]),
    otherSpecialtyServices: new UntypedFormControl(data ? data.otherSpecialtyServices : null),
    hearingAidManufacturers: new UntypedFormArray([]),
    otherHearingAidManufacturers: new UntypedFormControl(data ? data.otherHearingAidManufacturers : null),
  })
}

fromJsonDate(jDate) {
  const bDate: Date = new Date(jDate);
  return bDate.toISOString().substring(0, 10);  //Ignore time
}

setFieldControl(){
  if(this.fieldData){
    this.smmForm.setControl('fields', this.formBuilder.array(this.fieldData.map(c => this.formBuilder.group({
      id : new UntypedFormControl(c.id),
      checked: new UntypedFormControl(this.getCheckedValue(c.id, 'lFields', 'checked')),
      name: new UntypedFormControl(this.getCheckedValue(c.id, 'lFields', 'name') || c.name)
    }))))
  }
    
  
}

getFieldData(){
  this.intakesService.getFieldData().subscribe((res) => {
    if (res) {
      this.fieldData = res['masterList'];
      this.setFieldControl();
      this.fieldOther = _.find(this.fieldData, (field) => {
        return field.name.toLowerCase() == 'other';
      });
    }
  },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
}

getCheckedValue(id, key, control){
  if(this.existingData){
    let filteredElement = _.find(this.existingData[key], (dataItem) => {
      return dataItem.id == id;
    })
    if(filteredElement){
      if(control == 'checked'){
        return true;
      }else{
        return filteredElement.name;
      }
    }
  }
    
  
}

setSpecialServiceControl(){
  if(this.specialServiceData){
    this.smmForm.setControl('specialtyServices', this.formBuilder.array(this.specialServiceData.map(c => this.formBuilder.group({
      id : new UntypedFormControl(c.id),
      checked: new UntypedFormControl(this.getCheckedValue(c.id, 'lSpecialtyServices', 'checked')),
      name: new UntypedFormControl(this.getCheckedValue(c.id, 'lSpecialtyServices', 'name') || c.name),
      otherSpecialtyServices: new UntypedFormControl(this.existingData ? this.existingData.otherSpecialtyServices : null)
    }))))
  }
  
}

getSpecialServiceData(){
  this.intakesService.getSpecialServiceData().subscribe((res) => {
    if (res) {
      this.specialServiceData = res['masterList'];
      this.setSpecialServiceControl();
      this.specialServiceOther = _.find(this.specialServiceData, (service) => {
        return service.name.toLowerCase() == 'other';
      });
    }
  },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
}

setHearingAidControl(){
  if(this.hearingAidData){
    this.smmForm.setControl('hearingAidManufacturers', this.formBuilder.array(this.hearingAidData.map(c => this.formBuilder.group({
      id : new UntypedFormControl(c.id),
      checked: new UntypedFormControl(this.getCheckedValue(c.id, 'lHearingAidManufacturers', 'checked')),
      name: new UntypedFormControl(this.getCheckedValue(c.id, 'lHearingAidManufacturers', 'name') || c.name),
      otherHearingAidManufacturers: new UntypedFormControl(this.existingData ? this.existingData.otherHearingAidManufacturers : null)
    }))))
  }
  
}

getHearingAidData(){
  this.intakesService.getHearingAidsData().subscribe((res) => {
    if (res) {
      this.hearingAidData = res['masterList'];
      this.setHearingAidControl();
      this.hearingAidOther = _.find(this.hearingAidData, (aid) => {
        return aid.name.toLowerCase() == 'other';
      });
    }
  },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
}

getStringId(data) {
  let filteredData: any = _.filter(data, (item) => {
      return item.checked;
  });
  filteredData = _.toString(_.map(filteredData, 'id'));
  return filteredData;
}

isFieldValid(field: string,index:number =0) {
    
  let control=this.smmForm.get('specialtyServices');
  let controlHearing=this.smmForm.get('hearingAidManufacturers');

   if(field=='otherSpecialtyServices'){
     for (let i=index;i<control['controls'].length;i++){ 
       return !control['controls'][i].controls.otherSpecialtyServices.valid && control['controls'][i].controls.otherSpecialtyServices.touched; 
     }
   }else if(field=='otherHearingAidManufacturers'){
    for (let i=index;i<controlHearing['controls'].length;i++){ 
      return !controlHearing['controls'][i].controls.otherHearingAidManufacturers.valid && controlHearing['controls'][i].controls.otherHearingAidManufacturers.touched; 
    }
   }else{
    return !this.smmForm.get(field).valid && this.smmForm.get(field).touched;
  }
}

displayFieldCss(field: string) {
  return {
    'has-error': this.isFieldValid(field),
    'has-feedback': this.isFieldValid(field)
  };
}
validateAllFormFields(formGroup: any) {

  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof UntypedFormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof UntypedFormGroup) {
      this.validateAllFormFields(control);
    } else if (control instanceof UntypedFormArray){
      for (let i=0;i<control.controls.length;i++){
        this.validateAllFormFields(control.controls[i]);
      }
    }

  });
}

saveFormData(form, mode?) {

  if(this.smmForm.valid){
    
    // form.value.date = form.value.date.getMonth()+1+"/"+form.value.date.getDate()+"/"+form.value.date.getFullYear();
  let intakeData = new Smm(form.value, this.intakeId);
  if(intakeData.fields =='' || intakeData.fields ==null){
    this.checkRequiredField=false;
    this.httpService.openErrorPopup(this.dataService.requiredMsg);
  }else{
    this.checkRequiredField=true;
  }

  if(this.checkRequiredField){
    this.intakesService.savesmmIntake(intakeData).subscribe(res => {
      if (res) {
        this.changeStatus.next();
        this.toastrService.success(this.dataService.successMsg, 'Success');
        this.intakesService.updateRequiredFieldsStatus(intakeData);
        let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.submenu[0].id, this.intakeId);
        this.postIntakeSection(sectionStatus);
        if(mode == 'continue'){
          this.goToNext('parent');
        }
      }

    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }
  }else{
      
      this.validateAllFormFields(this.smmForm);
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
  }

}

getProductSectionData(familyId, intakeId) {
  this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
    if (!_.isEmpty(res)) {
      this.intakesService.basicMenu = this.intakesService.groupMenu(res);
      this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
      // this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
      this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

    } else {
      this.toastrService.error("Site doesn't exist", 'Error');
    }
  })
}

postIntakeSection(sectionStatus) {
  this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
    if (res) {
      this.getProductSectionData(this.intakeTypeId, this.intakeId);
    }
  },
    err => {
      this.httpService.openErrorPopup(err.error.message);
    });
}

goToNext(menu){
  this.continue.next(menu);
}
ngAfterViewInit() {
  this.addSendibleConnect();
}

addSendibleConnect() {
  let script = this._renderer2.createElement('script');
  let widget = document.getElementById('client-connect-widget');
  script.type = `text/javascript`;
  script.text = `!function(){var e=document,t=e.createElement("iframe");window.addEventListener("message",function(e){if(!e || !e.data || !e.data.height){return;}t.height=(parseInt(e.data.height, 10)+45)+"px"},!1),t.style.border="0",t.style.width="100%",t.id="client-connect-iframe",t.src="https://api.sendible.com/api/v2/client_connect_widget.json?id=89",e.getElementById("client-connect-widget").appendChild(t)}();
  `;
  this._renderer2.appendChild(widget.parentNode, script);
} 

}
