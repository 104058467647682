import { Component, OnInit, Input, ViewChild, EventEmitter } from '@angular/core';
import { PaymentService } from '../payment/services/payment.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { SiteService } from '../../site/service/site.service';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { HttpService } from '@services/http.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CorporatePartnerService } from '../../admin/manage-corporate-partner/services/corporate-partner.service';
import { roles } from '@shared/master-enum.enum';
import { CorporatePartner } from '@shared/interfaces/client';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';
import { PaymentHistory, PaymentHistoryFilter } from '@shared/interfaces/billing';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css'],
  providers: [DatePipe],
})
export class PaymentHistoryComponent implements OnInit, PageHasTable {
  @Input() corporatePartners: CorporatePartner[];
  @Input() selectedSiteId: number | string;

  @ViewChild('dt') table: TableGenericComponent;

  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  nestedTableColumnHeaders?: ColumnHeader[];
  nestedTableData: Array<object>;
  nestedTableDataType: ColumnDataTypes;
  nestedTableQuery: EventEmitter<any>;
  roles = roles;
  siteIsLock;
  userInfo = localStorage.getItem('userInfo') != null
    ? JSON.parse(localStorage.getItem('userInfo'))
    : { token: '', userId: null, roleId: null };
  paymentHistory: PaymentHistory[];
  paymentHistoryProcessed: PaymentHistory[] = [];
  corporatePartner;
  allPayment;
  filterBy: PaymentHistoryFilter = {
    site: null, // Initialize site to null instead of 'all'
    status: 'all',
    siteName: null,
    paymentBy: '',
  };
  loading = true;
  startDate = null;
  endDate = null;
  status = null;
  siteId = null;
  sites;
  allSites;
  products;
  currentRole;
  invoiceStatus;

  constructor(
    public paymentService: PaymentService,
    public toastrService: ToastrService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private corporatePartnerService: CorporatePartnerService,
    private siteService: SiteService
  ) {
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1].path;
    });
  }

  ngOnInit() {
    this.siteIsLock = this.siteService.siteIsLock;

    this.filterBy['status'] = this.activatedRoute.snapshot.queryParamMap.get('status');
    this.filterBy['startDate'] = this.activatedRoute.snapshot.queryParamMap.get('startDate');
    this.filterBy['endDate'] = this.activatedRoute.snapshot.queryParamMap.get('endDate');

    this.siteId = this.selectedSiteId;

    this.filterBy['site'] = this.siteId;

    if (this.userInfo.roleId == 11 || this.userInfo.roleId == 13) {
      const site = JSON.parse(localStorage.getItem('selectedSite'));
      this.siteId = site ? site.siteId : null;
      this.filterBy['site'] = this.siteId;
    }

    this.getPaymentHistory(this.filterBy);
    this.getInvoiceStatus();

    if (this.userInfo.roleId < 11) {
      this.corporatePartners = this.corporatePartners;
    }

    this.getTableData();
  }

  /**
   * Retrieves payment history data
   *
   * @param {PaymentHistoryFilter} filterBy
   */
  getPaymentHistory(filterBy: PaymentHistoryFilter) {
    filterBy['formattedStartDate'] = this.datePipe.transform(filterBy.startDate, 'MM-dd-yyyy');
    filterBy['formattedEndDate'] = this.datePipe.transform(filterBy.endDate, 'MM-dd-yyyy');

    this.paymentService.getPaymentList(filterBy).subscribe(
      (res) => {
        this.paymentHistoryProcessed = [];

        if (res) {
          res.map((paymentHistory) => {
            if (paymentHistory.paymentBy == 0) {
              paymentHistory.paymentBy = 'Program';
            } else {
              paymentHistory.paymentBy = 'Client';
            }

            this.paymentHistoryProcessed.push(paymentHistory);
          });

          this.paymentHistory = res;

          this.allPayment = Object.assign([], this.paymentHistory);
          this.loading = false;
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
        this.loading = false;
      }
    );
  }

  applyFilter(site) {
    this.filterBy['siteName'] = site.businessName;
    this.filterBy['site'] = this.siteId;
    this.getPaymentHistory(this.filterBy);
  }

  getCorporatePartner() {
    this.corporatePartnerService.getCorporatePartners().subscribe(
      (res) => {
        if (res) {
          this.corporatePartner = res;
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  getTableData() {
    this.columnDataTypes = {
      invoiceCreatedOn: 'date',
      invoiceId: 'invoiceUrlLink',
      paymentOn: 'date',
      totalAmount: 'currency',
    };

    this.columnHeaders = [
      { field: 'siteName', header: 'Business Name' },
      { field: 'totalAmount', header: 'Amount' },
      { field: 'invoiceCreatedOn', header: 'Invoice Creation Date' },
      { field: 'invoiceStatus', header: 'Invoice Status', filter: 'select' },
      { field: 'paymentStatus', header: 'Payment Status', filter: 'select' },
      { field: 'invoiceId', header: 'Invoice URL' },
      { field: 'paymentOn', header: 'Payment Date' },
      { field: 'paymentBy', header: 'Billed To', filter: 'select' },
    ];

    this.nestedTableColumnHeaders = [
      { field: 'productName', header: 'Product Name' },
      { field: 'quantity', header: 'Quantity' },
      { field: 'amount', header: 'Amount' },
    ];

    this.nestedTableDataType = {
      amount: 'currency',
    };
  }

  resetFilter(obj?: PaymentHistoryFilter) {
    this.table.clearFilters();

    this.filterBy = {
      site: this.siteId,
      status: '',
      siteName: null,
      paymentBy: '',
    };

    this.filterBy['status'] = this.activatedRoute.snapshot.queryParamMap.get('status');

    if (this.activatedRoute.snapshot.queryParamMap.get('startDate')) {
      this.filterBy['startDate'] = new Date(this.activatedRoute.snapshot.queryParamMap.get('startDate'));
    } else {
      this.filterBy['startDate'] = null;
    }

    if (this.activatedRoute.snapshot.queryParamMap.get('endDate')) {
      this.filterBy['endDate'] = new Date(this.activatedRoute.snapshot.queryParamMap.get('endDate'));
    } else {
      this.filterBy['endDate'] = null;
    }

    this.getPaymentHistory(this.filterBy);
  }

  paymentStatusIsComplete(payment: PaymentHistory): boolean {
    return ['Failed', 'draft'].includes(payment.paymentStatus);
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 3 ? [] : this.allSites.filter((v) => v['businessName'].toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
      )
    );
  formatter = (result: string) => `${result['businessName']}`;

  inputFormatter = (result: string) => `${result['businessName']}`;

  callFromParent(site: any): void {
    if (site) {
      // When a specific site is selected
      this.selectedSiteId = site.id;
      this.siteIsLock = site.isLock;
      this.filterBy['site'] = site.id;
      this.siteId = site.id;
    } else {
      // When no site is selected, set the filter to null to fetch payment history for all sites
      this.selectedSiteId = null;
      this.siteId = null;
      this.filterBy['site'] = null; // Set site to null instead of 'all'
    }

    // Fetch payment history based on the current filter
    this.getPaymentHistory(this.filterBy);
  }

  payInvoice(siteId, invoiceId) {
    const data = { siteId: siteId, invoiceId: invoiceId };
    this.paymentService.Charge(data).subscribe(
      (res) => {
        if (res) {
          this.getPaymentHistory(this.filterBy);
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  getInvoiceStatus() {
    this.paymentService.getGeneralMasterByType(13).subscribe(
      (res) => {
        if (res) {
          this.invoiceStatus = res;
        }
      },
      (err) => {
        throw err;
      }
    );
  }
}
