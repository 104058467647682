import {
  Component, ElementRef,
  Input,
  OnInit, Renderer2,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { SiteInfo } from '@shared/interfaces/site';
import { EmptyUser, ImpersonateUserConfig, UserInfo, UserProfile } from '@shared/interfaces/user';
import { ActivatedRoute, Router } from '@angular/router';
import { roles } from '@shared/master-enum.enum';
import { UserService } from '../../../../components/admin/users/services/user-service.service';
import { TicketService } from '../../../../components/ticket/service/ticket.service';
import * as _ from 'lodash';
import { WordPressHelper } from '@shared/utilities/WordPressUtilities';
import { hasPermission } from '@shared/policies/permissions';
import { SiteService } from '../../../../components/site/service/site.service';

@Component({
  selector: 'app-glyphs-client-actions',
  templateUrl: './glyphs-client-actions.component.html',
  styleUrls: ['./glyphs-client-actions.component.css'],
})
export class GlyphsClientActionsComponent implements OnInit, OnChanges {
  @Input() currentUser ?: EmptyUser | UserInfo;
  @Input() enabled = true;
  @Input() parentInfo;
  @Input() site: Partial<SiteInfo>;
  @Input() showBilling = true;
  @Input() showConfig = true;
  @Input() showImpersonation = false;
  @Input() showJira = true;
  @Input() showReports = true;
  @Input() showWordPress = true;

  private jiraURL: string;
  private jiraProjectKey: string;
  private userRole: string;

  public hasPermission = hasPermission;
  public userRoleId: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private siteService: SiteService,
    private ticketService: TicketService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,  // Added ChangeDetectorRef for manual change detection
  ) { }

  ngOnInit(): void {
    this.userRole = this.userService.getCurrentUserRole();
    this.userRoleId = Number(this.currentUser?.roleId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.site) {
      if (this.site) {
        this.updateActionsForNewSite();
      } else {
        this.handleSiteReset();
      }
      this.cdr.detectChanges(); // Explicitly trigger change detection
    }
  }

  updateActionsForNewSite(): void {
    if (this.site && !this.site.hasOwnProperty('id')) {
      this.site = this.siteService.normalizeIds(this.site);
    }
    this.enabled = true; // Enable actions when a valid site is selected
    this.cdr.detectChanges(); // Ensure view updates correctly
  }

  handleSiteReset(): void {
    this.enabled = false; // Disable actions when site is reset
    this.userRoleId = null; // Reset any other internal properties that depend on the site
    this.cdr.detectChanges(); // Ensure view updates correctly
  }

  get canImpersonateUser(): boolean {
    if (this.enabled && this.site?.userId > 0) {
      return hasPermission(this.userRoleId, 'IMPERSONATE_USER') && !this.parentInfo;
    }
    return false;
  }

  get hasWPSite(): boolean {
    return (
      this.site &&
      this.site.hasOwnProperty('wpSitePath') &&
      this.site.wpSitePath &&
      this.site.wpSitePath !== ''
    );
  }

  getUserProfile(user: ImpersonateUserConfig): void {
    this.userService.getUserProfile(user.userId).subscribe(
      (res: UserProfile) => {
        if (res) {
          this.userService.setUserProfile(res);

          if (res.site.length && user.roleId != roles.corporatePartner.roleId) {
            const impersonateSite = _.find(res.site, (site) => {
              return site.siteId == localStorage.getItem('impersonateSite');
            });

            const site = {
              siteId: impersonateSite?.siteId || res.site[0].siteId,
              domainUrl: impersonateSite?.domainUrl || res.site[0].domainUrl,
            };

            localStorage.setItem('selectedSite', JSON.stringify(site));
          }

          this.goToDashboard(user);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  goToBillingSection(site: SiteInfo): void {
    if (this.enabled) {
      localStorage.setItem('selectedSite', JSON.stringify(site));
      this.router.navigateByUrl(`${this.userService.currentUserRole}/billing`);
    }
  }

  goToDashboard(res): void {
    const role = _.find(roles, (role) => {
      return role.roleId == res.roleId;
    });
    if (role) {
      this.router.navigateByUrl(role.url);
    }
  }

  goToJiraDashboard(site: SiteInfo): void {
    if (this.enabled) {
      this.ticketService.getJiraUrl().subscribe(
        (res) => {
          if (res) {
            this.jiraURL = `${res['url']}/jira/core`;
            this.jiraProjectKey = res['ProjectKey'];

            window.open(
              `${this.jiraURL}/projects/${this.jiraProjectKey}${site.id}/board`,
              '_blank'
            );
          }
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }

  goToReports(site: SiteInfo): void {
    if (this.enabled) {
      localStorage.setItem('selectedSite', JSON.stringify(site));
      this.router.navigateByUrl(`${this.userRole}/client-reports`);
    }
  }

  impersonateUser(site: SiteInfo): void {
    if (this.enabled) {
      localStorage.setItem('parentInfo', localStorage.getItem('userInfo'));

      const data = {
        userId: site.userId,
      };

      this.userService.impersonateUser(data).subscribe(
        (userConfig: ImpersonateUserConfig) => {
          if (userConfig) {
            localStorage.setItem('userInfo', JSON.stringify(userConfig));
            localStorage.setItem('impersonateSite', JSON.stringify(site.id));
            localStorage.setItem('selectedSite', JSON.stringify(site));
            this.getUserProfile(userConfig);
          }
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }

  logInToWordPress(site: SiteInfo): void {
    if (this.enabled && this.hasWPSite) {
      const WordPress = new WordPressHelper(
        this.el,
        this.renderer,
        this.userService
      );

      WordPress.logInToWordPress(this.currentUser, site);
    }
  }

  viewSiteConfig(site: SiteInfo): void {
    if (this.enabled) {
      this.router.navigate(
        ['../site-config/', site.id],
        { relativeTo: this.activatedRoute }
      );
    }
  }
}
