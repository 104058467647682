import { Component, OnInit, Input, ViewChild, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ReportingService } from '../services/reporting.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ExcelService } from '../services/excel.service';
import * as _ from 'lodash';
import moment from 'moment';
import { Table } from 'primeng/table';
import * as html2pdf from 'html2pdf.js';
import { HttpService } from '../../../services/http.service';
import { ConvertToHoursPipe } from '@shared/pipes/secondsToHours.pipe';
import { DatePipe } from '@angular/common';
import { MasterService } from '../../admin/masters/services/master.service';
import { SiteService } from '../../site/service/site.service';
import { CallTrackingInfo } from "@shared/interfaces/call_tracking";
import { CallDetailsUserInterface } from '@shared/interfaces/interfaces';
import { SummaryData } from '@shared/interfaces/call_tracking';
import { CallTrackingService } from '@shared/services/call-tracking.service';
import * as tooltipText from '@shared/text/tooltips.json';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';

@Component({
  selector: 'app-call-tracking-report',
  templateUrl: './call-tracking-report.component.html',
  styleUrls: ['./call-tracking-report.component.css'],
})
export class CallTrackingReportComponent implements OnInit, OnChanges, PageHasTable {
  loading = false;
  @Input() siteId: any = null;
  @Input() params: any = null;
  @ViewChild('dt') dt: Table;

  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  callTrackingInfo: CallTrackingInfo[];
  callTrackingSummaryData: SummaryData;
  isAudioPlay;
  reports;
  filterBy = {
    aD_Source: '',
    apptSet: '',
    location: '',
    missedOpp: '',
    mobile: '',
    prospect: '',
    result: '',
  };
  callSources;
  today = new Date();
  audio = new Audio();
  reportParams = {
    callSourceId: 0,
    startDate: moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY'),
    endDate: moment(moment()).subtract(1, 'months').endOf('month').format('MM/DD/YYYY'),
    _startDate: moment().subtract(1, 'months').startOf('month').format('MM/DD/YY'),
    _endDate: moment(moment()).subtract(1, 'months').endOf('month').format('MM/DD/YY'),
    adSource: '',
  };
  locationsData;
  selectedSorting = 'Location';
  adSources;
  ttText: any;

  @ViewChild('dt') table: TableGenericComponent;

  constructor(
    private reportingService: ReportingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private masterService: MasterService,
    private siteService: SiteService,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private excelService: ExcelService,
    private callTrackingService: CallTrackingService,
    private cdr:ChangeDetectorRef
  ) { }

  ngOnChanges() {
    if (this.params) {
      Object.assign(this.reportParams, this.params);
    }

    this.getLocationsData(this.siteId);
    this.getAdSourceData();
    this.getCallSourceDetails(this.reportParams);
  }

  ngOnInit() {
    this.ttText = (tooltipText as any).default;

    this.getTableData();
  }

  convertTime(input) {
    return moment(input, 'HH:mm:ss').format('h:mm A');
  }

  /**
   * @deprecated Use the Phone pipe if possible
   *
   * @see {@link DatePipe#transform}
   * @param {string} phoneNumberString
   */
  formatPhoneNumber(phoneNumberString) {
    try {
      if (phoneNumberString != null) {
        phoneNumberString = phoneNumberString.replace(/-/g, '');
      }
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
    }
    catch (e) {
      return phoneNumberString;
    }

    return null;
  }

  getLocationsData(siteId) {
    this.reportingService.getCallTracking(siteId).subscribe(
      (res: any) => {
        if (res) {
          // Use Map to efficiently group locations by customerName
          const groupedLocations = res.reduce((groupMap, location) => {
            // Check if the current customerName exists in the Map
            if (groupMap.has(location.customerName)) {
              // If found and isActive is true, add the id to the existing array
              if (location.isActive) {
                groupMap.get(location.customerName).ids.push(location.id);
              }
            } else {
              // If not found and isActive is true, create a new entry with customerName and an array with the current id
              if (location.isActive) {
                groupMap.set(location.customerName, { customerName: location.customerName, ids: [location.id] });
              }
            }

            return groupMap;
          }, new Map<string, { customerName: string, ids: string[] }>());

          // Convert the Map values to an array
          this.locationsData = Array.from(groupedLocations.values());
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }


  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      appointment_Set: 'yesNoUndetermined',
      audio_URL: 'audioLink',
      caller_Number: 'phone',
      callSourceDateTime: 'dateLong',
      duration: 'duration',
      localDate: 'dateLong',
      localTime: 'time',
    };

    this.columnHeaders = [
      { field: 'location', header: 'Practice / Location', width: 20 },
      { field: 'localDate', header: 'Call Date', filter: 'none' },
      { field: 'localTime', header: 'Call Time', filter: 'none', width: 7 },
      { field: 'duration', header: 'Duration', filter: 'none', width: 7 },
      { field: 'audio_URL', header: 'Audio', filter: 'none', width: 7 },
      { field: 'caller_Number', header: 'Caller Number' },
      { field: 'caller_name', header: 'Caller Name' },
      { field: 'aD_Source', header: 'Ad Source', filter: 'select' },
      { field: 'prospect_Non_Prospect', header: 'Lead Type', filter: 'select' },
      { field: 'appointment_Set', header: 'Appt. Set', filter: 'select' },
    ];
  }

  getCallSourceDetails(reportParams) {
    let that=this;
    this.reportingService.getCallSourceDetails(this.siteId, reportParams).subscribe((res: any) => {
      if (res) {
        that.setCallSourceData(res);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  setCallSourceData(res){
    this.reports={};
    this.callSources={};
    this.reports = res;
    this.callSources =res["callSources"];
    this.getAdSourceData(); //reset the adSource list so it has all original 5 adSource services
    this.getConfiguredAdSources(this.siteId); // filter the list so we only get the ones that are configured to this client
  }

  getConfiguredAdSources(siteId: string): void {
    this.siteService.getCallTracking(siteId).subscribe(
      (res: CallTrackingInfo[]) => {
        if (res) {
          // Filter items where isActive is true and extract adSource values
          const filteredAdSources = Array.from(
            new Set(
              res
                .filter((item) => item.isActive === true)
                .map((item) => item.adSource)
            )
          );

          // Update this.adSources by keeping only those from the master list
          this.adSources = this.adSources.filter(element =>
            filteredAdSources.some(str => element.name.includes(str))
          );
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  onValueChange(event) {
    this.reportParams.startDate = moment(event[0]).format('MM/DD/YYYY');
    this.reportParams.endDate = moment(event[1]).format('MM/DD/YYYY');
    this.reportParams._startDate = moment(event[0]).format('MM/DD/YY');
    this.reportParams._endDate = moment(event[1]).format('MM/DD/YY');

    this.getCallSourceDetails(this.reportParams);
  }

  applySorting(params, label) {
    this.selectedSorting = label;

    const topBar = document.getElementById('sortby');

    topBar.classList.remove('show');
  }

  playAudio(callSource, action) {
    _.forEach(this.reports.callSources, (source) => {
      source.isAudioPlay = false;
    });
    this.audio.pause();
    if (action == 'play') {
      this.audio = new Audio();
      this.audio.src = callSource.audio_URL;
      this.audio.load();
      this.audio.play();
      callSource.isAudioPlay = !callSource.isAudioPlay;
    }
  }

  getAdSourceData(){
    this.masterService.getMastersListById(45).subscribe((res: any) => {
      if (res) {
        this.adSources = res['masterList'];
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  downloadReport(report: string) {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = 'none';
    var collapsedElements = document.getElementsByClassName('collapse');
    _.forEach(collapsedElements, (element) => {
      element.className += ' show';
    })
    var element = '<div style="padding: 20px;">' + document.getElementById('report').innerHTML + '<div class="listing call-track-overview"><div class="listing-heading"><h3 style="padding: 10px 27px;">Call Details</h3></div></div></div>';
    var opt = {
      margin: 0,
      filename: 'call-tracking.pdf',
      jsPDF: { unit: 'in', format: 'b3', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
    };
    let values = [];
    let keys = [
      'Practice/ Location',
      'Result',
      'Call Date/ Time',
      'Duration',
      'Audio',
      'Caller Number',
      'caller_name',
      'Ad Source',
      'Appt.Set',
      'Lead Type',
      'CS Lead Score'
    ];

    let tableData = this.getCallSourceData();
    _.forEach(tableData, (data) => {
      let value = _.values(data);
      values.push(value);
    })

    var output = 'save';
    if (report == 'print') {
      output = 'dataurlnewwindow';
    }
    var worker = html2pdf().from(element).set(opt);
    worker.toPdf().get('pdf').then((pdf) => {
      pdf.autoTable({
        headStyles: { fillColor: '#DCE0EB', textColor: '#4D4F5C' },
        head: [keys],
        body:
          values,
        columnStyles: {
          // practice/location
          0: { minCellWidth: 1.4 },
          // result
          1: { minCellWidth: 1, textColor: '#1AA69A' },
          // call date/time
          2: { minCellWidth: 1.2 },
          // duration
          3: { minCellWidth: 1 },
          // audio
          4: { minCellWidth: 2.2 },
          // caller number
          5: { minCellWidth: 1.5 },
          // caller name
          6: { minCellWidth: 1.5 },
          // ad source
          7: { minCellWidth: 1.2 },
          // appt set
          8: { minCellWidth: 1 },
          // lead type
          9: { minCellWidth: 1.5 },
          // callsource lead score
          10: { minCellWidth: 1.5 }
        },
        startY: 5,
        margin: 0.2,
        startX: 0
      });
    }).output(output, 'call-tracking.pdf');
    document.getElementById("downloadButton").style.display = 'block';
  }

  getCallSourceData() {
    const convertToHoursPipeFilter = new ConvertToHoursPipe();
    let datePipeFilter = new DatePipe('en-US');
    let callSources = [];

    _.forEach(this.reports.callSources, (source) => {
      const obj = {
        'location': source.location,
        'result': source.result,
        'date_time': datePipeFilter.transform(source.localDate, 'MMMM d, y') + ' ' + this.convertTime(source.localTime),
        'duration': source.duration?convertToHoursPipeFilter.transform(source.duration):'',
        'audio': source.audio_URL,
        'callNumber': this.formatPhoneNumber(source.caller_Number),
        'caller_name': source.caller_name,
        'adSource': source.aD_Source,
        'apptSet': source.appointment_Set != "N/A" ? source.appointment_Set : source.missed_Opp,
        'prospect': source.prospect_Non_Prospect,
        'leadScore': source.leadScore
      };

      callSources.push(obj);
    });
    return callSources;
  }

  applyFilter(filterBy) {
    let filteredCallSource = this.reports.callSources;

    if (filterBy) {
      if (!_.isEmpty(filterBy.mobile)) {
        filteredCallSource = _.filter(filteredCallSource, (user) => {
          return _.includes(user['caller_Number'], filterBy.mobile);
        });
      }
      if (!_.isEmpty(filterBy.location)) {
        filteredCallSource = _.filter(filteredCallSource, (user) => {
          return _.includes(user['location'].toLowerCase(), filterBy.location.toLowerCase());
        });
      }
      if (!_.isEmpty(filterBy.result)) {
        filteredCallSource = _.filter(filteredCallSource, (user) => {
          return _.includes(user['result'].toLowerCase(), filterBy.result.toLowerCase());
        });
      }
      if (!_.isEmpty(filterBy.aD_Source)) {
        filteredCallSource = _.filter(filteredCallSource, (user) => {
          return _.includes(user['aD_Source'].toLowerCase(), filterBy.aD_Source.toLowerCase());
        });
      }
      if (!_.isEmpty(filterBy.prospect)) {
        filteredCallSource = _.filter(filteredCallSource, (user: CallDetailsUserInterface) => {
          return _.includes(
            user['prospect_Non_Prospect']?.toLowerCase(),
            filterBy.prospect.toLowerCase()
          );
        });
      }

      if (!_.isEmpty(filterBy.missedOpp)) {
        filteredCallSource = _.filter(filteredCallSource, (user) => {
          if (user['missed_Opp']) {
            return _.includes(user['missed_Opp'].toLowerCase(), filterBy.missedOpp.toLowerCase());
          }
        });
      }

      if (!_.isEmpty(filterBy.apptSet)) {
        filteredCallSource = _.filter(filteredCallSource, (user) => {
          if (user['appointment_Set']) {
            return _.includes(
              user['appointment_Set'].toLowerCase(),
              filterBy.apptSet.toLowerCase()
            );
          }
        });
      }

      this.callSources = filteredCallSource;

      this.resetPagination();
    }
  }

  public resetPagination() {
    if (this.dt) {
      this.dt.first = 0; // Reset to the first page
    }
  }
  convertSecondsToHumanReadable(seconds: number | null | undefined): string {
    if (seconds == null || isNaN(seconds)) {
        return '00:00:00';
    }

    const hours = Math.floor(seconds / 3600);
    const remainingSecondsAfterHours = seconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const remainingSeconds = remainingSecondsAfterHours % 60;

    // Format the hours, minutes, and seconds to always have two digits
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}


  
  exportToCsv() {
    // Clone the original data to avoid modifying the existing dataset
    const clonedData = this.callSources.map(callSource => {
      return {
        ...callSource,
        duration: this.convertSecondsToHumanReadable(callSource.duration) // Format duration to human-readable
      };
    });
  
    // Assign the transformed data to the table component
    if (this.table) {
      this.table.data = clonedData;
  
      // Trigger change detection to update the view
      this.cdr.detectChanges();
    }
  
    // Use the exportCSV function from the TableGenericComponent
    this.table.exportCSV();
  }
  

}
