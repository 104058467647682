import { Component, OnInit, Input, ɵConsole, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { IntakesService } from '../../services/intakes.service';
import { Ppc } from '../../models/intakePpc';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { SectionStatus } from '../basic/review/section-model';
import { HttpService } from '../../../../../services/http.service';
@Component({
  selector: 'app-ppc-fb-ads',
  templateUrl: './ppc-fb-ads.component.html',
  styleUrls: ['./ppc-fb-ads.component.css']
})
export class PpcFbAdsComponent implements OnInit {
  ppcFbForm: UntypedFormGroup;
  productType: string;
  @Input() intakeTypeId;
  @Input() selectedMenu;
  selectedItem = {};
  intakeId:number=null;
  siteId:number=null;
  selectedIntakeData;
  existingPpcData;
  imageSrc;
  @Output() continue = new EventEmitter<object>();
  @Output() changeStatus= new EventEmitter<any>(); 
  multiLocation;
  
  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private toastrService: ToastrService,
    public modalService: NgbModal,
  ) { 
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.siteId = params.siteId;
        this.getIntakeProductList(params.id);
        //this.getIntakePpc(this.intakeId);
        
      }
    });
    this.intakesService.progressBar.subscribe(obj => {
      if (obj != null) {
        
        _.forEach(obj.basicMenu, (menu) => {
          if (this.selectedMenu && menu.id == this.selectedMenu.id) {
            this.selectedMenu.submenu = menu.submenu;
          }
        })
      }
    });
  }

  ngOnInit() {
    if (this.selectedMenu.selectedSectionId) {
      this.selectedItem = _.find(this.selectedMenu.submenu, (item) => { return item.id == this.selectedMenu.selectedSectionId });
    }
    this.getIntakePpc(this.intakeId,this.intakeTypeId)
  }
  getIntakeProductList(intakeId) {
    this.intakesService.getIntakeProductList(intakeId).subscribe((res) => {
      if (res) {
        this.selectedIntakeData = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  
  getIntakePpc(intakeId,intakeTypeId) {
    this.intakesService.getIntakePpc(intakeId,intakeTypeId).subscribe((res) => {
      if (res) {
        
        this.existingPpcData = new Ppc(res);
        this.initializeForm();
        if(res.oIntakeLocation.lstLocation && res.oIntakeLocation.lstLocation.length>1){
          this.ppcFbForm.controls.multipleLoc.setValue(true);
        }else{
          this.ppcFbForm.controls.multipleLoc.setValue(false);
        }
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
      
  }

  initializeForm() {
    this.ppcFbForm = this.formBuilder.group({
      intakeId : this.intakeId ? this.intakeId : null,
      intakeTypeId : this.intakeTypeId ? this.intakeTypeId : null,
      multipleLoc: new UntypedFormControl(),
      date : new UntypedFormControl(this.existingPpcData && this.existingPpcData.date ? new Date(this.existingPpcData.date):null),
      //callTrackingNumber :  new FormControl(this.existingPpcData ? this.existingPpcData.callTrackingNumber : null),
      productType : new UntypedFormControl(this.productType),
      clientLogo : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.clientLogo : null),
      campaign1StartDate : new UntypedFormControl(this.existingPpcData && this.existingPpcData.campaign1StartDate ? new Date(this.existingPpcData.campaign1StartDate):null),
      campaign1EndDate : new UntypedFormControl(this.existingPpcData && this.existingPpcData.campaign1EndDate ? new Date(this.existingPpcData.campaign1EndDate):null),
      campaign1Budget : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.campaign1Budget : null),
      //campaign2StartDate : new FormControl(this.existingPpcData ? this.existingPpcData.campaign2StartDate : null),
      //campaign2EndDate : new FormControl(this.existingPpcData ? this.existingPpcData.campaign2EndDate : null),
      campaign2Budget : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.campaign2Budget : null),
      targetDemographic :  new UntypedFormControl(this.existingPpcData ? this.existingPpcData.targetDemographic : null),
      targetGeographicZone :  new UntypedFormControl(this.existingPpcData ? this.existingPpcData.targetGeographicZone : null),
      previousCampaignHistory : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.previousCampaignHistory : null),
      isActiveAccount : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.isActiveAccount : null),
      facebookBusinessUrl : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.facebookBusinessUrl : null),
      additionalNotes : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.additionalNotes : null),
      topSellingServices : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.topSellingServices : null),
      promotion : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.promotion : null),
      focusOnService : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.focusOnService : null),
      costPerLead : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.costPerLead : null),
      leadConversionRate : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.leadConversionRate : null),
      averageRevenue : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.averageRevenue : null),
      averageProfitMargin : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.averageProfitMargin : null),
      competitorName1 : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.competitorName1 : null),
      website1 : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.website1 : null),
      competitorName2 : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.competitorName2 : null),
      website2 : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.website2 : null),
      competitorName3 : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.competitorName3 : null),
      website3 : new UntypedFormControl(this.existingPpcData ? this.existingPpcData.website3 : null),
      oIntakeLocation: this.formBuilder.group({
        intakeType:new UntypedFormControl(this.existingPpcData ? this.existingPpcData.oIntakeLocation.intakeType : 5), 
        lstLocation:this.formBuilder.array(
          this.locationInstance(this.existingPpcData ? this.existingPpcData.oIntakeLocation.lstLocation : null)
        )
      })
    });
  }

  locationInstance(addressInfo){    
    let locations =[];
    let location;
    if(addressInfo !=''){
      _.forEach(addressInfo, (info) => {
        location = this.formBuilder.group({
          id:new UntypedFormControl(info.id||0), 
          address1:new UntypedFormControl(info.address1),
          address2:new UntypedFormControl(info.address2),
          city: new UntypedFormControl(info.city),
          state:new UntypedFormControl(info.state),
          zipCode:new UntypedFormControl(info.zipCode)
        })
        locations.push(location);
       })       
    }else{
      location = this.formBuilder.group({
        id:new UntypedFormControl(0), 
        address1:new UntypedFormControl(),
        address2:new UntypedFormControl(),
        city: new UntypedFormControl(),
        state:new UntypedFormControl(),
        zipCode:new UntypedFormControl()
      })
      locations.push(location);
    }    
    return locations;
  }

  save(form){
    if(form.valid){
      let intakeData =form.value;
      intakeData.date = intakeData.date ? intakeData.date.getMonth()+1+"/"+intakeData.date.getDate()+"/"+intakeData.date.getFullYear() : intakeData.date;
      intakeData.campaign1StartDate = intakeData.campaign1StartDate ? intakeData.campaign1StartDate.getMonth()+1+"/"+intakeData.campaign1StartDate.getDate()+"/"+intakeData.campaign1StartDate.getFullYear() : intakeData.campaign1StartDate;
      intakeData.campaign1EndDate = intakeData.campaign1EndDate ? intakeData.campaign1EndDate.getMonth()+1+"/"+intakeData.campaign1EndDate.getDate()+"/"+intakeData.campaign1EndDate.getFullYear() : intakeData.campaign1EndDate;
     
      this.intakesService.savePpcIntake(intakeData).subscribe(res => {
        if (res) {
          this.toastrService.success('Saved successfully', 'Success');

          this.intakesService.updateRequiredFieldsStatus(intakeData);
          let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.selectedItem[0].id, this.intakeId);
          this.postIntakeSection(sectionStatus);
        } 
      },
        err => {
          this.httpService.openErrorPopup(err.error.message);
        })
    }
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  getValue(){     
    if( this.ppcFbForm.controls.multipleLoc.value){
      this.ppcFbForm.controls.oIntakeLocation['controls'].lstLocation.controls.push(
        this.locationInstance('')
      );
    }else{
      this.ppcFbForm.controls.oIntakeLocation['controls'].lstLocation.controls.pop();
    }
  }

  openUploadedFilesPopup(){
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = this.siteId;
    model.result.then((result) => {      
      if(result){
        this.imageSrc=result[0];
        this.ppcFbForm.controls.clientLogo.setValue(this.imageSrc);
      }
    }, (reason) => {
      
    });
  }

  openPage(item?) {    
    this.selectedItem = item ? item : {id: 0};
  }
  goBack(){
    let selectedItem = this.selectedItem;
    if((selectedItem['id']!==91 && selectedItem['familyId']==5)|| (selectedItem['id']!==94 && selectedItem['familyId']==6) ){
      let index = _.findIndex(this.selectedMenu.submenu, function(menu: any) { 
        return menu.id == selectedItem['id'];
      });
        this.selectedItem = this.selectedMenu.submenu[index - 1];
    }else{
      this.selectedItem = {};
    }
    window.scroll(0,0);
  }

  goToNext(menu){
    if (menu == 'parent') {
      this.continue.emit();
    } else {
    let selectedItem = this.selectedItem;
    let index = _.findIndex(this.selectedMenu.submenu, function(menu: any) { 
      return menu.id == selectedItem['id'];
    });
    this.selectedItem = this.selectedMenu.submenu[index + 1];
    window.scroll(0,0);
  }
}
changeIntakeStatus(){
  this.changeStatus.next();
}

}