import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@services/http.service';
import { catchError, tap } from 'rxjs/operators';
import { Site, SiteConfig, SiteInfo, SiteInfoWithSiteID } from '@shared/interfaces/site';
import { StatusResponse } from '@shared/interfaces/responses';
import { HttpParams } from '@angular/common/http';
import { Package } from '@shared/interfaces/client';
import { CallTrackingInfo } from '@shared/interfaces/call_tracking';
import { SelectedSite } from '@shared/interfaces/application';
import { InvoiceCoupon } from '@shared/interfaces/invoices';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  public siteIsLock = false;

  constructor(
    private httpService: HttpService
  ) { }

  /**
   * Creates an object with minimum data for the selectedSite entries
   * @param {Partial<SelectedSite>} data
   *
   * @return {Object}
   */
  createSelectedSite(data: Partial<SiteInfoWithSiteID>): Partial<SelectedSite> {
    return {
      domainURL: data.domainUrl,
      id: data.id ?? data.siteId,
      siteId: data.siteId ?? data.id,
    };
  }

  getApproveSite(filterBy, status){
    return this.httpService.getApi(`intake/IntakeStatusWise?status=${status || 0}&DomainUrl=${filterBy.domainUrl || ''}&SiteName=${filterBy.businessName || ''}&CPAccountNo=${filterBy.cpAccountNo || ''}&IntakeTypeId=${filterBy.intakeTypeId || ''}&SiteType=${filterBy.siteType || ''}&SearchByCP=${filterBy.cp || ''}&AccountManager=${filterBy.am || ''}&TaskStatus=${filterBy.taskStatus || ''}&StartDate=${filterBy.formattedStartDate || ''}&EndDate=${filterBy.formattedEndDate || '' } &IsNew=${filterBy.isNew || ''}`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }

  getAssignUser(userId){
    return this.httpService.getApi(`site/AssignUser/${userId}`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }

  /**
   * Returns a list of all packages, used primarily for filtering in the Client Table
   *
   * @return {Observable}
   */
  getPackages() {
    return this.httpService.getApi(`site/getpackages`)
      .pipe(
        tap((res: Package[]) => {
          return res;
        })
      );
  }

  /**
   * Takes any variety of site object and ensure that it has `id` and `siteId` properties
   * @param {Object} site The site data to normalize
   *
   * @return { id: string; siteId: string} The normalized site data
   */
  normalizeIds(site: Pick<SiteInfo, 'siteId'> & Partial<SiteInfo>) {
    if (site.hasOwnProperty('siteId') && !site.hasOwnProperty('id')) {
      return {
        ...site,
        id: site.siteId,
        siteId: site.siteId,
      };
    }
  }

  assignUser(data): Observable<any[]> {
    return this.httpService.postApi('site/UpdateSiteUser',data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  unMapSiteUser(userId, siteId): Observable<any[]> {
    return this.httpService.deleteApi(`site/DeleteSiteUser?UserId=${userId}&SiteId=${siteId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  /**
   * Queries all sites and returns data ready to use in the search bar
   * @param {String} cpId
   * @param {Boolean} isActive
   *
   * @return Observable
   */
  getSites(cpId?, isActive = true): Observable<Site[]> {
    return this.httpService.getApi(`site/getSites?CPId=${cpId ? cpId : 0}&IsActive=${isActive}`)
      .pipe(
        tap((res) => {
          return res;
        })
      );
  }

  searchSites(cpId?: number, isActive: boolean = true, searchTerm: string = ''): Observable<Site[]> {
    return this.httpService
      .getApi(`site/SearchSites?CPId=${cpId ? cpId : 0}&IsActive=${isActive}&term=${encodeURIComponent(searchTerm)}`)
      .pipe(
        tap((res) => {
          return res;
        })
      );
  }

  getProductBySite(obj){
    return this.httpService.getApi(`site/ProductBySite?siteid=${obj.siteId}`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }
  startBilling(data): Observable<any[]> {
    return this.httpService.postApi('Intake/StartSubscription',data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  getCorporatePartner(): Observable<any> {
    return this.httpService.getApi('user/corporatepartner')
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }

  getAmrpcReport(filter): Observable<any> {
    return this.httpService.getApi(`Report/AMRPCReport?CPId=${filter.cpId}&SiteId=${filter.siteId}&StartDate=${filter.formattedStartDate}&EndDate=${filter.formattedEndDate}`)
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }

  updateSiteStatus(body): Observable<any> {
    return this.httpService.putApi(`site/UpdateSiteStatus`, body, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getUserInfo(id): Observable<any> {
    return this.httpService.getApi(`user/GetUser/${id}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        }),
        catchError(this.httpService.handleError<any>())
      );
  }
  getSiteInfo(siteId): Observable<SiteInfo> {
    return this.httpService.getApi(`site/getSite/${siteId}`)
      .pipe(
        tap(res => {
          return res;
        })
      );
  }

  getAllUsersByParent(filterBy,siteId?): Observable<any[]> {
    return this.httpService.getApi(`User/GetUsersByParent?SiteId=${siteId}&UserName=${filterBy && filterBy.username ? filterBy.username:''}&Name=${filterBy && filterBy.name ? filterBy.name:''}&Phone=${filterBy && filterBy.mobile ? filterBy.mobile:''}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  delete(userInfo): Observable<any> {
    return this.httpService.putApi(`user`, userInfo, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }
        })
      );
  }
  deleteSiteUser(userId): Observable<any> {
    return this.httpService.deleteApi(`user/${userId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }
        })
      );
  }

  getCallTracking(siteId): Observable<CallTrackingInfo[]> {
    return this.httpService.getApi(`callSource/GetCallTracking/${siteId}`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }

  postSetup(siteId): Observable<any> {
    return this.httpService.postApi(`callSource/SetUp/${siteId}`, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }
        })
      );
  }

  SaveProvisionInfo(siteId): Observable<any> {
    return this.httpService.postApi(`callSource/SaveProvisionInfo/${siteId}`, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }
        })
      );
  }
  setUpPiwik(SiteId,PiwikId): Observable<any> {
    return this.httpService.postApi(`Piwik/SetUp?SiteId=${SiteId}&PiwikId=${PiwikId}`, '')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }
  getSocialMediaMarketing(siteId){
    return this.httpService.getApi(`SocialMediaMarketing/SetUp/${siteId}`)
    .pipe(
      tap(res=>{
        if(res){
        return res;
        }
      })
    );
  }
  setUpSocialMediaMarketing(data):Observable<any>{
    return this.httpService.postApi(`SocialMediaMarketing/SetUp`,data)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }

  getReviewGen(siteId){
    return this.httpService.getApi(`ReviewGen/Profile/${siteId}`)
    .pipe(
      tap(res=>{
        if(res){
        return res;
        }
      })
    );
  }

  getReviewGenUser(siteId){
    return this.httpService.getApi(`ReviewGen/User/${siteId}`)
    .pipe(
      tap(res=>{
        if(res){
        return res;
        }
      })
    );
  }

  saveReviewGen(profile): Observable<any> {
    return this.httpService.postApi(`ReviewGen/Profile`,profile )
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  getReviewSSOUrl(siteId){
    return this.httpService.getApi(`ReviewGen/SSOUrl/${siteId}`)
    .pipe(
      tap(res=>{
        if(res){
        return res;
        }
      })
    );
  }

  saveReviewGenUser(user): Observable<any> {
    return this.httpService.postApi(`ReviewGen/User`,user)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  deleteReviewGen(Id,Type): Observable<any> {
    return this.httpService.deleteApi(`ReviewGen?Id=${Id}&Type=${Type}`,'')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }
  deleteInstaPage(Id): Observable<any> {
    return this.httpService.deleteApi(`GooglePPC/Instapage/${Id}`,'')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  getSocialMediaSetup(siteId){
    return this.httpService.getApi(`SocialMediaSetup/SetUp/${siteId}`)
    .pipe(
      tap(res=>{
        if(res){
        return res;
        }
      })
    );
  }

  setUpSocialMediaSetup(data):Observable<any>{
    return this.httpService.postApi(`SocialMediaSetup/SetUp`,data)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }

  /**
   * Filters the Clients Table
   *
   * @param {Object} filterBy
   */
  getAdvanceSearchData(filterBy) {
    const params = new HttpParams({
      fromObject: {
        packageId: filterBy.packageId ?? '',
      },
    });

    const urlParams = `IsActive=${filterBy.isActive==null?'':filterBy.isActive }&IsLive=${filterBy.isLive==null?'':filterBy.isLive }&Dt=${filterBy.dateType || ''}&StartDate=${filterBy.formattedStartDate || ''}&EndDate=${filterBy.formattedEndDate || ''}&SiteType=${filterBy.siteType || ''}&AccountManager=${filterBy.am || ''}&SearchByCP=${filterBy.cp || ''}&SiteId=${filterBy.siteId || ''}&IsCancelled=${filterBy.cancelled || ''}&DomainUrl=${filterBy.domainUrl || ''}&SiteName=${filterBy.siteName || ''}&productType=${filterBy.productType || ''}&isNew=${filterBy.isNew || ''}&CpAccountNo=${filterBy.cpAccountNo || ''}&TaskStatus=${filterBy.taskStatus || ''}`;

    return this.httpService.getApi(`Site/GetSiteBySearch?${urlParams}&${params.toString()}`)
      .pipe(
        tap((res: SiteInfo[]) => {
          return res;
        })
      );
  }

  getMastersListById(id): Observable<any> {
    return this.httpService.getApi(`master/${id}`)
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }

  getAccountManager(): Observable<any> {
    return this.httpService.getApi(`user/AccountManagers`)
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }

  setUpFacebook(data): Observable<any> {
    return this.httpService.postApi(`FacebookAds/Setup`,data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  setUpGoogle(data): Observable<any> {
    return this.httpService.postApi(`GoogleAnalytics/Setup`,data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  siteSetup(data): Observable<any> {
    return this.httpService.postApi(`site/Setup`,data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  deleteSite(siteId){
    return this.httpService.deleteApi(`Site/${siteId}`)
    .pipe(
      tap(res=>{
        if(res){
          return res;
        }
      })
    );
  }

  getSiteProductTypes(siteId): Observable<any[]> {
    return this.httpService.getApi(`Intake/GetSiteProductTypes?siteId=${siteId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getDowngradeProduct(siteId,packageId,type): Observable<any[]> {
    return this.httpService.getApi(`Salesforce/ProductSFId?siteId=${siteId}&packageId=${packageId}&type=${type}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  downgradeProduct(data): Observable<any> {
    return this.httpService.postApi(`Salesforce/DownGrade`,data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  /**
   * Sends an updated site configuration to the API
   *
   * @param {SiteConfig} data
   *
   * @return {Observable}
   */
  updateSiteInfo(data: SiteConfig): Observable<StatusResponse> {
    return this.httpService.putApi('site/updatesite', data)
        .pipe(
            tap((res: StatusResponse) => {
              if (res) {
                return res;
              }
            }),
        );
  }

  getCCM(siteId): Observable<any[]> {
    return this.httpService.getApi(`site/ccm/${siteId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  saveCCM(data): Observable<any[]> {
    return this.httpService.postApi('site/ccm',data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  cancelSubscription(data): Observable<any[]> {
    return this.httpService.postApi('Site/CancelSubscription',data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  getCancelSite(filterBy) {

  return this.httpService.getApi(`site/Cancellations?StartDate=${filterBy.formattedStartDate || ''}&EndDate=${filterBy.formattedEndDate || ''}&DomainUrl=${filterBy.domainUrl || ''}&CPId=${filterBy.cp || ''}&SiteName=${filterBy.siteName || ''}&IntakeTypeId=${filterBy.productType || ''}&Email=${filterBy.email || ''}`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }

  getWPSites(){
    return this.httpService.getApi(`WordPress/sites`)
    .pipe(
      tap(res=>{
        return res;
      })
    );
  }

  deleteSmm(Id): Observable<any> {
    return this.httpService.deleteApi(`SocialMediaMarketing/SetUp/${Id}`,'')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  deleteCallTracking(id,siteId): Observable<any> {
    return this.httpService.deleteApi(`CallSource/CallTracking?Id=${id}&SiteId=${siteId}`,'')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  CallSourceCallTracking(data): Observable<any[]> {
    return this.httpService.postApi('CallSource/CallTracking',data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  UpdateCallsourceNumber(siteId): Observable<any[]> {
    return this.httpService.postApi(`WordPress/Callsource/${siteId}`,'')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  getSEMrushLocation(siteId){
    return this.httpService.getApi(`SEMrush/Location/${siteId}`)
    .pipe(
      tap(res=>{
        if(res){
        return res;
        }
      })
    );
  }

  getGMBLocation(siteId){
    return this.httpService.getApi(`googleMyBusiness/Location/${siteId}`)
    .pipe(
      tap(res=>{
        if(res){
        return res;
        }
      })
    );
  }

  deleteSEMrush(Id): Observable<any> {
    return this.httpService.deleteApi(`SEMrush/Location/${Id}`,'')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  deleteGMB(Id): Observable<any> {
    return this.httpService.deleteApi(`googleMyBusiness/Location/${Id}`,'')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  addSEMrush(data): Observable<any[]> {
    return this.httpService.postApi('SEMrush/Location',data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  addGMB(data): Observable<any[]> {
    return this.httpService.postApi('googleMyBusiness/Location',data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  addInstaPage(data): Observable<any[]> {
    return this.httpService.postApi('GooglePPC/Instapage',data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  getInstapageData(siteId){
    return this.httpService.getApi(`GooglePPC/Instapage/${siteId}`)
    .pipe(
      tap(res=>{
        if(res){
        return res;
        }
      })
    );
  }

  GetWebsiteProductToBeDowngradedIn(siteId): Observable<any[]> {
    const rendomNumber = Math.random();

    return this.httpService.getApi(
      `Intake/GetWebsiteProductToBeDowngradedIn?siteId=${siteId}&i=${rendomNumber}`
    ).pipe(
      tap(res => {
        if (res) {
          return res;
        }
      })
    );
  }

  getPackagesAvailableToDowngradeTo(siteId): Observable<any[]> {
    let rendomNumber=Math.random();
    return this.httpService.getApi(`Intake/GetWebsiteProductToBeDowngradedIn?siteId=${siteId}&i=${rendomNumber}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  GetCurrentWebsiteData(siteId): Observable<any[]> {
    let rendomNumber=Math.random();
    return this.httpService.getApi(`site/CurrentWebsiteProduct?siteId=${siteId}&i=${rendomNumber}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getProductForCancelSubs(siteId): Observable<any[]> {
    return this.httpService.getApi(`Site/ProductsForCancelSubscription?SiteId=${siteId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  getSwitchProduct(sfId): Observable<any[]> {
    return this.httpService.getApi(`Salesforce/SwitchProductSFId?SFId=${sfId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  pauseSubscription(body): Observable<any> {
    return this.httpService.putApi(`stripe/PauseSubscription`,body)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  getPausedSubscription(siteId): Observable<any[]> {
    return this.httpService.getApi(`stripe/PausedSubscriptions?SiteId=${siteId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  createInvoice(data): Observable<any[]> {
    return this.httpService.postApi('stripe/CustomeInvoice',data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }
  getRefunds(siteId): Observable<any[]> {
    return this.httpService.getApi(`stripe/Refunds?SiteId=${siteId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getCoupons(duration): Observable<any[]> {
    return this.httpService.getApi(`Stripe/GetCouponList?CouponDuration=${duration}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getAllCoupons(): Observable<InvoiceCoupon[]> {
    return this.httpService.getApi(`Stripe/GetCouponList`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }
        })
      );
  }

  UpdateCouponOnSubscription(body): Observable<any> {
    return this.httpService.putApi(`stripe/UpdateCouponOnSubscription`,body)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  addCp(data): Observable<any[]> {
    return this.httpService.postApi('User/CP',data)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  modifySubscription(body): Observable<any> {
    return this.httpService.putApi(`Stripe/UpdateSubscriptionProduct`,body)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  getDownGradeProduct(siteId):Observable<any> {
    return this.httpService.getApi(`site/DownGradeProduct/${siteId}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }
  getPendingInvoiceItems(siteId,paymentMode):Observable<any> {
    return this.httpService.getApi(`stripe/PendingInvoiceItems?SiteId=${siteId}&PaymentMode=${paymentMode}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }
  getSycleSetup(siteId):Observable<any> {
    return this.httpService.getApi(`PMS/SycleSetup/${siteId}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }
  saveSycleSetup(sycleSetup):Observable<any> {
    return this.httpService.postApi(`PMS/SycleSetup`,sycleSetup)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }
  deleteSycleSetup(id):Observable<any> {
    return this.httpService.deleteApi(`PMS/SycleSetup/${id}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }
  getBluePrintSetup(siteId):Observable<any> {
    return this.httpService.getApi(`PMS/BluePrintSetup/${siteId}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }
  saveBluePrintSetup(data):Observable<any> {
    return this.httpService.postApi(`PMS/BluePrintSetup`,data)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }
  deleteBluePrintSetup(id):Observable<any> {
    return this.httpService.deleteApi(`PMS/BluePrintSetup/${id}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }
  generateIframeScript(data){
    return this.httpService.postApi(`PMS/GenerateIframeScript`,data)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      )
  }
  getGeneralMaster(type){
    return this.httpService.getApi(`Master/General/${type}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      )
  }
  getPiwikScript(siteId){
    return this.httpService.getApi(`Piwik/Script/${siteId}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      )
  }
  getTimeZone(){
    return this.httpService.getApi(`PMS/TimeZone`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      )
  }
  getProfileData(siteId){
    return this.httpService.getApi(`ReviewGen/Profile/${siteId}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      )
  }
  getSiteuser(){
    return this.httpService.getApi('User/SiteUser')
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      )
  }
  addSiteUser(siteId,userId){
    return this.httpService.postApi(`User/SiteUser?SiteId=${siteId}&UserId=${userId}`,'')
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      )
  }

  getCurrentPackageProductSubscription(siteId){
    return this.httpService.getApi(`Stripe/GetCurrentPackageSubscription?siteId=${siteId}`)
      .pipe(
        tap(res => {
          if(res)
            return res;
        })
      )
  }
}
