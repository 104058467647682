import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';
import { masterEnum } from '@shared/master-enum.enum';
import * as _ from 'lodash';
import { IntakeLocalListing, LlListingTag } from '@shared/interfaces/intakes';

@Injectable({
  providedIn: 'root'
})
export class IntakesService {
  public progressBar: BehaviorSubject<any> = new BehaviorSubject(null);
  basicInfo = {};
  public websitePromotion = {};
  public basicMenu;
  commonBasicInfoObj = {};
  productFamilyList = [];
  basicInfoRequiredData = {};
  sectionData = {};
  accordionCheckedPageList = [];
  public overAllPercentage;
  myHistory=[];
  requiredFields = [
    { key: 'isLogo', errorMsg: 'This is a Required Field', sectionId: 19, status: false },
    { key: 'bannerStyle', errorMsg: 'This is a Required Field', sectionId: 20, status: false },
    { key: 'ctaStyle', errorMsg: 'This is a Required Field', sectionId: 20, status: false },
    { key: 'banner', errorMsg: 'This is a Required Field', sectionId: 20, status: false },
    { key: 'banner', errorMsg: 'This is a Required Field', sectionId: 55, status: false },
    { key: 'isLogo', errorMsg: 'This is a Required Field', sectionId: 54, status: false },
    { key: 'bannerStyle', errorMsg: 'This is a Required Field', sectionId: 55, status: false },
    { key: 'ctaStyle', errorMsg: 'This is a Required Field', sectionId: 55, status: false },
    { key: 'state', errorMsg: 'This is a Required Field', sectionId: 8, status: false },
    { key: 'city', errorMsg: 'This is a Required Field', sectionId: 8, status: false },
    { key: 'pmsProviderType', errorMsg: 'This is a Required Field', sectionId: 8, status: false },
    { key: 'goal', errorMsg: 'This is a Required Field', sectionId: 8, status: false },
    { key: 'businessEstablishmentYear', errorMsg: 'This is a Required Field', sectionId: 8, status: false },
    { key: 'isDomainTransfer', errorMsg: 'This is a Required Field', sectionId: 8, status: false },
    { key: 'isDomainEmail', errorMsg: 'This is a Required Field', sectionId: 8, status: false },
    { key: 'email', errorMsg: 'This is a Required Field', sectionId: 8, status: false },
    { key: 'email', errorMsg: 'This is a Required Field', sectionId: 62, status: false },
    { key: 'purpose', errorMsg: 'This is a Required Field', sectionId: 51, status: false },
    { key: 'isUpdateWebsiteContent', errorMsg: 'This is a Required Field', sectionId: 51, status: false },
    { key: 'like', errorMsg: 'This is a Required Field', sectionId: 51, status: false },
    { key: 'dislike', errorMsg: 'This is a Required Field', sectionId: 51, status: false },
    // Local Listings required fields - 78 = business info, 99 = location info
    { key: 'website', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    { key: 'locations', errorMsg: 'This is a Required Field', sectionId: 99, status: false },

    // Legacy Citation Cleanup required fields - DEPRECATED
    // { key: 'currentBusinessName', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    // { key: 'businessOwnerName', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    // // { key: 'hoursofOperation', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    // { key: 'paymentsAccepted', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    // { key: 'currentBusinessUrl', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    // // { key: 'curBusLocationName', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    // { key: 'businessOwnerEmail', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    // { key: 'curBusAddress1', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    // // { key: 'curBusAddress2', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    // { key: 'curBusCity', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    // { key: 'curBusState', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    // { key: 'curBusZipCode', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    // { key: 'curBusPhoneNumber', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    //{ key: 'lstLocationWorkingHours', errorMsg: 'This is a Required Field', sectionId: 78, status: false },
    { key: 'isCallTrackingRequired', errorMsg: 'This is a Required Field', sectionId: 42, status: false },
    { key: 'fields', errorMsg: 'This is a Required Field', sectionId: 58, status: false },
    { key: 'facebookBusinessUrl', errorMsg: 'This is a Required Field', sectionId: 95, status: false },
    { key: 'campaign1EndDate', errorMsg: 'This is a Required Field', sectionId: 95, status: false },
    { key: 'campaign1StartDate', errorMsg: 'This is a Required Field', sectionId: 95, status: false },
    { key: 'campaign1Budget', errorMsg: 'This is a Required Field', sectionId: 95, status: false },
    { key: 'targetDemographic', errorMsg: 'This is a Required Field', sectionId: 95, status: false },
    { key: 'targetGeographicZone', errorMsg: 'This is a Required Field', sectionId: 95, status: false },
    { key: 'campaign1EndDate', errorMsg: 'This is a Required Field', sectionId: 92, status: false },
    { key: 'campaign1StartDate', errorMsg: 'This is a Required Field', sectionId: 92, status: false },
    { key: 'campaign1Budget', errorMsg: 'This is a Required Field', sectionId: 92, status: false },
    { key: 'targetDemographic', errorMsg: 'This is a Required Field', sectionId: 92, status: false },
    { key: 'targetGeographicZone', errorMsg: 'This is a Required Field', sectionId: 92, status: false },
    { key: 'gmbEmail', errorMsg: 'This is a Required Field', sectionId: 87, gmb: true, status: false },
    { key: 'gmbPassword', errorMsg: 'This is a Required Field', sectionId: 87, gmb: true, status: false },
    { key: 'gmbRecoveryEmail', errorMsg: 'This is a Required Field', sectionId: 87, gmb: true, status: false },
    { key: 'gmbdob', errorMsg: 'This is a Required Field', sectionId: 87, gmb: true, status: false },
    { key: 'gmbGender', errorMsg: 'This is a Required Field', sectionId: 87, gmb: true, status: false },
    { key: 'gmbRecoveryMobile', errorMsg: 'This is a Required Field', sectionId: 87, gmb: true, status: false },
    { key: 'firstName', errorMsg: 'This is a Required Field', sectionId: 87, gmb: false, status: false },
    { key: 'lastName', errorMsg: 'This is a Required Field', sectionId: 87, gmb: false, status: false },
    { key: 'pEmail', errorMsg: 'This is a Required Field', sectionId: 87, gmb: false, status: false },
    { key: 'dob', errorMsg: 'This is a Required Field', sectionId: 87, gmb: false, status: false },
    { key: 'gender', errorMsg: 'This is a Required Field', sectionId: 87, gmb: false, status: false },
    { key: 'recoveryMobile', errorMsg: 'This is a Required Field', sectionId: 87, gmb: false, status: false },
    { key: 'businessName', errorMsg: 'This is a Required Field', sectionId: 87, status: false },
    { key: 'businessOwner', errorMsg: 'This is a Required Field', sectionId: 87, status: false },
    { key: 'isPrvBusinessName', errorMsg: 'This is a Required Field', sectionId: 87, status: false },
    { key: 'isPrvBusinessNumber', errorMsg: 'This is a Required Field', sectionId: 87, status: false },
    { key: 'isPrvBusinessUrl', errorMsg: 'This is a Required Field', sectionId: 87, status: false },
    { key: 'businessEstablishmentYear', errorMsg: 'This is a Required Field', sectionId: 88, status: false },
    { key: 'referencedContentId', errorMsg: 'This is a Required Field', sectionId: 88, status: false },
    { key: 'referencedContentId', errorMsg: 'This is a Required Field', sectionId: 97, status: false },
    { key: 'contentWrites', errorMsg: 'This is a Required Field', sectionId: 88, status: false, keyToIgnore: 'notes' },
    { key: 'lstPPCLocation', errorMsg: 'This is a Required Field', sectionId: 91, status: false, keyToIgnore: 'address2' },
    { key: 'email', errorMsg: 'This is a Required Field', sectionId: 91, status: false},
    { key: 'email', errorMsg: 'This is a Required Field', sectionId: 94, status: false}

  ]
  locationTimeLists = [
    {
      "value": "By Appointment Only",
      "time": "By Appointment Only"
    },
    {
      "value": "8:00 AM",
      "time": "8:00 AM"
    },
    {
      "value": "8:30 AM",
      "time": "8:30 AM"
    },
    {
      "value": "9:00 AM",
      "time": "9:00 AM"
    },
    {
      "value": "9:30 AM",
      "time": "9:30 AM"
    },
    {
      "value": "10:00 AM",
      "time": "10:00 AM"
    },
    {
      "value": "10:30 AM",
      "time": "10:30 AM"
    },
    {
      "value": "11:00 AM",
      "time": "11:00 AM"
    },
    {
      "value": "11:30 AM",
      "time": "11:30 AM"
    },
    {
      "value": "12:00 PM",
      "time": "12:00 PM"
    },
    {
      "value": "12:30 PM",
      "time": "12:30 PM"
    },
    {
      "value": "1:00 PM",
      "time": "1:00 PM"
    },
    {
      "value": "1:30 PM",
      "time": "1:30 PM"
    },
    {
      "value": "2:00 PM",
      "time": "2:00 PM"
    },
    {
      "value": "2:30 PM",
      "time": "2:30 PM"
    },
    {
      "value": "3:00 PM",
      "time": "3:00 PM"
    },
    {
      "value": "3:30 PM",
      "time": "3:30 PM"
    },
    {
      "value": "4:00 PM",
      "time": "4:00 PM"
    },
    {
      "value": "4:30 PM",
      "time": "4:30 PM"
    },
    {
      "value": "5:00 PM",
      "time": "5:00 PM"
    },
    {
      "value": "5:30 PM",
      "time": "5:30 PM"
    },
    {
      "value": "6:00 PM",
      "time": "6:00 PM"
    }
  ]
  goToSetup:string='';
  constructor(
    private httpService: HttpService
  ) {
  }

  getRequiredFields(intakeSection) {
    // for (let key in intakeSection) {
    _.forEach(intakeSection, (menu) => {
      _.forEach(menu.submenu, (section) => {
        let reqFields = _.filter(this.requiredFields, (ff) => {
          return ff.sectionId == section.id;
        })
        _.forEach(reqFields, (field: any) => {
          if (section.id == field.sectionId) {
            field.percentage = section.percentage / reqFields.length;
          }
        });
        section.requiredFields = reqFields;
      })
    })
    // }
    return intakeSection;
  }

  getPaletteData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.palette}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getAssociationsData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.trade_associations}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getContentSectionData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.contentSection}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getElementData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.element}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getPmsProviderData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.pms_provider_type}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getSmmSpecialServiceData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.special_services}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getPrimaryGoalsData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.primary_goals}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  saveIntake(intakeData): Observable<any> {
    return this.httpService.postApi('Intake/IntakeBasic', intakeData, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getIntakeBasic(intakeId): Observable<any> {
    return this.httpService.getApi('intake/IntakeBasic/' + intakeId)
      .pipe(
        tap(res => {
          return res;
        })
      );
  }
  getProductList(familyId, intakeId): Observable<any> {
    return this.httpService.getApi(`intake/ProductSection?FamilyId=${familyId}&IntakeId=${intakeId}`)
      .pipe(
        tap(res => {
          return res;
        })
      );
  }
  getIntakePpc(intakeId, intakeTypeId): Observable<any> {
    return this.httpService.getApi(`Intake/IntakePPC?intakeId=${intakeId}&IntakeTypeId=${intakeTypeId}`)
      .pipe(
        tap(res => {
          return res;
        })
      );
  }

  savePpcIntake(intakeData): Observable<any> {
    return this.httpService.postApi('Intake/IntakePPC', intakeData, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  updateIntake(intakeData, intakeId): Observable<any> {
    return this.httpService.putApi(`Intake/IntakeBasic/${intakeId}`, intakeData, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getIntakes(siteId): Observable<any[]> {
    return this.httpService.getApi(`Intake/GetProjects?SiteId=${siteId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getIntakeProductList(IntakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/GetIntake?IntakeId=${IntakeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  GetCustomerPayInfo(SiteId): Observable<any[]> {
    return this.httpService.getApi(`Stripe/GetCustomerPayInfo/${SiteId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getBannerTypeData(): Observable<any[]> {
    return this.httpService.getApi(`master/31`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getAgeGroupData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.age_group}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getEthinicityData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.ethinicity}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getLifestyleData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.lifestyle}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getProductsData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.products}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getPageList(selectedItemName, intakeId): Observable<any> {
    return this.httpService.getApi(`WordPress/GetWPPages?ProductSectionName=${selectedItemName}&IntakeId=${intakeId}`)
      .pipe(
        tap(res => {
          return res;
        })
      );
  }

  saveIntakePages(selectedPageList): Observable<any> {
    return this.httpService.postApi('Intake/IntakePages', selectedPageList, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getIntakeSavedPages(intakeId, ProductSectionId): Observable<any> {
    return this.httpService.getApi(`Intake/IntakeSavedPages?IntakeId=${intakeId}&ProductSectionId=${ProductSectionId}`)
      .pipe(
        tap(res => {
          return res;
        })
      );
  }


  updateIntakeApproval(data): Observable<any> {
    return this.httpService.postApi('intake/UpdateIntakeApproval', data, '')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }
  getIntakeBasicReview(intakeId): Observable<any> {
    return this.httpService.getApi('intake/IntakeBasicReview/' + intakeId)
      .pipe(
        tap(res => {
          return res;
        })
      );
  }

  postIntakeSection(sectionStatus): Observable<any> {
    return this.httpService.postApi('intake/IntakeSection', sectionStatus, '')
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  updateIntakePageContent(updatedData): Observable<any> {
    return this.httpService.postApi('intake/IntakePageContent', updatedData, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getIntakePageContent(intakeId, PageId): Observable<any> {
    return this.httpService.getApi(`intake/IntakePageContent?IntakeId=${intakeId}&PageId=${PageId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getIntakeCustomPageContent(intakeId, PageId, title): Observable<any> {
    return this.httpService.getApi(`intake/IntakePageContent?IntakeId=${intakeId}&PageId=${PageId}&title=${title}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  deleteIntakeSavedPages(intakeId, PageId, parentId, productSectionId): Observable<any> {
    return this.httpService.deleteApi(`intake/IntakePages?IntakeId=${intakeId}&PageId=${PageId}&ParentId=${parentId}&ProductSectionId=${productSectionId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getIntakeSectionData(intakeId): Observable<any> {
    return this.httpService.getApi(`intake/IntakeSection/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  getHomepageData(intakeId): Observable<any> {
    return this.httpService.getApi(`intake/IntakeBasicHomePage/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  updateRequiredFieldsStatus(intakeData) {
    if (!_.isEmpty(intakeData)) {
      _.forEach(this.basicInfoRequiredData, (menu: any) => {
        _.forEach(menu.submenu, (data) => {
          data.requiredFields = this.checkIfGmbExists(intakeData, data.id, data.percentage);
          data['updatedPercentage'] = data.requiredFields.length ? 0 : data.percentage;
          _.forEach(data.requiredFields, (field) => {
            if (typeof intakeData[field.key] == 'object' && intakeData[field.key] != null) {
              if (intakeData[field.key][field.property] && intakeData[field.key][field.property].length) {
                this.updatePropertyListStatus(intakeData, field);
              } else if(intakeData[field.key].length){
                this.updateFieldListStatus(intakeData, field);
              } else {
                field['status'] = _.isEmpty(intakeData[field.key][field.property]) ? false : true;
              }
            } else {
              field['status'] = ((intakeData[field.key] != null && (typeof intakeData[field.key]) != undefined && intakeData[field.key] != '') || (typeof intakeData[field.key]) == 'boolean') ? true : false;
            }
            if (field.status) {
              data['updatedPercentage'] = data.updatedPercentage + field.percentage;
            }
          })
        })
      })
    }
  }

  checkIfGmbExists(intakeData, sectionId, menuPercentage){
    let requiredFields;
    if (typeof intakeData.isGMB == 'boolean') {
      requiredFields = _.filter(this.requiredFields, (field) => {
        return (field.gmb == intakeData.isGMB || !_.has(field, 'gmb')) && field.sectionId == sectionId;
      })
    } else {
      requiredFields = _.filter(this.requiredFields, (field) => {
        return !_.has(field, 'gmb') && field.sectionId == sectionId;
      })
    }
    _.forEach(requiredFields, (field) => {
        field.percentage = menuPercentage / requiredFields.length;
      })
    return requiredFields;
  }

  updateFieldListStatus(intakeData, field) {
    let blankData = [];
    _.forEach(intakeData[field.key], (val) => {
      let emptyVal = _.pickBy(val, function (value, key) {
        return val[key] == '' && (typeof val[key] != 'number') && (typeof val[key] != 'boolean') && key != field.keyToIgnore;
      });
      if (!_.isEmpty(emptyVal)) {
        blankData.push(emptyVal);
      }
    })
    field['status'] = blankData.length ? false : true;
  }

  updatePropertyListStatus(intakeData, field) {
    let blankData = [];
    _.forEach(intakeData[field.key][field.property], (val) => {
      let emptyVal = _.pickBy(val, function (value, key) {
        return val[key] == '' && (typeof val[key] != 'number') && (typeof val[key] != 'boolean') && key != field.keyToIgnore;
      });;
      if (!_.isEmpty(emptyVal)) {
        blankData.push(emptyVal);
      }
    })
    field['status'] = blankData.length ? false : true;
  }

  bulidSite(intakeId): Observable<any> {
    return this.httpService.postApi('WordPress/BuildSite/' + intakeId, {})
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }
        })
      );
  }

  deleteCustomPages(intakeId, itemId, productSectionId): Observable<any> {
    return this.httpService.deleteApi(`intake/CustomeIntakePages?IntakeId=${intakeId}&ItemId=${itemId}&ProductSectionId=${productSectionId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  saveCustomPages(customPageList): Observable<any> {
    return this.httpService.postApi('Intake/CustomeIntakePages', customPageList, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  postContentHomepage(contentHomepage): Observable<any> {
    return this.httpService.postApi('intake/IntakeBasicHomePage', contentHomepage, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  groupMenu(res) {
    _.forEach(res.lstProductSection, (menuItem) => {
      if (menuItem.parent == '0') {
        let groupedMenu = _.groupBy(res.lstProductSection, (menu) => {
          return menu.parent == menuItem.id;
        });
        menuItem['submenu'] = groupedMenu.true;
      }
    })
    this.overAllPercentage = res.OverAllPercentage;
    res.lstProductSection = _.filter(res.lstProductSection, (response) => {
      return !response.parent;
    });
    return res.lstProductSection;
  }

  updateSectionstatus(intakeSection) {
    if (intakeSection) {
      // intakeSection = _.groupBy(intakeSection, 'parent');
      // for (let section in intakeSection) {
      _.forEach(intakeSection, (menu) => {
        _.forEach(menu.submenu, (data) => {
          // data.percentage = menu.percentage != 0 ? menu.percentage / menu.submenu.length : 0;
          data.percentage = 100 / menu.submenu.length;
          data.requiredFields = [];

        })
      })
      // }
      this.sectionData = intakeSection;
      this.basicInfoRequiredData = this.getRequiredFields(intakeSection);
      // this.updateRequiredFieldsStatus(this.commonBasicInfoObj);

    }
  }

  getLocation(intakeId, intakeType): Observable<any> {
    return this.httpService.getApi(`Intake/IntakeLocation?IntakeId=${intakeId}&IntakeType=${intakeType}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }

  postLocation(intakeLocation): Observable<any> {
    return this.httpService.postApi('Intake/IntakeLocation', intakeLocation, '')
      .pipe(
        tap(res => {
          if (res) {
            
            return res;
            
          }

        })
      );
  }
  getStaff(intakeId): Observable<any> {
    return this.httpService.getApi(`intake/StaffInfo?IntakeId=${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {
            return res;
          }

        })
      );
  }
  postStaff(staffInfo): Observable<any> {
    return this.httpService.postApi('intake/StaffInfo', staffInfo, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  saveEnhancedIntakeData(enhancedIntake): Observable<any> {
    return this.httpService.postApi(`intake/IntakeEnhanced`, enhancedIntake, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getEnhancedPages(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.enhancedPages}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getPremiumPages(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.premiumPages}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getReferenceContent(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.referenceContent}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getEnhancedData(intakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/IntakeEnhanced/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getIntakePremium(intakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/IntakePremium/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  postPremiumPagesData(data): Observable<any> {
    return this.httpService.postApi('intake/IntakePremium', data, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  saveCallTrackingIntake(data): Observable<any> {
    return this.httpService.postApi('intake/IntakeCalltracking', data, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getCallTrackingIntake(intakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/IntakeCalltracking/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getFieldData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.smm_field}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getSpecialServiceData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.smm_speciality_services}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getHearingAidsData(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.hearingAid}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getSMSData(intakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/IntakeSMS/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }


  postSMSFormData(smsFormData): Observable<any> {
    return this.httpService.postApi('Intake/IntakeSMS', smsFormData, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getReviewGen(intakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/IntakeReviewGen/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  postReviewGenFormData(reviewGenFormData): Observable<any> {
    return this.httpService.postApi('Intake/IntakeReviewGen', reviewGenFormData, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  savesmmIntake(data): Observable<any> {
    return this.httpService.postApi('intake/IntakeSMM', data, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getReviewPlateForms(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.reviewPlateForms}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getSmmIntake(intakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/IntakeSMM/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  saveRedesignIntake(data): Observable<any> {
    return this.httpService.postApi('intake/IntakeRedesign', data, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getRedesignIntake(intakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/IntakeRedesign/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  saveDomainIntake(data): Observable<any> {
    return this.httpService.postApi('intake/IntakeDomain', data, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getDomainIntake(intakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/IntakeDomain/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getEmailIntake(intakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/IntakeEmail/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  saveEmailIntake(data): Observable<any> {
    return this.httpService.postApi('intake/IntakeEmail', data, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getLocalListingsIntake(intakeId){
    return this.httpService.getApi(`LocalListings/IntakeLocalListings/${intakeId}`)
      .pipe(
        tap(res => {
          if(res) {
            return res;
          }
        })
      )
  }

  getCitationIntake(intakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/IntakeCitationCleanUp/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  saveCitationIntake(data): Observable<any> {
    return this.httpService.postApi('intake/IntakeCitationCleanUp', data, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getIntakeLogo(intakeId): Observable<any[]> {
    return this.httpService.getApi(`Intake/IntakeLogo/${intakeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  saveIntakeLogo(data): Observable<any> {
    return this.httpService.postApi('Intake/IntakeLogo', data, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getIcon(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.icon}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getFont(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.font}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getSampleData(pageId): Observable<any> {
    return this.httpService.getApi(`WordPress/Page?PageId=${pageId}`)
      .pipe(
        tap(res => {
          return res;
        })
      );
  }

  getAuditType(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.audit_type}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getPracticeType(): Observable<any[]> {
    return this.httpService.getApi(`master/${masterEnum.practice_type}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getAuditRequestData(filterBy): Observable<any[]> {
    return this.httpService.getApi(`site/Proposal?CPId=${filterBy.CPId || 0}&StartDate=${filterBy.formattedStartDate || ''}&EndDate=${filterBy.formattedEndDate || ''}&WebsiteUrl=${filterBy.websiteUrl || ''}&PracticeName=${filterBy.practiceName || ''}&Account=${filterBy.account || ''}&Email=${filterBy.email || ''}&Type=${filterBy.type || ''}&UserName=${filterBy.userName || ''}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getCustomerForCP(submittedBy): Observable<any[]> {
    return this.httpService.getApi(`site/CustomerForCP?CPUserId=${submittedBy}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  saveAuditRequest(data): Observable<any> {
    return this.httpService.postApi('site/Proposal', data, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getAuditDetail(id):Observable<any>{
    return this.httpService.getApi(`site/Proposal/${id}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }

  postApprovalStatus(data): Observable<any> {
    return this.httpService.postApi('intake/IntakeApprovalStatus', data, '')
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  deleteCiation(intakeId, srNo): Observable<any> {
    return this.httpService.deleteApi(`intake/IntakeCitationCleanUp?IntakeId=${intakeId}&SrNo=${srNo}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getIntakeDetails(intakeId, IntakeTypeId): Observable<any> {
    return this.httpService.getApi(`intake/intakemaster/${intakeId}/${IntakeTypeId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getRequestAdditionalData(issueId): Observable<any> {
    return this.httpService.getApi(`site/ProposalTicket?IssueIds=${issueId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }
  getComment(intakeId,intakeTypeId):Observable<any> {
    return this.httpService.getApi(`Intake/Comment?IntakeId=${intakeId}&IntakeTypeId=${intakeTypeId}`)
      .pipe(
        tap(res=>{
          if(res){
            return res;
          }
        })
      );
  }
  getMaster(id){
    return this.httpService.getApi(`master/${id}`)
    .pipe(
      tap(res=>{
        if(res){
          return res;
        }
      })
    );
  }
  getLocalListingsTagTypes(){
    return this.httpService.getApi(`Master/General/51`)
      .pipe(
        tap(res => {
          if(res){
            return res;
          }

      })
    );
  }
  /**
   * Returns an object containing Business Tags grouped by name
   * @param {IntakeLocalListing} intake The Local Listing Intake
   * @param {Boolean} idsOnly A flag to return only the IDs instead of the objects
   *
   * @return {Object} The formatted tag object
   */
  getTagsByName(intake: IntakeLocalListing, idsOnly = false): { [key: string]: Array<any> } {
    const tagsObject = {};

    if (Array.isArray(intake.business_tags)) {
      for (let i = 0; i < intake.business_tags.length; i++) {
        const tagTypeName = intake.business_tags[i].tagInfo.tag_type_name;

        if (typeof tagsObject[tagTypeName] !== 'object') {
          tagsObject[tagTypeName] = [];
        }

        let tagData = intake.business_tags[i] as any;

        if (idsOnly) {
          tagData = intake.business_tags[i].ll_tag_id;
        }

        tagsObject[tagTypeName].push(tagData);
        tagsObject['ll_business_id'] = intake.business_tags[i].ll_business_id;
      }
    }

    return tagsObject;
  }

  /**
   * Returns tags for the Location form
   * @param {LlListingTag[]} locationTags An array of tags for the location form
   * @param {Boolean} idsOnly Flag that determines whether or not the returned object is keys only
   *
   * @return {Array}
   */
  getLocationTagsByNameOrID(
    locationTags: LlListingTag[],
    idsOnly = false
  ): { [key: string]: Array<any> } {
    const tagsObject = {};

    // Check if location tags is an array
    if (Array.isArray(locationTags)) {
      for (let i = 0; i < locationTags.length; i++) {
        const tagTypeName = locationTags[i].tagInfo.tag_type_name;

        if (tagTypeName && typeof tagsObject[tagTypeName] !== 'object') {
          tagsObject[tagTypeName] = [];
        }

        let tagData = locationTags[i] as any;

        if (idsOnly) {
          tagData = locationTags[i].ll_tag_id;
        }

        tagsObject[tagTypeName].push(tagData);
      }
    }

    return tagsObject;
  }

}

