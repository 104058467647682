import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteService } from '../service/site.service';

@Component({
  selector: 'app-modify-subscription',
  templateUrl: './modify-subscription.component.html',
  styleUrls: ['./modify-subscription.component.css']
})
export class ModifySubscriptionComponent implements OnInit {

siteId: number;
product: any;
modifiedData = {
  quantity: 0,
  prorate: false
}

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.modifiedData.quantity = this.product.quantity;
    this.modifiedData['productId'] = this.product.productId;
    let obj = {
      siteId: this.siteId
    }
  }

  dismissModal() {
    this.activeModal.close();
  }
  modifySubscription(){
      this.activeModal.close(this.modifiedData);
  }
}
