import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { SectionStatus } from '../basic/review/section-model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { environment } from 'environments/environment';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../services/http.service';
@Component({
  selector: 'app-review-gen',
  templateUrl: './review-gen.component.html',
  styleUrls: ['./review-gen.component.css']
})
export class ReviewGenComponent implements OnInit {

  reviewGenForm: UntypedFormGroup;
  @Input() submenu;
  @Input() intakeTypeId;
  @Input() menuId;
  intakeId:number=null;
  siteId:number=null;
  selectedIntakeData;
  existingData;
  socialMediaData;
  selectedMedia=[];
  baseUrl = environment.imgUrl;
  @Output() continue = new EventEmitter<any>();
  @Output() changeStatus= new EventEmitter<any>();
  results;
  checkLocationField:boolean=false;
  pmsProviderData;
  constructor(private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    private httpService: HttpService,
    public dataService:DataService,
    private toastrService: ToastrService) {
      this.activatedRoute.params.subscribe(params => {
        if (params.id && params.siteId) {
          this.intakeId = params.id;
          this.siteId = params.siteId;
        }
      });
    }

  ngOnInit() {

    this.getIntakeProductList(this.intakeId);
    this.getReviewGen(this.intakeId);
    this.getPmsProviderData();
    //this.getReviewPlateForms();
    //this.initializeForm();
  }

  initializeForm() {
    this.reviewGenForm = this.formBuilder.group({
       intakeId: this.intakeId,
       pmsProviderType: new UntypedFormControl(this.existingData ?this.existingData.pmsProviderType:0),
       AdditionalReviewPlatform: this.existingData ?this.existingData.additionalReviewPlatform:null,
       intakeType: new UntypedFormControl(this.intakeTypeId),
       logo: new UntypedFormControl(this.existingData ? this.existingData.logo : null),
       lstLocation: this.formBuilder.array([
        this.locationInstance(),
      ]),
    })
  }

  isFieldValid(field: string,index:number =0) {

    let control=this.reviewGenForm.get('lstLocation');
     if(field=='locationName'){
       for (let i=index;i<control['controls'].length;i++){
         return !control['controls'][i].controls.locationName.valid && control['controls'][i].controls.locationName.touched;
       }
     }else if(field=='email'){
      for (let i=index;i<control['controls'].length;i++){
        return !control['controls'][i].controls.email.valid && control['controls'][i].controls.email.touched;
      }
     }else{
      return !this.reviewGenForm.get(field).valid && this.reviewGenForm.get(field).touched;
    }
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }
  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof UntypedFormArray){
        for (let i=0;i<control.controls.length;i++){
          this.validateAllFormFields(control.controls[i]);
        }
      }
    });
  }

  setLocationControl(data) {
    this.reviewGenForm.setControl('lstLocation', this.formBuilder.array(data.map(c =>
      this.locationInstance(c)
    )))
  }
  locationInstance(data?) {
    let location = this.formBuilder.group({
      id: data ? data.id:0,
      locationName: new UntypedFormControl(data ? data.locationName : null),
      email: new UntypedFormControl(data ? data.email : null)
    });
    return location;
  }


  getReviewGen(intakeId){
    this.intakesService.getReviewGen(intakeId).subscribe((res) => {
      if(res){
        this.existingData = res;

        this.initializeForm();
        if(this.existingData.oIntakeLocation.lstLocation.length){
          this.setLocationControl(this.existingData.oIntakeLocation.lstLocation);
        }
        this.getReviewPlateForms();

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }


  getReviewPlateForms(){
    this.intakesService.getReviewPlateForms().subscribe((res) => {
      if(res){
        this.socialMediaData=res['masterList'];
        this.results = this.socialMediaData.map(function(el) {
          let o = Object.assign({}, el);
          o.isActive = false;
          return o;
        })

        if(this.existingData.additionalReviewPlatform){
        _.forEach(this.existingData.additionalReviewPlatform.split(','), (data) => {
          for(let i=0;i<this.results.length;i++){
            if(this.results[i].id.toString()===data){
              this.results[i].isActive=true;
              this.selectedMedia.push(this.results[i].id);
            }
          }
      });
    }
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getIntakeProductList(intakeId) {
    this.intakesService.getIntakeProductList(intakeId).subscribe((res) => {
      if (res) {
        this.selectedIntakeData = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getValue(i, action) {
    if (action == 'add') {
      this.reviewGenForm.controls.lstLocation['controls'].push(
        this.locationInstance()
      );
    } else {
      if(this.reviewGenForm.controls.lstLocation['controls'].length > 1){
        this.reviewGenForm.controls.lstLocation['controls'].splice(i, 1);
      }
    }
    this.reviewGenForm.get('lstLocation').updateValueAndValidity();
  }

  IsBluePrintAccount(){

  }

  saveFormData(form, mode?){
    this.reviewGenForm.get('lstLocation').updateValueAndValidity();
    if(this.reviewGenForm.valid){
      let reviewGenFormData={
       intakeId: this.intakeId,
       IsBluePrintAccount: form.value.IsBluePrintAccount,
       AdditionalReviewPlatform: form.value.AdditionalReviewPlatform,
       Logo:form.value.logo,
       pmsProviderType:form.value.pmsProviderType,
       oIntakeLocation: {
           intakeType: this.intakeTypeId,
           lstLocation: form.value.lstLocation
      }
  };

  if(reviewGenFormData.oIntakeLocation.lstLocation.length==1){
    this.checkLocationField=true;
  }else{
    _.forEach(reviewGenFormData.oIntakeLocation.lstLocation, (location) => {
          this.checkLocationField=true;
        if((location.locationName == '' || location.locationName == null) || (location.email == '' || location.email == null)){
        this.checkLocationField=false;
        this.toastrService.error("Please fill the location information", 'Error');
          return false;
        }
    })
  }
   if(this.checkLocationField){
    this.intakesService.postReviewGenFormData(reviewGenFormData).subscribe(res => {
      if(res){
        this.changeStatus.next();
        this.toastrService.success('Saved Successfully', 'Success');
        this.intakesService.updateRequiredFieldsStatus(form.value);
        let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.submenu[0].id, this.intakeId);
        this.postIntakeSection(sectionStatus);
        if(mode == 'continue'){
          this.goToNext('parent');
        }
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
    }

    }else{

      this.validateAllFormFields(this.reviewGenForm);
      if(form.value.lstLocation.length > 1){
        this.toastrService.error("Please fill the location information", 'Error');
        this.httpService.openErrorPopup("Please fill the location information");
      }else{
        this.httpService.openErrorPopup(this.dataService.requiredMsg);
      }

    }

  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  selectSocialMedia(media,event){

    if (event.target.checked) {
      this.selectedMedia.push(media.id);
    } else {
      for (var i = 0; i < this.selectedMedia.length; i++) {
          this.selectedMedia.splice(i, 1);
      }
    }
   this.reviewGenForm.controls.AdditionalReviewPlatform.setValue(this.selectedMedia.join(","));
  }

  goToNext(menu){
    this.continue.next(menu);
  }
  openUploadedFilesPopup(fieldName) {
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = this.siteId;
    model.componentInstance.field = fieldName;
    model.result.then((result) => {
      if (result) {
        // let field = result.field;
        let control = _.pick(this.reviewGenForm.controls, fieldName);
        control[fieldName].patchValue(result[0]);
      }
    }, (reason) => {

    });
  }
  getPmsProviderData() {
    this.intakesService.getPmsProviderData().subscribe((res) => {
      if (res) {
        this.pmsProviderData = res['masterList'];
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

}
