import {
  Component,
  ElementRef,
  OnInit,
  AfterViewInit,
  AfterViewChecked,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { SiteService } from '../service/site.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { PaymentHistoryComponent } from '../../customer';
import { ManageCardComponent } from '../../customer/manage-card/manage-card.component';
import { DowngradeProductComponent } from '../site-config/downgrade-product/downgrade-product.component';
import { SiteProductComponent } from '../site-product/site-product.component';
import { StartBillingComponent } from '../start-billing/start-billing.component';
import { UserService } from '../../admin/users/services/user-service.service';
import { SubscriptionPausedComponent } from '../subscription-paused/subscription-paused.component';
import { RefundsComponent } from '../refunds/refunds.component';
import { ProductListItem, Result } from '@shared/interfaces/billing';
import { Location } from '@angular/common';
import { getCurrentRole } from '@shared/security/role-guards.service';
import { CorporatePartnerService } from '../../admin/manage-corporate-partner/services/corporate-partner.service';
import { CorporatePartner } from '@shared/interfaces/client';
import { SiteInfo } from '@shared/interfaces/site';
import { hasPermission } from '@shared/policies/permissions';
import { ManageLocationsComponent } from 'app/components/billing/manage-locations/manage-locations.component';
import { HttpService } from '@shared/services/http/http.service';

@Component({
  selector: 'app-site-setting',
  templateUrl: './site-setting.component.html',
  styleUrls: ['./site-setting.component.css'],
})
export class SiteSettingComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  corporatePartnerBelongingToSite;
  corporatePartners: CorporatePartner[];
  errorMessage: string;
  hasPermission = hasPermission;
  site;
  siteId;
  userId;
  allSites;
  selectedSite: SiteInfo;
  siteIsLock;
  parentInfo = JSON.parse(localStorage.getItem('parentInfo'));
  ProductLists: ProductListItem[] = [];
  productId;
  userInfo =
    localStorage.getItem('userInfo') != null
      ? JSON.parse(localStorage.getItem('userInfo'))
      : { token: '', userId: null, roleId: null };
  fromSidebar = false;
  isCpUser = false; // Add this flag to identify CP users

  @ViewChild(PaymentHistoryComponent)
  private PaymentHistoryComponent: PaymentHistoryComponent;
  @ViewChild(ManageCardComponent)
  private ManageCardComponent: ManageCardComponent;
  @ViewChild(DowngradeProductComponent)
  private DowngradeProductComponent: DowngradeProductComponent;
  @ViewChild(SiteProductComponent)
  private SiteProductComponent: SiteProductComponent;
  @ViewChild(StartBillingComponent)
  private StartBillingComponent: StartBillingComponent;
  @ViewChild(SubscriptionPausedComponent)
  private SubscriptionPausedComponent: SubscriptionPausedComponent;
  @ViewChild(RefundsComponent)
  private RefundsComponent: RefundsComponent;

  @ViewChild(ManageLocationsComponent, { static: false })
  private ManageLocationsComponent: ManageLocationsComponent;
  @ViewChild('siteSettingSearchViewChildId') siteSettingSearchField: ElementRef;
  refreshFlag: boolean;

  constructor(
    private corporatePartnerService: CorporatePartnerService,
    protected siteService: SiteService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private location: Location,
    private cdr: ChangeDetectorRef, // Inject ChangeDetectorRef
    private router: Router // Inject Router
  ) {
    this.productId = this.activatedRoute.snapshot.queryParamMap.get('productId');
    this.isCpUser = getCurrentRole() === 'corporate-partner'; // Check if the user is a CP user
    this.getSites();
  }

  ngOnInit() {
    this.initializeComponent();
  }

  ngAfterViewInit() {
    const isImpersonated = localStorage.getItem('impersonateSite') === 'true';
    if (isImpersonated && !this.areLocalStorageVariablesAvailable()) {
      this.setLocalStorageVariables();
    } else {
      this.reinitializeComponent();
    }
    if (
      this.siteSettingSearchField &&
      this.siteSettingSearchField.nativeElement
    ) {
      this.siteSettingSearchField.nativeElement.focus();
    }
  }

  areLocalStorageVariablesAvailable(): boolean {
    return localStorage.getItem('selectedSite') !== null;
  }

  setLocalStorageVariables() {
    // Simulate setting local storage variables
    this.siteService.getSiteInfo(this.siteId).subscribe((siteDetails) => {
      this.selectedSite = siteDetails;
      this.site = siteDetails;
      localStorage.setItem('selectedSite', JSON.stringify(siteDetails));
      // After setting the variables, reinitialize components
      this.refreshComponents(siteDetails);
    });
  }

  initializeComponent() {
    this.fromSidebar =
      this.activatedRoute.snapshot.queryParamMap.get('fromSidebar') === 'true';

    const previousUrl = localStorage.getItem('previousUrl');
    if (this.fromSidebar && !previousUrl?.includes('client')) {
      localStorage.removeItem('selectedSite');
      this.site = null;
      this.selectedSite = null;
    } else {
      this.loadSelectedSiteFromLocalStorage();
    }

    this.checkAndCleanUrl();
    this.getCorporatePartners();
    this.errorMessage = '';

    this.ProductLists = [
      { id: 4, name: 'Billing Subscription' },
      { id: 5, name: 'Downgrade Package' },
      { id: 6, name: 'Create Invoice' },
      { id: 7, name: 'Paused Subscription' },
      { id: 8, name: 'Refunds' },
      { id: 9, name: 'Manage Locations' },
    ];

    if (this.site) {
      if (this.site.id === undefined && this.site.siteId) {
        this.site.id = this.site.siteId;
      } else if (this.site.id && this.site.siteId === undefined) {
        this.site.siteId = this.site.id;
      }
    }

    this.userId = this.userInfo.userId;
    this.isCpUser = this.userInfo.roleId > 10; // Set the flag for CP users

    if (this.userInfo.roleId <= 10) {
      this.activatedRoute.url.subscribe(() => {
        this.siteId = this.activatedRoute.snapshot.paramMap.get('siteId');
        if (this.siteId) {
          this.getSiteInfo(this.siteId);
        }
      });
    } else {
      this.siteId = this.site ? this.site.id || this.site.siteId : 0;
    }

    if (this.activatedRoute.snapshot.children.length > 0) {
      this.productId =
        this.activatedRoute.snapshot.firstChild.data.productId ?? 1;
    } else {
      this.productId = 1;
    }

    if (this.site) {
      this.getSelectedSiteId(this.site, false);
    } else {
      // If no site is selected, refresh components with null
      this.refreshComponents(null);
    }
  }

  reinitializeComponent() {
    this.loadSelectedSiteFromLocalStorage();
    this.checkAndCleanUrl();
    this.getCorporatePartners();
    this.errorMessage = '';

    this.ProductLists = [
      { id: 4, name: 'Billing Subscription' },
      { id: 5, name: 'Downgrade Package' },
      { id: 6, name: 'Create Invoice' },
      { id: 7, name: 'Paused Subscription' },
      { id: 8, name: 'Refunds' },
      { id: 9, name: 'Manage Locations' },
    ];

    if (this.site) {
      if (this.site.id === undefined && this.site.siteId) {
        this.site.id = this.site.siteId;
      } else if (this.site.id && this.site.siteId === undefined) {
        this.site.siteId = this.site.id;
      }
    }

    this.userId = this.userInfo.userId;

    if (this.userInfo.roleId <= 10) {
      this.activatedRoute.url.subscribe(() => {
        this.siteId = this.activatedRoute.snapshot.paramMap.get('siteId');
        if (this.siteId) {
          this.getSiteInfo(this.siteId);
        }
      });
    } else {
      this.siteId = this.site ? this.site.id || this.site.siteId : 0;
    }

    if (this.activatedRoute.snapshot.children.length > 0) {
      this.productId =
        this.activatedRoute.snapshot.firstChild.data.productId ?? 1;
    } else {
      this.productId = 1;
    }

    if (this.site) {
      this.getSelectedSiteId(this.site, false);
    } else {
      // If no site is selected, refresh components with null
      this.refreshComponents(null);
    }
  }

  resetSiteSelection() {
    this.selectedSite = null;
    this.siteId = null;
    this.site = null;

    // Remove the selected site from local storage
    localStorage.removeItem('selectedSite');

    // Trigger refresh in child components, passing `null` to signify reset
    this.productId = 1;
    this.refreshComponents(null);

    // Set default tab
    this.changeTabs(1);

    // Force Angular to update views
    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.errorMessage = null;
  }

  get selectedSiteIsSet(): boolean {
    return this.selectedSite != undefined && true;
  }

  changeTabs(productId: number): void {
    this.productId = productId;
    this.location.replaceState(`${getCurrentRole()}/billing`);
    this.cdr.detectChanges(); // Manually trigger change detection
  }

  getCorporatePartners(): void {
    this.corporatePartnerService.getCorporatePartners().subscribe(
      (res) => {
        if (res) {
          this.corporatePartners = res;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getSelectedSiteId(site: SiteInfo, saveToStorage: boolean = true): void {
    this.siteId = site.id ?? site.siteId;

    this.siteService.getSiteInfo(this.siteId).subscribe(
      (siteDetails) => {
        this.selectedSite = siteDetails;
        this.site = siteDetails;
        if (saveToStorage) {
          localStorage.setItem('selectedSite', JSON.stringify(siteDetails));
        }

        this.siteIsLock = siteDetails.isLock;
        this.siteService.siteIsLock = this.siteIsLock;

        this.setCorporatePartnerFromSite();

        this.refreshComponents(siteDetails);
      },
      (error) => {
        console.error('Error fetching site details:', error);
      }
    );
  }

  getSites(): void {
    this.siteService.getSites().subscribe(
      (res) => {
        if (res) {
          this.allSites = Object.assign([], res);
        }
      },
      (err) => {
        throw err;
      }
    );
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 3
          ? []
          : this.allSites
              .filter(
                (v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
              )
              .slice(0, 10)
      )
    );

  searchForSite(): void {
    if (this.selectedSite) {
      this.getSelectedSiteId(this.selectedSite, true);
    }
  }

  onSelectItem(item: SiteInfo): void {
    this.selectedSite = item;
    this.siteId = item.id;
    localStorage.setItem('selectedSite', JSON.stringify(item));

    // Ensure the site info is fully loaded
    this.siteService.getSiteInfo(this.selectedSite.id).subscribe(
      (siteDetails) => {
        this.selectedSite = siteDetails;
        localStorage.setItem('selectedSite', JSON.stringify(siteDetails));

        // Reinitialize the component or other necessary updates
        this.refreshComponents(this.selectedSite);

        // Refresh glyph actions or other components that might be affected
        this.cdr.detectChanges();

        // Redirect logic based on whether the user is a corporate partner (CP user) or not
        if (this.isCpUser) {
          this.router.navigate([`/${getCurrentRole()}/billing`], {
            queryParamsHandling: 'merge',
          });
        } else {
          this.router.navigate([`/${getCurrentRole()}/billing`], {
            queryParamsHandling: 'merge',
          });
        }
      },
      (error) => {
        console.error('Error fetching site details:', error);
      }
    );
  }

  setCorporatePartnerFromSite(): void {
    if (this.corporatePartners) {
      for (let i = 0; i < this.corporatePartners.length; i++) {
        const cp = this.corporatePartners[i];
        if (cp.id === this.selectedSite.corporatePartnerId) {
          this.corporatePartnerBelongingToSite = cp;
        }
      }
    }
  }

  formatter = (result: Result) => `${result.businessName} (${result.domainUrl})`;

  inputFormatter = (result: string) => `${result['name']}`;

  backToCancel(event) {
    this.productId = event.productId;
    this.getSiteInfo(event.siteId);
  }

  getSiteInfo(siteId) {
    this.siteService.getSiteInfo(siteId).subscribe(
      (res) => {
        if (res) {
          this.getSelectedSiteId(res);
          let site = this.allSites.find((site) => site.id === siteId);
          if (site) {
            site.isLock = res.isLock;
          }
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  loadSelectedSiteFromLocalStorage() {
    const siteString = localStorage.getItem('selectedSite');
    if (siteString) {
      try {
        const site = JSON.parse(siteString);
        this.selectedSite = site;
        this.site = site;
        this.cdr.detectChanges(); // Ensure change detection is triggered
      } catch (error) {
        console.error('Error parsing JSON from localStorage:', error);
      }
    } else {
      // If no site is selected, refresh components with null
      this.refreshComponents(null);
    }
  }

  refreshComponents(siteDetails: SiteInfo): void {
    // Ensure that child components are initialized
    if (this.PaymentHistoryComponent) {
      this.PaymentHistoryComponent.callFromParent(siteDetails);
    }
    if (this.ManageCardComponent) {
      this.ManageCardComponent.callFromParent(siteDetails);
    }
    if (this.DowngradeProductComponent) {
      this.DowngradeProductComponent.callFromParent(siteDetails);
    }
    if (this.SiteProductComponent) {
      this.SiteProductComponent.callFromParent(siteDetails);
    }
    if (this.StartBillingComponent) {
      this.StartBillingComponent.callFromParent(siteDetails);
    }
    if (this.SubscriptionPausedComponent) {
      this.SubscriptionPausedComponent.callFromParent(siteDetails);
    }
    if (this.RefundsComponent) {
      this.RefundsComponent.callFromParent(siteDetails);
    }
    if (this.ManageLocationsComponent) {
      this.ManageLocationsComponent.callFromParent(siteDetails);
    }

    this.refreshFlag = !this.refreshFlag;
    this.cdr.detectChanges(); // Ensure change detection is triggered after refresh
  }

  private checkAndCleanUrl(): void {
    const urlTree = this.router.parseUrl(this.router.url);
    if (urlTree.queryParams['fromSidebar']) {
      delete urlTree.queryParams['fromSidebar'];
      this.router.navigateByUrl(urlTree.toString());
    }
  }
}
