import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { SiteService } from '../service/site.service';
import { UserService } from '../../admin/users/services/user-service.service';
import { MasterService } from '../../admin/masters/services/master.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { CorporatePartnerService } from '../../../components/admin/manage-corporate-partner/services/corporate-partner.service';
import { masterEnum } from '@shared/master-enum.enum';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ConfirmModalComponent } from '../../../shared/confirm-modal/confirm-modal.component';
import { TicketService } from '../../ticket/service/ticket.service';
import { roles } from '@shared/master-enum.enum';
import { HttpService } from '@services/http.service';
import { environment } from 'environments/environment';
import { ExcelService } from '../../reporting/services/excel.service';
import { SiteInfo } from '@shared/interfaces/site';
import { Package } from '@shared/interfaces/client';
import { ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';
import { SystemService } from '@shared/services/system/system.service';
import { IntakeFamilyItem } from '@shared/interfaces/intakes';
import { InputGeneric } from '@shared/interfaces/forms';
import { hasPermission } from '@shared/policies/permissions';

@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: [
    '../../../shared/components/tables/tables-pages.css',
    './site-list.component.css',
  ],
  providers: [DatePipe],
})
export class SiteListComponent implements OnInit, PageHasTable {
  @ViewChild('dt') dataTable: TableGenericComponent;

  columnHeaders: ColumnHeader[] = [];
  extraOptions: {[key: string]: Array<InputGeneric>};
  hasPermission = hasPermission;
  intakeFamilyList: IntakeFamilyItem[];
  roles = roles;
  sites = [];
  currentRole;
  returnUrl;
  modal;
  siteType;
  jiraUrl;
  projectKey;
  filterBy = { domainUrl: null, siteName: null };
  siteStatus;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  parentInfo = JSON.parse(localStorage.getItem('parentInfo'));
  corporatePartner;
  accountManager;
  packageNames: Array<{name: string, id: number}> = [];
  projectType;
  package = [
    {
      id: 1,
      name: "Basic"
    },
    {
      id: 2,
      name: "Enhanced"
    },
    {
      id: 3,
      name: "Premium"
    }
  ];
  siteLists;
  wpSSOUrl = environment.wpSSO;

  constructor(
    private siteService: SiteService,
    private userService: UserService,
    private ticketService: TicketService,
    private masterService: MasterService,
    private httpService: HttpService,
    private datePipe: DatePipe,
    private corporatePartnerService: CorporatePartnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private excelService: ExcelService,
    public modalService: NgbModal,
    private toastrService: ToastrService,
    private renderer: Renderer2,
    private el: ElementRef,
    private systemService: SystemService,
  ) {
    this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
  }

  ngOnInit() {
    this.getQueryParams();
    this.getSiteStatus();
    this.getSiteType(masterEnum.site_type);
    this.getProductType();
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1].path;
      // console.log(url);
    })

    if (this.userInfo.roleId < 11) {
      this.getAccountManager();
      this.getCorporatePartner();
      this.getJiraUrl();
    }

    this.getSites();

    this.setPackageNames();

    this.getTableData();
  }

  /**
   * Returns if a user is a client as opposed to an admin or internal user
   *
   * @return {Boolean} True, if the user is a client, false if not
   */
  get userIsClient(): boolean {
    return this.userInfo.roleId === '11' || this.userInfo.roleId === '13';
  }

  public resetPagination() {
    // this.mf.setPage(1, this.mf.rowsOnPage);
  }

  getProductType() {
    this.masterService.getGeneralMasterByType(4).subscribe((res) => {
      if (res) {
        this.projectType = res;
      }
    },
      err => {
        throw err;
      })
  }

  getQueryParams() {
    this.filterBy['isActive'] = JSON.parse(this.activatedRoute.snapshot.queryParamMap.get('isActive'));
    this.filterBy['isLive'] = JSON.parse(this.activatedRoute.snapshot.queryParamMap.get('isLive'));
    this.filterBy['status'] = this.activatedRoute.snapshot.queryParamMap.get('status');
    this.filterBy['startDate'] = this.activatedRoute.snapshot.queryParamMap.get('startDate');
    this.filterBy['endDate'] = this.activatedRoute.snapshot.queryParamMap.get('endDate');
    this.filterBy['dateType'] = this.activatedRoute.snapshot.queryParamMap.get('dt');
    this.filterBy['cancelled'] = this.activatedRoute.snapshot.queryParamMap.get('is_cancel');
    this.filterBy['backCount'] = this.activatedRoute.snapshot.queryParamMap.get('back');
    this.filterBy['project'] = this.activatedRoute.snapshot.queryParamMap.get('project') || this.activatedRoute.snapshot.queryParamMap.get('product');
    this.filterBy['productType'] = this.activatedRoute.snapshot.queryParamMap.get('product_type');
    this.filterBy['cp'] = this.activatedRoute.snapshot.queryParamMap.get('cp');
    this.filterBy['isNew'] = this.activatedRoute.snapshot.queryParamMap.get('isNew');
    this.filterBy['taskStatus'] = this.activatedRoute.snapshot.queryParamMap.get('taskStatus');
    this.getSiteBySearch(this.filterBy);
  }

  loadSite(site) {
    let siteInfo = {
      siteId: site.id,
      domainUrl: site.domainUrl
    }
    localStorage.setItem('selectedSite', JSON.stringify(siteInfo));
    this.router.navigate([`${this.currentRole}/dashboard`]);
  }

  /**
   * Gets data for the tables and sets the column headers
   *
   * @TODO Move API call to this method
   */
  getTableData(): void {
    this.columnHeaders = [
      {field: 'domainUrl', header: 'URL', width: 13},
      {field: 'name', header: 'Business Name', width: 10},
      {field: 'siteType', header: 'Practice Type', filter: 'select'},
      {field: 'corporatePartnerName', header: 'Program', filter: 'select'},
      {field: 'cpAccountNo', header: 'Account #', width: 5},
      {field: 'accountManagerName', header: 'AM', filter: 'select', width: 5},
      {field: 'intakeFamily', header: 'Service', filter: 'select', hasFilterChangeEvent: true, excludeColumnContentInFilter: true},
      {field: 'packageName', header: 'Package', filter: 'select', width: 5},
    ];
    if(!this.userIsClient){
      this.columnHeaders.push({field: 'siteId', header: 'Site ID', width: 3});
    }
  }

  /**
   * Filters the list of Sites on the Clients index page
   *
   * @param {Object} filterBy
   */
  getSiteBySearch(filterBy) {
    filterBy['formattedStartDate'] = this.datePipe.transform(filterBy.startDate, 'yyyy-MM-dd');
    filterBy['formattedEndDate'] = this.datePipe.transform(filterBy.endDate, 'yyyy-MM-dd');

    this.systemService.getFamilyList().subscribe(
      (res) => {
        if (res) {
          this.intakeFamilyList = res;

          this.extraOptions = {
            'intakeFamily': this.intakeFamilyList.map((intakeFamily) => {
              return {
                text: intakeFamily.display,
                value: intakeFamily.display,
                // disable the None family. No client will ever have this family.
                disabled: intakeFamily.display === '-'
              } as InputGeneric;
            }),
          };
        }
      },
      () => {},
      () => {
        this.siteService.getAdvanceSearchData(filterBy).subscribe(
          (res: SiteInfo[]) => {
            if (res) {
              this.sites = res;

              const intakeFamilyObject = {};

              this.intakeFamilyList.map((item) => {
                intakeFamilyObject[item.id.toString()] = item.display;
              });

              for (const i in this.sites) {
                if (!this.sites[i].packageName) {
                  this.sites[i].packageName = 'A La Carte';
                }

                if(this.sites[i].intakeTypeId == 0){
                  this.sites[i].intakeFamily = this.sites[i].intakeType;
                } else {
                  this.sites[i].intakeFamily = intakeFamilyObject[this.sites[i].intakeTypeId];
                }
              }

              this.resetPagination();
            }
          },
          (err) => {
            this.httpService.openErrorPopup(err.error.message);
          }
        );
      }
    );
  }

  getSiteStatus() {
    this.masterService.getGeneralMasterByType(5).subscribe((res) => {
      if (res) {
        this.siteStatus = res;
      }
    },
      err => {
        throw err;
      })
  }

  getCorporatePartner() {
    this.corporatePartnerService.getCorporatePartners().subscribe(res => {
      if (res) {
        this.corporatePartner = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getAccountManager() {
    this.siteService.getAccountManager().subscribe(res => {
      if (res) {
        this.accountManager = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  selectedItem(obj?) {
    this.getSiteBySearch(this.filterBy);
  }

  getSiteType(id) {
    this.siteService.getMastersListById(id).subscribe((res) => {
      if (res) {
        this.siteType = res.masterList;
      }
    },
      err => {
        throw err;
      })
  }


  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.siteLists.filter(v => v['businessName'].toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  /**
   * Sets the selected site
   * @param {SiteInfo} site
   */
  setSelectedSite(site: SiteInfo): void {
    localStorage.setItem('selectedSite', JSON.stringify(site));
  }

  formatter = (result: string) => `${result['businessName']}`;
  inputFormatter = (result: string) => `${result['businessName']}`;



  deleteSite(siteId) {
    var model = this.modalService.open(ConfirmModalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.message = 'Are you sure you want to delete this site?';
    model.result.then((result) => {
      if (result) {
        this.siteService.deleteSite(siteId).subscribe(res => {
          if (res) {
            this.getSiteBySearch(this.filterBy);
            this.toastrService.success('Delete successfully', 'success');
          }
        }, err => {
          this.toastrService.error(err, 'Error');
        })
      }
    }, (reason) => {
      console.log(`Dismissed: ${reason}`);
    });
  }
  cancelSubscription(siteId) {
    this.router.navigate([`${this.currentRole}/cancel-subscription/${siteId}`]);
    // this.modal = this.modalService.open(SiteProductComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: 'custom-class' });
    // this.modal.componentInstance.siteId = siteId;
  }

  searchDomain = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.siteLists.filter(v => v['domainUrl'].toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  domainFormatter = (result: string) => `${result['domainUrl']}`;
  domainInputFormatter = (result: string) => `${result['domainUrl']}`;

  filter(param) {
    if (param.length >= 3) {
      this.getSiteBySearch(this.filterBy);
    }
  }

  getSites(){
    this.siteService.getSites().subscribe((res) => {
      if (res) {
        this.siteLists = Object.assign([], res);
      }
    },
      err => {
        throw err;
      })
  };

  getJiraUrl() {
    this.ticketService.getJiraUrl().subscribe(res => {
      if (res) {
        this.jiraUrl = res['url'];
        this.projectKey = res['ProjectKey'];
      }
    },
      err => {
        throw err;

      })
  }

  applySearch(inputKey, filterKey, key) {
    if ((typeof this.filterBy[inputKey]) == 'object') {
      this.filterBy[filterKey] = this.filterBy[inputKey][key];
    } else {
      this.filterBy[filterKey] = this.filterBy[inputKey];
    }
    this.getSiteBySearch(this.filterBy);
  }

  impersonate(site) {
    localStorage.setItem('parentInfo', localStorage.getItem('userInfo'));
    let data = {
      userId: site.userId,
      // parentId: JSON.parse(localStorage.getItem('userInfo')).userId
    }
    this.userService.impersonateUser(data).subscribe((res) => {
      if (res) {
        console.log(res);
        localStorage.setItem('userInfo', JSON.stringify(res));
        localStorage.setItem('impersonateSite', JSON.stringify(site.id));
        localStorage.setItem('selectedSite', JSON.stringify(site));
        // this.router.navigate([`/login`]);
        this.getUserProfile(res);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  downgrade(siteID) {
    this.router.navigate([`${this.currentRole}/downgrade-product/${siteID}`]);
  }

  getUserProfile(user) {
    this.userService.getUserProfile(user.userId).subscribe((res) => {
      if (res) {
        this.userService.UserProfile = res;
        let site;
        if (res.site.length && user.roleId != roles.corporatePartner.roleId) {
          let impersonateSite = _.find(res.site, (site) => {
            return site.siteId == localStorage.getItem('impersonateSite');
          })
          site = {
            siteId: impersonateSite.siteId || res.site[0].siteId,
            domainUrl: impersonateSite.domainUrl || res.site[0].domainUrl
          }
          localStorage.setItem('selectedSite', JSON.stringify(site));
        }
        this.goToDashboard(user);
      }
    },
      err => {
        throw err;
      })
  }

  goToDashboard(res) {
    if (this.returnUrl)
      this.router.navigateByUrl(this.returnUrl);
    else {
      let role = _.find(roles, (role) => {
        return role.roleId == res.roleId;
      })
      this.router.navigateByUrl(role.url);
    }
  }
  logIntoWP(wpPath: string, site: any): any {
    // Log the site object to check its contents
    console.log('Site object:', site);

    if (!site || !site.wpSitePath ) {
        this.toastrService.error('Site is missing.');
        return;
    }

    let wpSSOUrl = this.wpSSOUrl.replace('sitePath', wpPath);
    const wpForm = this.renderer.createElement('form');
    this.renderer.setAttribute(wpForm, 'id', 'wpForm');
    this.renderer.setAttribute(wpForm, 'action', wpSSOUrl);
    this.renderer.setAttribute(wpForm, 'target', "_blank");
    this.renderer.setAttribute(wpForm, 'method', "post");

    const inputEmail = this.renderer.createElement('input');
    this.renderer.setAttribute(inputEmail, 'type', 'text');
    this.renderer.setAttribute(inputEmail, 'name', 'email');
    this.renderer.setAttribute(inputEmail, 'value', site.email); // Use site email

    const inputToken = this.renderer.createElement('input');
    this.renderer.setAttribute(inputToken, 'type', 'text');
    this.renderer.setAttribute(inputToken, 'name', 'token');
    this.renderer.setAttribute(inputToken, 'value', this.userInfo.token); // Ensure userInfo.token is available

    this.renderer.appendChild(wpForm, inputEmail);
    this.renderer.appendChild(wpForm, inputToken);
    this.renderer.setStyle(wpForm, 'visibility', 'hidden');
    this.renderer.appendChild(this.el.nativeElement, wpForm);

    wpForm.submit();
    document.getElementById("wpForm").remove();
}



  gotoBilling(site) {
    localStorage.setItem('selectedSite', JSON.stringify(site));
    this.router.navigateByUrl(this.currentRole+'/billing');
  }

  /**
   * Navigates to the reporting page
   * @param {SiteInfo} site
   */
  gotoReporting(site): void {
    site['siteId'] = site.id;

    localStorage.setItem('selectedSite', JSON.stringify(site));

    this.router.navigateByUrl(this.currentRole+'/client-reports');
  }

  /**
   * Queries the API, formats the returned data, and adds it to the packageNames array
   *
   * @retun void
   */
  setPackageNames() {
    this.siteService.getPackages().subscribe((res: Package[]) => {
      this.packageNames = res.map((packageData: Package) => {
        return { name: packageData.packageName, id: packageData.id };
      });
    });
  }

  updateTableFilter(eventData) {
    // query GetSiteBySearch again with the passed in parameter
    // map field/column filter to filterBy param and set filterBy to the selected value
    this.mapTableColumnToFilterBy(eventData.field, eventData.event?.target?.value ?? '');
    this.getSiteBySearch(this.filterBy);
  }

  mapTableColumnToFilterBy(column: string, paramValue: string){
    switch(column){
      case 'intakeFamily':
        // if column filter is intakeFamily, set the productType filter to the family id of the selected service type
        this.filterBy['productType'] = this.intakeFamilyList.find(intakeFamily => {
          return intakeFamily.name === paramValue || intakeFamily.display === paramValue;
        })?.id ?? null;
        break;
    }
  }
}
