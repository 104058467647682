import * as _ from 'lodash';
import { IntakesService } from '../../../../services/intakes.service';


export class Photography {
    intakeId: number;
    subjectMatterAgeGroup: string;
    subjectMatterEthnicity: string;
    subjectMatterLifestyle: string;
    subjectMatterProducts: string;
    lSubjectMatterAgeGroup: string;
    lSubjectMatterEthnicity: string;
    lSubjectMatterLifestyle: string;
    lSubjectMatterProducts: string;
    photography: string;
    localLandmarks: string;


    constructor(info, intakeId) {
        this.intakeId = intakeId;
        this.photography = info.photography;
        this.localLandmarks = info.localLandmarks;
        this.subjectMatterAgeGroup = this.getSubjectMatterId(info.lSubjectMatterAgeGroup);
        this.subjectMatterEthnicity = this.getSubjectMatterId(info.lSubjectMatterEthnicity);
        this.subjectMatterLifestyle = this.getSubjectMatterId(info.lSubjectMatterLifestyle);
        this.subjectMatterProducts = this.getSubjectMatterId(info.lSubjectMatterProducts);
        this.lSubjectMatterAgeGroup = this.getSubjectMatterId(info.lSubjectMatterAgeGroup, 'list');
        this.lSubjectMatterEthnicity = this.getSubjectMatterId(info.lSubjectMatterEthnicity, 'list');
        this.lSubjectMatterLifestyle = this.getSubjectMatterId(info.lSubjectMatterLifestyle, 'list');
        this.lSubjectMatterProducts = this.getSubjectMatterId(info.lSubjectMatterProducts, 'list');
    }


    getSubjectMatterId(subjectMatter, type?) {
        let tAssocation: any = _.filter(subjectMatter, (association) => {
            return association.checked;
        });
        if (!type) {
            tAssocation = _.toString(_.map(tAssocation, 'id'));
        }
        return tAssocation;
    }

}

