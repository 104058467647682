import { Component, OnInit, ViewChild } from '@angular/core';
import { TicketService } from '../service/ticket.service';
import { UserService } from '../../admin/users/services/user-service.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { environment } from 'environments/environment';
import { roles } from '@shared/master-enum.enum';
import { HttpService } from '@services/http.service';
import { FileUploadService } from '@shared/services/file-upload.service';
import { AssetsComponent } from '../../customer/intakes/add-intakes-data/basic/content/assets/assets.component';
import { DataService } from '@shared/data-service.service';
import { SiteService } from '../../site/service/site.service';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';
import { Ticket, TicketResponse } from '@shared/interfaces/tickets';
import { PaginatorEvent } from '@shared/interfaces/events';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-ticketlist',
  templateUrl: './ticketlist.component.html',
  styleUrls: ['./ticketlist.component.css'],
})
export class TicketlistComponent implements OnInit, PageHasTable {
  public Editor = ClassicEditor;
  public editorConfig = {
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
      'link', 'unlink', '|',
      'bulletedList', 'numberedList', '|',
      'alignment', '|',
      'blockQuote', 'codeBlock', '|',
      'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', '|',
      'imageUpload', 'mediaEmbed', '|',
      'specialCharacters', '|',
      'horizontalLine', 'pageBreak', '|',
      'undo', 'redo', '|',
      'removeFormat', 'findAndReplace', 'sourceEditing'
    ],
    image: {
      toolbar: [
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        '|',
        'toggleImageCaption',
        'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    }
  };
  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
  subject;
  domain;
  roles = roles;
  tickets: TicketResponse;
  ticketList ?: Ticket[];
  page = 1;
  jiraStatus;
  activatedUrl;
  currentTab;
  sites;
  filterBy = {};
  allTickets;
  currentRole;
  queue;
  fileName;
  selectedSite;
  photographs: any = [];
  ticketType: number | string;
  ticketForm: UntypedFormGroup;
  jiraUrl;
  lables = ['ALL', 'AM', 'DEV', 'DESIGN', 'SEO', 'SEM'];
  projects = [{value:'CST',name :'Customer Service Ticket'  }, { value: 'AAP',name: 'Audit And Proposal'}, { value: 'SEM', name:'SEM Proposal'}];

  @ViewChild('dt') table: TableGenericComponent;

  constructor(
    private ticketService: TicketService,
    private userService: UserService,
    private siteService: SiteService,
    private activatedRoute: ActivatedRoute,
    private fileUploadService: FileUploadService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private httpService: HttpService,
    public modalService: NgbModal,
    private toastrService: ToastrService,
    public dataService:DataService
  ) {
    this.currentRole = _.find(roles, (role) => {
      return role.roleId == this.userInfo.roleId;
    });
  }

  ngOnInit() {
    localStorage.setItem('previousUrl', this.router.url);

    if(this.userInfo.roleId<11) {
      this.getJiraUrl();
    }

    //this.selectedSite = JSON.parse(localStorage.getItem('selectedSite'));

    // TODO: Cleanup and set to return a single datatype if possible
    let ticketType = this.userInfo.roleId == roles.clientCommunicationManager.roleId
                    ||this.userInfo.roleId == roles.accountManagement.roleId ?
      1 : (this.userInfo.roleId == roles.corporatePartner.roleId||this.userInfo.roleId == roles.corporatePartnerUser.roleId || this.userInfo.roleId == roles.client.roleId || this.userInfo.roleId == roles.siteUser.roleId) ? '' : 3;

    this.ticketType = ticketType;

    this.currentTab = this.userInfo.roleId == roles.clientCommunicationManager.roleId
    ||this.userInfo.roleId == roles.accountManagement.roleId ?
'Customer Service Ticket' : (this.userInfo.roleId == roles.corporatePartner.roleId || this.userInfo.roleId == roles.client.roleId || this.userInfo.roleId == roles.siteUser.roleId) ? 'Resolved' : 'Assigned to me';

    if (this.ticketType) {
      this.getTickets({ticketType: this.ticketType});
    }

    this.getJiraStatusData();

    if (_.isEmpty(this.userService.UserProfile)) {
      this.userService.getUserProfile(this.userInfo.userId).subscribe(
        (res) => {
          if (res) {
            this.userService.UserProfile = res;
            this.initializeForm(res);
          }
        },
        (err) => {
          throw err;
        }
      );
    } else {
      this.initializeForm(this.userService.UserProfile);
    }

    if (!_.isEmpty(this.userInfo.queue)) {
      this.userInfo['queueList'] = this.userInfo.queue.split(',');
    }

    this.getTableData();
  }

  /**
   * Gets data for the tables and sets the column headers
   *
   * @TODO Move API call to this method
   */
  getTableData() {
    
    // This switch is done with the idea that all external users will have a roleId greater than 10 and
    // internal users less than.  If we add a new role that breaks that convention, the logic here
    // will have to be updated.
    if (this.userInfo.roleId < 11)
    {
      this.columnDataTypes = {
        key: 'jiraKey',
        createdDate: 'dateMedium',
        dueDate: 'dateMedium',
      };
    }
    else{
      this.columnDataTypes = {
        key: 'portalTicketLink',
        createdDate: 'dateMedium',
        dueDate: 'dateMedium',
      };
    }
    

    this.columnHeaders = [
      {field: 'key', header: 'Ticket ID', filter: 'none', width: 9},
      {field: 'subject', header: 'Subject', filter: 'text', width: 19},
      {field: 'status', header: 'Status', filter: 'none', width: 9},
      {field: 'assignee', header: 'Assignee', filter: 'none', width: 10},
      {field: 'priority', header: 'Priority', filter: 'none'},
      {field: 'createdDate', header: 'Created Date', filter: 'none'},
      {field: 'dueDate', header: 'Due Date', filter: 'none'},
    ];

    if (this.ticketType != 22) {
      this.columnHeaders.splice(
        1,
        0,
        {field: 'domain', header: 'Domain', filter: 'text', width: 19});
    }
  }

  /**
   * Queries ticket list based on current page, meant to be used with a pagination component
   *
   * @param {PaginatorEvent} event
   */
  getTicketPage(event: PaginatorEvent): void {
    this.getTickets(
      {ticketType: this.ticketType},
      event.page,
      this.queue
    );
  }

  /**
   * Retrieves tickets from API
   *
   * @param {Object} args the query parameters for the API call
   * @param {String} page Page number used in server-side pagination
   * @param {String} queue The ticket queue
   */
  getTickets(args, page?, queue ?: string): void {
    this.queue = queue;

    if (args && this.ticketType != args.ticketType) {
      if (args.ticketType) {
        this.filterBy = {};
      }
    }

    this.ticketType = (args && args.ticketType) ? args.ticketType : this.ticketType;
    this.currentTab = (args && args.title) ? args.title : this.currentTab;

    const params = {
      pageNo: page || 0,
      ticketType: this.ticketType,
      project: this.filterBy['project'],
      status: this.filterBy['status'] || ((this.ticketType == 7 || this.ticketType == 21) ? 'NA' : ''),
      queue: queue || this.filterBy['label'],
      subject: this.subject,
      domain: this.domain,
      siteId: localStorage.getItem('selectedSite') ?
        JSON.parse(localStorage.getItem('selectedSite')).siteId : 0,
    };

    if (args && (args.ticketType == 1 || args.ticketType == 6 || args.ticketType == 7)) {
      if (localStorage.getItem('selectedSite')) {
        params['siteId'] = JSON.parse(localStorage.getItem('selectedSite')).siteId;
      }
    }

    this.ticketService.getTickets(params).subscribe(
      (res) => {
        if (res) {
          this.tickets = res;
          this.ticketList = this.tickets.tickets;

          if (this.tickets.queue != null) {
            this.filterBy['label'] = this.tickets.queue;
            this.page = page ? page+1 : 1;
          }
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      },
    );
  }

  getJiraStatusData() {
    this.ticketService.getJiraStatusData().subscribe((res) => {
      if (res) {
        this.jiraStatus = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }
  getJiraUrl() {
    this.ticketService.getJiraUrl().subscribe(res => {
      if (res) {
        this.jiraUrl = res['url'];
      }
    },
      err => {
        throw err;

      })
  }
  onPageChange(event) {
    
  }

  loadPage(event){
    
  }

  initializeForm(user?) {
    let site = {};

 
      this.getSites();
  

    this.ticketForm = this.formBuilder.group({
      CreatedBy: ['Customer'],
      Summary: [null,[Validators.required]],
      Description: [null,[Validators.required]],
      WebSite: [site ? site['domainUrl'] : '',[Validators.required]],
      siteId: [site ? site['siteId'] : '' ]
    });
  }

  getSites() {
    this.siteService.getSites().subscribe(res => {
      if (res) {
        

        this.sites = res;
        
      }
    }, 
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  convertHtmlToJiraText(html: string): string {
    if (!html) return "";

    let listCounter = 1; // Track ordered list numbers
    let inOrderedList = false; // Track ordered list context

    html = html
        .replace(/<\/p>/g, '\n\n') // Paragraphs: Double newline
        .replace(/<p>/g, '') // Remove <p> tags
        .replace(/<\/strong>/g, '*') // Bold close
        .replace(/<strong>/g, '*') // Bold open
        .replace(/<\/em>/g, '_') // Italic close
        .replace(/<em>/g, '_') // Italic open
        .replace(/<\/i>/g, '_') // Italic for <i>
        .replace(/<i>/g, '_')
        .replace(/<br\s*\/?>/g, '\n') // Convert <br> to newline
        .replace(/<h1>(.*?)<\/h1>/g, 'h1. $1\n') // Heading 1
        .replace(/<h2>(.*?)<\/h2>/g, 'h2. $1\n') // Heading 2
        .replace(/<h3>(.*?)<\/h3>/g, 'h3. $1\n') // Heading 3
        .replace(/<h4>(.*?)<\/h4>/g, 'h4. $1\n') // Heading 4
        .replace(/<blockquote[\s\S]*?>([\s\S]*?)<\/blockquote>/g, 'bq. $1\n') // Blockquotes
        .replace(/<pre>(.*?)<\/pre>/g, '{code}$1{code}') // Code block
        .replace(/<code>(.*?)<\/code>/g, '{{$1}}') // Inline code
        .replace(/&nbsp;/g, ' ') // Convert non-breaking spaces
        .replace(/<tbody>/g, '') // Remove <tbody> from tables
        .replace(/<\/tbody>/g, '') // Remove </tbody> from tables
        .replace(/<table>/g, '\n') // Ensure newline before table
        .replace(/<\/table>/g, '\n') // Ensure newline after table
        .replace(/<tr>/g, '\n| ') // Start table row
        .replace(/<\/tr>/g, ' |') // End table row
        .replace(/<th>/g, '|| ') // Table headers
        .replace(/<\/th>/g, ' ||\n') // Close headers with a new line
        .replace(/<td>/g, '| ') // Table data
        .replace(/<\/td>/g, ' ') // Close table data
        .replace(/<figure class="table">/g, '') // Remove wrapper
        .replace(/<\/figure>/g, '') // Remove wrapper

    const elements = html.split(/(<[^>]+>)/g).filter(e => e.trim() !== "");

    const converted = elements.map(el => {
        if (el.startsWith("<")) {
            switch (true) {
                case /<ol>/.test(el):
                    inOrderedList = true;
                    listCounter = 1;
                    return "";
                case /<\/ol>/.test(el):
                    inOrderedList = false;
                    return "";
                case /<li>/.test(el):
                    return inOrderedList ? `# ${listCounter++} ` : "* ";
                case /<\/li>/.test(el):
                    return "\n";
                case /<ul>/.test(el):
                case /<\/ul>/.test(el):
                    return "";
                default:
                    return el;
            }
        }
        return el; // Keep non-tag text unchanged
    });

    return converted.join("").trim();
  }

  createTicket(form) {
    if(!form.valid){
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
      return;
     }
    let data = form.value;
    data['siteId'] =  parseInt(data.WebSite);
    data = _.omit(data, ['WebSite']);
    data.Description = this.convertHtmlToJiraText(data.Description);
    data.attachment = { fileName: this.fileName };
    //form.value.attachment.fileName=this.fileName;
    this.ticketService.createTicket(data).subscribe((res) => {
      if (res) {

        this.toastrService.success('Ticket created', 'Success');
        this.ticketForm.reset();
        this.initializeForm(this.userService.UserProfile);
        this.fileName = '';
        this.photographs = [];
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);

      })
  }

  openUploadedFilesPopup(fieldName) {
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = 0;
    model.componentInstance.attachmentFor = 'JIRA';
    model.componentInstance.baseUrl = environment.apiUrl;
    model.componentInstance.selectedFile = this.photographs;
    model.componentInstance.field = fieldName;
    model.componentInstance.isAssets = true;
    model.componentInstance.isMultipleFiles = true;
    model.result.then((files) => {
      if (files) {
        _.forEach(files, (file) => {
          let n;
          if (this.photographs.length) {
            n = _.find(this.photographs, (photo) => {
              return photo == file;
            })
          }
          if (!n) {
            this.photographs.push(file);
          }
        })
        this.fileName = this.photographs.join(',');
      }
    }, (reason) => {
      
    });

  }

  openAssetsPopup() {
    let model = this.modalService.open(AssetsComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.title = 'Attachments';
    model.componentInstance.baseUrl = environment.apiUrl;
    this.photographs.forEach(function (file) {
      let type = file.split('.');
      type = type[type.length - 1];
      model.componentInstance.assets.push({ path: file, fileType: type, name: file.split('\\')[1] })
    });
    model.componentInstance.deleteAssets = (type?) => {
      model.componentInstance.assets = _.filter(model.componentInstance.assets, (file) => {
        return !file.hasOwnProperty('isFileSelected') || file.isFileSelected == false;
      });
      this.fileUploadService.uploadedFiles['files'] = model.componentInstance.assets;

      this.photographs = [];
      // this.homepagePhotographyForm.controls.photography.setValue('');
      this.fileName = '';
      _.forEach(model.componentInstance.assets, (asset) => {
        this.photographs.push(asset.path);
        this.fileName = this.photographs.join(',');
      })
      if (type != 'delete' || !this.photographs.length) {
        model.close();
      }
    }
  }

  applySearch(filterBy){
    if(filterBy && filterBy.length >= 3){
      this.getTickets(this.ticketType);
    }
  }

  /**
   * Resets the ticket table on the users dashboard
   */
  resetTickets(): void {
    this.filterBy = {};

    this.domain = this.subject = '';

    const ticketArgs = {
      ticketType: this.ticketType,
      title: this.queue,
    };

    this.getTickets(ticketArgs, '', this.queue);

    this.table.clearFilters();
  }
}

