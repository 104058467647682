// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-row {
  margin-top: 1rem;
}
.textarea-container {
  position: relative;
  display: flex;
}

.textarea-container textarea {
  width: 100%;
  height: 170px;
  padding-right: 50px; /* Space for button */
  resize: none;
  border-radius: 5px;
}

.copy-btn {
  position: absolute;
  top: 4px; /* Flush with top */
  right: 4px; /* Flush with right */
  background-color: #007bff;
  color: white;
  border: none;
  padding: 3px 10px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 3px;
  line-height: 1;
}

.copy-btn:hover {
  background-color: #0056b3;
}

.copy-popup {
  position: absolute;
  top: -30px; /* Above the button */
  right: 10px;
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInOut 2s ease-in-out;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/site/site-config/sycle-setup/sycle-setup.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB,EAAE,qBAAqB;EAC1C,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ,EAAE,mBAAmB;EAC7B,UAAU,EAAE,qBAAqB;EACjC,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,UAAU,EAAE,qBAAqB;EACjC,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,UAAU;EACV,2BAA2B;EAC3B,mCAAmC;AACrC;;AAEA;EACE;IACE,UAAU;IACV,2BAA2B;EAC7B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;EACA;IACE,UAAU;IACV,2BAA2B;EAC7B;AACF","sourcesContent":[".filter-row {\n  margin-top: 1rem;\n}\n.textarea-container {\n  position: relative;\n  display: flex;\n}\n\n.textarea-container textarea {\n  width: 100%;\n  height: 170px;\n  padding-right: 50px; /* Space for button */\n  resize: none;\n  border-radius: 5px;\n}\n\n.copy-btn {\n  position: absolute;\n  top: 4px; /* Flush with top */\n  right: 4px; /* Flush with right */\n  background-color: #007bff;\n  color: white;\n  border: none;\n  padding: 3px 10px;\n  font-size: 14px;\n  cursor: pointer;\n  border-radius: 3px;\n  line-height: 1;\n}\n\n.copy-btn:hover {\n  background-color: #0056b3;\n}\n\n.copy-popup {\n  position: absolute;\n  top: -30px; /* Above the button */\n  right: 10px;\n  background-color: #28a745;\n  color: white;\n  padding: 5px 10px;\n  border-radius: 5px;\n  font-size: 14px;\n  opacity: 0;\n  transform: translateY(10px);\n  animation: fadeInOut 2s ease-in-out;\n}\n\n@keyframes fadeInOut {\n  0% {\n    opacity: 0;\n    transform: translateY(10px);\n  }\n  10% {\n    opacity: 1;\n    transform: translateY(0);\n  }\n  90% {\n    opacity: 1;\n    transform: translateY(0);\n  }\n  100% {\n    opacity: 0;\n    transform: translateY(10px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
