import { Component, OnInit, ViewChild } from '@angular/core';
import { IntakesService } from '../../../../components/customer/intakes/services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '@services/http.service';
import moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { CorporatePartnerService } from '../../../admin/manage-corporate-partner/services/corporate-partner.service';
import { DatePipe } from '@angular/common';
import { roles } from '@shared/master-enum.enum';
import { TicketService} from '../../../ticket/service/ticket.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditDetailComponent } from '../audit-detail/audit-detail.component';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';

@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.css'],
  providers: [DatePipe]
})
export class RequestListComponent implements OnInit, PageHasTable {
  columnHeaders: ColumnHeader[];
  columnDataTypes: ColumnDataTypes;
  filterBy = {
    startDate: '',
    endDate: '',
    _startDate: '',
    _endDate: '',
    dateRange: null
  };
  loading: boolean = false;
  nestedTableColumnHeaders;
  nestedTableDataType: ColumnDataTypes = {};
  submittedBy: number = null;
  existingData = [];
  userInfo = JSON.parse(localStorage.getItem("userInfo"));
  corporatePartner;
  roles = roles;
  currentRole;
  jiraUrl;
  subTableColumnHeaders: ColumnHeader[];
  subtableData: Array<object>;
  disableNestedTable: boolean;

  @ViewChild('dt') table: TableGenericComponent;

  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private corporatePartnerService: CorporatePartnerService,
    private datePipe: DatePipe,
    private ticketService:TicketService,
    public modalService: NgbModal,
  ) {
    if (this.userInfo) {
      this.currentRole = _.find(roles, (role) => {
        return role.roleId == this.userInfo.roleId;
      })
    }
  }

  public resetPagination() {
    // this.mf.setPage(1, this.mf.rowsOnPage);
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParamMap.get('startDate')) {
      this.filterBy['startDate'] = this.filterBy['_startDate'] = this.activatedRoute.snapshot.queryParamMap.get('startDate');
      this.filterBy['endDate'] = this.filterBy['_endDate'] = this.activatedRoute.snapshot.queryParamMap.get('endDate');
    }
    this.getAuditRequestData(this.filterBy);
    if(this.userInfo.roleId<11){
      this.getJiraUrl();
      this.getCorporatePartner();
    }

    this.getTableData();
  }
  getAuditRequestData(filterBy) {
    filterBy['CPId'] = parseInt(filterBy['CPId']);
    let invalidKey = _.findKey(filterBy, (param) => {
      return typeof param == 'string' && param.length < 3 && param.length > 0;
    })
    if (!invalidKey) {
      filterBy['formattedStartDate'] = this.datePipe.transform(filterBy.startDate, 'yyyy-MM-dd');
      filterBy['formattedEndDate'] = this.datePipe.transform(filterBy.endDate, 'yyyy-MM-dd');
      this.intakesService.getAuditRequestData(filterBy).subscribe((res) => {
        if (res) {
          this.existingData = res;

          this.resetPagination();
        }
      }, err => {
        this.httpService.openErrorPopup(err.error.message);
      })
    } else {
      this.toastrService.warning("Enter atleast 3 characters", 'Warning');
    }

  }
  getCorporatePartner() {
    this.corporatePartnerService.getCorporatePartners().subscribe(res => {
      if (res) {
        this.corporatePartner = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  /**
   * Gets data for the tables and sets the column headers
   *
   * @TODO Move API call to this method
   */
  getTableData(): void {
    this.columnHeaders = [
      {field: 'parentIssueKey', header: 'Ticket ID'},
      {field: 'websiteUrl', header: 'Domain Url'},
      {field: 'customerName', header: 'Customer Name'},
      {field: 'cpName', header: 'Program', filter: 'select' },
      {field: 'resoundAccount', header: 'Account #'},
      {field: 'customerEmail', header: 'Client Email'},
      {field: 'userName', header: 'Ticket Username'},
    ];

    this.subTableColumnHeaders = [
      {field: 'ticketId', header: 'Ticket ID'},
      {field: 'subject', header: 'Subject'},
      {field: 'status', header: 'Status'},
      {field: 'priority', header: 'Priority'},
      {field: 'assignee', header: 'Assignee'},
      {field: 'created', header: 'Created'},
    ];

    this.columnDataTypes = {
      parentIssueKey: 'jiraKey',
    };

    this.nestedTableColumnHeaders = {
      created: 'mediumDate',
      ticketId: 'jiraKey',
    };

    // All communication back to the CP is done on the parent ticket. 
    // Internal communication happens on the subtasks and we don’t want the CP to see this. 
    this.disableNestedTable = this.userInfo.roleId === '12' || this.userInfo.roleId === '14';

    if (this.disableNestedTable) {
      this.columnDataTypes.parentIssueKey = 'portalAuditTicketLink';
      this.nestedTableColumnHeaders.ticketId = 'portalAuditTicketLink';
    }
  }

  insertNestedData(nestedRowData) {
    const relatedIDs = nestedRowData.rowData.issueId.split(',');
    const parentIssueKey = nestedRowData.rowData.parentIssueKey;
    const relatedTickets = [];

    relatedIDs.map((relatedId: string) => {
      this.intakesService.getRequestAdditionalData(relatedId)
        .subscribe((res) => {
          relatedTickets.push(res[0]);
        },
        (err) => {
          this.httpService.openErrorPopup(err.error.message);
        }, () => {
          for (let i = 0; i < this.existingData.length; i++) {
            if (this.existingData[i].parentIssueKey === parentIssueKey) {
              this.existingData[i].related = relatedTickets;
            }
          }
        });
    });
  }

  /**
   * Removes the filters from the table
   */
  resetFilter(): void {
    this.filterBy = {
      startDate: '',
      endDate: '',
      _startDate: '',
      _endDate: '',
      dateRange: null
    };

    this.filterBy['startDate'] = this.activatedRoute.snapshot.queryParamMap.get('startDate');
    this.filterBy['endDate'] = this.activatedRoute.snapshot.queryParamMap.get('endDate');

    this.table.clearFilters();

    this.getAuditRequestData(this.filterBy);
  }

  onValueChange(event) {
    if (event == null)
      return;
    this.filterBy['startDate'] = moment(event[0]).format('MM/DD/YYYY');
    this.filterBy['endDate'] = moment(event[1]).format('MM/DD/YYYY');
    this.filterBy['_startDate'] = moment(event[0]).format('MM/DD/YY');
    this.filterBy['_endDate'] = moment(event[1]).format('MM/DD/YY');
    this.getAuditRequestData(this.filterBy);
  }

  getAdditionalData(item) {
    if(!item.issueId)
      return;
    if (item.isActive) {
      this.intakesService.getRequestAdditionalData(item.issueId).subscribe((res) => {
        if (res) {
          item['addonInfo'] = res;
        }
      }, err => {
        this.httpService.openErrorPopup(err.error.message);
      })
    }
  }
  getJiraUrl() {
    this.ticketService.getJiraUrl().subscribe(res => {
      if (res) {
        this.jiraUrl = res['url'];
      }
    },
      err => {
        throw err;

      })
  }
  getAuditDetail(id){
    var model = this.modalService.open(AuditDetailComponent, { size: 'lg',ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.id = id;

  }
}
