import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IntakesService } from '../services/intakes.service';
import { StripePaymentComponent } from '../../payment/stripe-payment/stripe-payment.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '@services/http.service';
import { ExcelService } from '../../../reporting/services/excel.service';
import * as _ from 'lodash';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';
import { UserService } from '../../../admin/users/services/user-service.service';
import { Intake } from '@shared/interfaces/intakes';
import { SiteInfo } from '@shared/interfaces/site';

@Component({
  selector: 'app-customer-intakes-list',
  templateUrl: './customer-intakes-list.component.html',
  styleUrls: ['./customer-intakes-list.component.css'],
})
export class CustomerIntakesListComponent implements OnInit, PageHasTable {
  @Input('dt') table: TableGenericComponent;

  columnDataTypes: ColumnDataTypes;
  columnHeaders: ColumnHeader[] = [];
  userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
  selectedSiteId = JSON.parse(localStorage.getItem('selectedSite')).siteId;
  allIntakes;
  openTAndC;
  customerCardInfo;
  EXHIBITA: boolean = false;
  EXHIBITC: boolean = false;
  intakesList: Intake[] = [];
  filterBy = {};
  page = 1;
  selectedIntake;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private intakesService: IntakesService,
    public modalService: NgbModal,
    private httpService: HttpService,
    private excelService:ExcelService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.openTAndC = this.route.snapshot.queryParamMap.get('tnc') ? true : false;
      // Get the item from localStorage
      var siteString = localStorage.getItem('selectedSite');
      // Parse the JSON string back into an object
      var selectedSite = JSON.parse(siteString);
      this.selectedSiteId = selectedSite.siteId;

      this.getIntakes(this.selectedSiteId);

      this.getCard(this.selectedSiteId);

      this.getTableData();
  }

  getIntakes(siteId) {
    this.intakesService.getIntakes(siteId).subscribe(
      (res) => {
        if (res) {
          this.allIntakes = res;

          this.intakesList = Object.assign([], this.allIntakes);

          if (this.route.snapshot.queryParamMap.get('intakeId')) {
            this.selectedIntake = _.find(this.intakesList, (intake) => {
              return intake.intakeId == this.route.snapshot.queryParamMap.get('intakeId');
            });
          }
        }
      },
      (err) => {
        console.error(err);
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      initiateDate: 'date',
    };

    this.columnHeaders = [
      { field: 'domainUrl', header: 'Domain' },
      { field: 'siteName', header: 'Site' },
      { field: 'products', header: 'Products' },
      { field: 'initiateDate', header: 'Start Date' },
      { field: 'paymentStatusName', header: 'Payment Status' },
      { field: 'paymentModeName', header: 'Payment Mode' },
      { field: 'statusName', header: 'Intake Status' },
    ];
  }

  goToIntakeProduct(intakeId) {
    this.router.navigate(['/client/intake-product-list/' + intakeId]);
  }

  goToAddIntake(intake) {
    if (!intake.isAcceptTnC) {
      this.openTAndC = true;
      this.router.navigate([], {
        queryParams: {
          tnc: true,
          intakeId: intake.intakeId
        },
        queryParamsHandling: 'merge',
      });
      this.selectedIntake = intake;
      window.scrollTo(0, 0)
    } else {
      if(
          (
            !this.customerCardInfo.isCardExists ||
            (this.customerCardInfo.isCardExists && intake.paymentStatus==0)
          )
          // exclude invoice intakes
          && intake.paymentMode != 1
        )
        this.openCardPopup(intake);
      else
        this.router.navigate([`/client/intakes/${intake.intakeId}/${intake.siteId}`]);

    }
  }

  openEXHIBIT(exhibitType) {
    if (exhibitType == 'A') {
      this.EXHIBITA = true;
    } else {
      this.EXHIBITC = true;
    }
    window.scrollTo(0, 0)
  }

  getCard(siteId) {
    this.intakesService.GetCustomerPayInfo(siteId).subscribe((res) => {
      this.customerCardInfo = res;
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  openCardPopup(intake) {
    this.openTAndC = false;
    let paymentInfo = {};
    let componentName;
    paymentInfo = {
      siteId: intake.siteId,
      cardNumber: this.customerCardInfo.last4,
      expirationYear: this.customerCardInfo.expYear,
      expirationMonth: this.customerCardInfo.expMonth,
      intakeId: intake.intakeId,
      userId: this.userInfo.userId,
      amount: intake.totalAmt || 0
    };
    componentName = StripePaymentComponent;
    this.openAddPopup(componentName, paymentInfo, intake);
  }

  openAddPopup(componentName, paymentInfo, intake?) {
    var model = this.modalService.open(componentName, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.paymentInfo = paymentInfo;
    model.componentInstance.intake=intake;
    model.result.then((result) => {
      
      this.customerCardInfo.isCardExists = true;
      this.router.navigate([`/client/intakes/${intake.intakeId}/${intake.siteId}`]);
      this.openTAndC = false;
    }, (reason) => {
      
    });

  }

  exportToCsv() {
    let exelData = [
      {
        domainUrl: 'Domain',
        siteName: 'Site',
        products: 'Products',
        initiateDate: 'Start Date',
        paymentStatusName: 'Payment Status',
        paymentModeName: 'Payment Mode',
        statusName: 'Intake Status'
      }
    ];
    _.forEach(this.intakesList, (user) => {
      let data:any = _.pick(user, ['domainUrl', 'siteName', 'products', 'initiateDate', 'paymentStatusName', 'paymentModeName', 'statusName']);
      exelData.push(data);
    })
    this.excelService.exportAsExcelFile(exelData, 'Intakes');
  }
}
