import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { IntakesService } from '../../../../services/intakes.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Associations } from './associations-model';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import * as _ from 'lodash';
import { SectionStatus } from '../../review/section-model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { environment } from 'environments/environment';
import { DataService } from '@shared/data-service.service';
import { AssetsComponent } from '../../content/assets/assets.component';
import { HttpService } from '../../../../../../../services/http.service';
@Component({
  selector: 'app-associations',
  templateUrl: './associations.component.html',
  styleUrls: ['./associations.component.css']
})
export class AssociationsComponent implements OnInit {
  tradeAssociationData;
  associationForm: UntypedFormGroup;
  @Output() backClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();
  @Output() changeStatus = new EventEmitter<any>();
  data;
  associationOther;
  intakeId;
  @Input() existingData;
  @Input() sectionId;
  @Input() intakeTypeId;
  selectedSiteId;
  photographs: any = [];
  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    private elementRef: ElementRef,
    private httpService: HttpService,
    private toastrService: ToastrService,
    public dataService: DataService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        this.selectedSiteId = params.siteId;
      }
    });
  }

  ngOnInit() {
    this.data = this.intakesService.basicInfo['associations'] ? this.intakesService.basicInfo['associations'] : this.existingData;;
    this.initializeForm();
    this.getAssociationsData();
    if (this.data.affiliateLogo) {
      this.photographs = this.data.affiliateLogo.split(',');
    }
  }

  initializeForm() {
    this.associationForm = this.formBuilder.group({
      intakeId: this.intakeId ? this.intakeId : null,
      affiliateLogo: new UntypedFormControl(this.data.affiliateLogo),
      ltradeAssociations: this.formBuilder.array(this.tradeAssociationData ? this.tradeAssociationData.map(c => this.formBuilder.group({
        checked: new UntypedFormControl(false),
        otherTradeAssociation: new UntypedFormControl()
      })) : [])
    })
  }

  getAssociationsData() {
    this.intakesService.getAssociationsData().subscribe((res) => {
      if (res) {
        this.tradeAssociationData = res['masterList'];
        this.associationForm.setControl('ltradeAssociations', this.formBuilder.array(this.tradeAssociationData.map(c => this.formBuilder.group({
          checked: new UntypedFormControl(this.getCheckedTradeAssociation(c.id)),
          name: new UntypedFormControl(c.name),
          id: new UntypedFormControl(c.id),
          otherTradeAssociation: new UntypedFormControl(this.data ? this.data.otherAssociation : '', Validators.pattern(this.dataService.checkWhiteSpace))
        }))));
        this.associationOther = _.find(this.tradeAssociationData, (association) => {
          return association.name.toLowerCase() == 'other';
        });
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getCheckedTradeAssociation(id) {
    if (this.data) {
      let filteredElement = _.filter(this.data.ltradeAssociations, (dataItem) => {
        return dataItem.id == id;
      })
      if (filteredElement.length) {
        return true;
      }
    }

  }


  saveFormData(form, mode?) {
    let intakeData = new Associations(form.value, this.intakeId);

    _.forEach(intakeData.ltradeAssociations, (data) => {
      intakeData.otherAssociation = (data as any).otherTradeAssociation
      if ((data as any).id == 53) {
        intakeData.otherAssociation = (data as any).otherTradeAssociation;
      } else {
        intakeData.otherAssociation = '';
      }
    })


    this.intakesService.basicInfo['associations'] = intakeData;
    _.assign(this.intakesService.commonBasicInfoObj, intakeData);
    
    this.intakesService.saveIntake(this.intakesService.commonBasicInfoObj).subscribe(res => {
      if (res) {
        this.changeStatus.next();
        if (mode == 'continue' || mode == 'submit') {
          this.toastrService.success(this.dataService.successMsg, 'Success');
        }
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, intakeData.intakeId);
        this.postIntakeSection(sectionStatus);
        if (mode == 'continue') {
          this.goToNext('parent');
        }
      } else {

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  goBack() {
    this.backClicked.next();
  }

  goToNext(menu) {
    this.continueClicked.next(menu);
  }

  openUploadedFilesPopup() {
    let model = this.modalService.open(FileUploadComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', windowClass: 'custom-class' });
    model.componentInstance.siteId = this.selectedSiteId;
    model.componentInstance.isAssets = true;
    model.componentInstance.isMultipleFiles = true
    model.result.then((files) => {
      if (files) {
        _.forEach(files, (file) => {
          let n;
          if(this.photographs.length){
            n = _.find(this.photographs, (photo) => {
              return photo == file;
            })
          }
          if(!n){
            this.photographs.push(file);
          }
        })
        this.associationForm.controls.affiliateLogo.setValue(this.photographs.join(','));
        this.saveFormData(this.associationForm);
      }
    }, (reason) => {
      
    });
  }

  openAssetsPopup() {
    let model = this.modalService.open(AssetsComponent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.componentInstance.title = 'Affiliate Logo';
    this.photographs.forEach(function (file) {
      let type = file.split('.');
      type = type[type.length - 1];
      model.componentInstance.assets.push({ path: file, fileType: type, name: file.split('/')[file.split('/').length - 1] })
    });
    model.componentInstance.deleteAssets = (type) => {
      model.componentInstance.assets = _.filter(model.componentInstance.assets, (file) => {
        return !file.hasOwnProperty('isFileSelected') || file.isFileSelected == false;
      });

      this.photographs = [];
      this.associationForm.controls.affiliateLogo.setValue('');
      _.forEach(model.componentInstance.assets, (asset) => {
        this.photographs.push(asset.path);
        this.associationForm.controls.affiliateLogo.setValue(this.photographs.join(','));
      })
      if(type != 'delete'){
        model.close();
      }
    }
  }


  deleteSelectedFile(field) {
    this.associationForm.controls[field].setValue('');
  }
}
