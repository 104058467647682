import { Routes, RouterModule, Route } from '@angular/router';
import { Pages } from './pages.component';
import { LoginComponent } from './login/login.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ListComponent, AdminDashboardComponent, MasterListComponent, AddComponent, DetailComponent, MtListComponent, PageCollectionListComponent, PageCollectionAddComponent, ConfigListComponent, BuildSiteComponent, ManageCorporatePartnerComponent } from './admin/index';
import { AmDashboardComponent, SitesComponent, IntakesComponent, IntakeProductListComponent } from './account-manager/index';
import { CustomerDashboardComponent, AddIntakesDataComponent, CustomerIntakesListComponent, CustomerIntakeProductListComponent, ContentEditorComponent, MasterReviewComponent, PaymentHistoryComponent } from './customer/index';
import { TicketlistComponent } from './ticket/ticketlist/ticketlist.component';
import { TicketDetailComponent } from './ticket/ticket-detail/ticket-detail.component';

import { PageHeaderComponent } from '../core/page-header/page-header.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuardsService as AuthGuard } from '@shared/security/auth-guards.service';
import { RoleGuardsService as RoleGuard, getCurrentRole } from '@shared/security/role-guards.service';
import { IntakeListComponent } from './site/intake-list/intake-list.component';
import { CancelSiteComponent } from './site/cancel-site/cancel-site.component';
import { ManageCardComponent } from './customer/manage-card/manage-card.component';
import { AuditRequestComponent } from '../components/corporate-partner/audit-request/audit-request/audit-request.component'

import { roles } from '@shared/master-enum.enum';
import { RequestListComponent } from './corporate-partner/audit-request/request-list/request-list.component'
import { CpDashboardComponent, CustomerListComponent, CustomerDetailsComponent } from './corporate-partner';
import { from } from 'rxjs';
import { NotificationComponent } from './admin/notification/notification.component';
import { AddEditNotificationComponent } from './admin/notification/add-edit-notification/add-edit-notification.component';
import { SiteListComponent } from './site/site-list/site-list.component';
import { SiteConfigComponent } from './site/site-config/site-config.component';
import { DowngradeProductComponent } from './site/site-config/downgrade-product/downgrade-product.component';
import { SiteSettingComponent } from './site/site-setting/site-setting.component';
import { ReportComponent } from './reporting/report.component';
import { AmrpcReportComponent } from './reporting/amrpc-report/amrpc-report.component';
import { BusinessPerformanceComponent } from './reporting/business-performance/business-performance.component';
import { SalesPerformanceComponent } from './reporting/sales-performance/sales-performance.component';
import { ListContainerComponent } from './ticket/list-container/list-container.component';
import { ObDashboardComponent } from './onboarding/ob-dashboard/ob-dashboard.component';
import { PmDashboardComponent } from './product-manager/pm-dashboard/pm-dashboard.component';
import { CompletedProjectsComponent } from './product-manager/completed-projects/completed-projects.component';
import { CcDashboardComponent } from './client-communication-manager/cc-dashboard/cc-dashboard.component';
import { SeoDashboardComponent } from './seo/seo-dashboard/seo-dashboard.component';
import { DesDashboardComponent } from './designer/des-dashboard/des-dashboard.component';
import { DevDashboardComponent } from './developer/dev-dashboard/dev-dashboard.component';
import { BdDashboardComponent } from './business-developement/bd-dashboard/bd-dashboard.component';
import { SmDashboardComponent } from './sales-and-marketing/sm-dashboard/sm-dashboard.component';
import { DirDashboardComponent } from './director/dir-dashboard/dir-dashboard.component';
import { ProjectsComponent } from './product-manager/projects/projects.component';
import { ProjectComponent } from './reporting/project-report/project.component';
import { SiteProductComponent } from './site/site-product/site-product.component';
import { SearchMktDashboardComponent } from './search-marketing/search-mkt-dashboard/search-mkt-dashboard.component';
import { StatementOfWorkComponent } from './customer/statement-of-work/statement-of-work.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { ExhibitAComponent } from './customer/statement-of-work/exhibit-a/exhibit-a.component';
import { ExhibitBComponent } from './customer/statement-of-work/exhibit-b/exhibit-b.component';
import{ExportDataComponent} from './downloads/export-data/export-data.component';
import{ContentGenerationComponent} from './content-generation/content-generation.component';
import{BluePrintProcessComponent} from './admin/blue-print-process/blue-print-process.component';
import { ManageLocationsComponent } from './billing/manage-locations/manage-locations.component';
import { SiteConfigRoutes } from '../routes/site-config';
import { PagesModule } from './pages.module';

// noinspection TypeScriptValidateTypes
// export function loadChildren(path) { return System.import(path); };

// import { AuthGuard, PureGuard } from '../guards';
const child: Route[] = [
  ...SiteConfigRoutes,
  {
    path: 'billing/locations',
    redirectTo: 'billing',
    pathMatch: 'full',
    data: {
      name: 'Locations',
    },
  },
  {
    path: 'billing',
    component: SiteSettingComponent,
    data: {
      name: 'Billing',
      menu: {
        title: 'Billing',
      },
    },
  },
  {
    path: 'billing/:siteId',
    component: SiteSettingComponent,
    data: {
      name: 'Billing',
      menu: {
        title: 'Billing',
      },
    },
    children: [{
      path: 'locations',
      component: ManageLocationsComponent,
      data: {
        productId: 9,
      },
    }],
  },
  {
    path: 'downloads', component: DownloadsComponent, data: {
      name: 'Third Party Accounts',
      menu: {
        title: 'Third Party Accounts'
      }
    }
  },
  {
    path: 'statement-of-work',
    component: StatementOfWorkComponent,
    data: {
      name: 'Statement of Work'
    }
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
    data: {
      name: 'Terms and Conditions'
    }
  },
  {
    path: 'exhibit/:type',
    component: ExhibitBComponent,
    data: {
      name: 'Terms and Conditions'
    }
  },
  {
    path: 'project', component: IntakesComponent, data: {
      name: 'New Business',
      menu: {
        title: 'Intake'
      }
    }
  },
  {
    path: 'projects', component: ProjectsComponent, data: {
      name: 'Projects'
    }
  }, {
    path: 'completed-projects', component: CompletedProjectsComponent, data: {
      name: 'Completed Projects'
    }
  },
  {
    path: 'notification', component: NotificationComponent, data: {
      name: 'Notification',
      menu: {
        title: 'Notification'
      }
    }
  }, {
    path: 'notification/:id', component: AddEditNotificationComponent, data: {
      name: 'Notification',
      menu: {
        title: 'Notification'
      }
    }
  },
  {
    path: 'intake-product-list/:id', component: IntakeProductListComponent, data: {
      name: 'Intake Product List',
      menu: {
        title: 'Intake Product List'
      }
    }
  },
  {
    path: 'users', component: ListComponent, data: {
      name: 'Users',
      menu: {
        title: 'Users'
      }
    }
  },
  {
    path: 'add-user', component: AddComponent, data: {
      name: 'Add User'
    }
  },
  {
    path: 'add-user/:site', component: AddComponent, data: {
      name: 'Add User'
    }
  },
  {
    path: 'user/:id', component: AddComponent, data: {
      name: 'Users'
    }
  },
  {
    path: 'userprofile', component: DetailComponent, data: {
      name: 'userprofile'
    }
  },
  {
    path: 'masters-type/:id', component: MtListComponent, data: {
      name: 'Master Type'
    }
  },
  {
    path: 'page-collection', component: PageCollectionListComponent, data: {
      name: 'Page Collection',
      menu: {
        title: 'Page Collection'
      }
    }
  },
  {
    path: 'add-page-collection/:id', component: PageCollectionAddComponent, data: {
      name: 'Edit Page Collection'
    }
  },
  {
    path: 'add-page-collection', component: PageCollectionAddComponent, data: {
      name: 'Add Page Collection'
    }
  },
  {
    path: 'configuration', component: ConfigListComponent, data: {
      name: 'Configuration',
      menu: {
        title: 'Configuration'
      }
    }
  },
  {
    path: 'intake-details', component: IntakeListComponent, data: {
      name: 'Intakes',
      menu: {
        title: 'Sites'
      }
    }
  },
  {
    path: 'build-site/:id', component: BuildSiteComponent, data: {
      name: '',
      menu: {
        title: 'Build Site'
      }
    }
  },
  {
    path: 'ticket', component: ListContainerComponent, data: {
      name: 'Tickets',
      menu: {
        title: 'Ticket'
      }
    }
  },
  {
    path: 'ticket/:id', component: TicketDetailComponent, data: {
      name: '',
      menu: {
        title: 'Ticket'
      }
    },
  },
  {
    path: 'sites', component: SiteListComponent, data: {
      name: 'Manage Clients'
    }
  },
  {
    path: 'cancel-subscription/:siteId', component: SiteProductComponent, data: {
      name: 'site Product'
    }
  },
  {
    path: 'downgrade-product/:siteId', component: DowngradeProductComponent, data: {
      name: 'Downgrade-Product'
    }
  },
  {
    path: 'CP', component: ManageCorporatePartnerComponent, data: {
      name: 'Manage Programs'
    }
  },
  {
    path: 'other-reports', component: ReportComponent, data: {
      name: 'Other Reports',
      menu: {
        title: 'Reporting'
      }
    }
  },
  {
    path: 'client-reports', component: ReportComponent, data: {
      name: 'Client Reports',
      menu: {
        title: 'Reporting'
      }
    }
  },
  {
    path: 'payment-history', component: PaymentHistoryComponent, data: {
      name: 'Payment History',
      menu: {
        title: 'Manage Cards'
      }
    }
  },
  {
    path: 'user-profile', component: UserProfileComponent, data: {
      name: '',
      menu: {
        title: 'User Profile'
      }
    }
  },
  {
    path: 'projects', component: ProjectsComponent, data: {
      name: 'Projects'
    }
  },
  {
    path: 'cancel-site', component: CancelSiteComponent, data: {
      name: 'Cancellations',
    }
  },
  {
    path: 'amrpc-report', component: AmrpcReportComponent, data: {
      name: 'AMRPC',
      menu: {
        title: 'amrpc'
      }
    }
  },
  {
    path: 'business-performance', component: BusinessPerformanceComponent, data: {
      name: 'business-performance',
      menu: {
        title: 'Reporting'
      }
    }
  },
  {
    path: 'audit-request', component: RequestListComponent, data: {
      name: 'Audits/Proposals',
      menu: {
        title: 'Audit Request'
      }
    },
  },
  {
    path: 'audit-request/:id', component: AuditRequestComponent, data: {
      name: 'Audits/Proposals',
      menu: {
        title: 'Audit Request'
      }
    },
  },
  {
    path: 'Utility', component: BluePrintProcessComponent, data: {
      name: 'Utility',
      menu: {
        title: 'Utility'
      }
    },
  },
  {
    path: 'export', component: ExportDataComponent, data: {
      name: 'Export',
      menu: {
        title: 'Export'
      }
    },
  },
  {
    path: 'content-generation', component: ContentGenerationComponent, data: {
      name: 'Content Generation',
      menu: {
        title: 'Content Generation'
      }
    },
  }
];
const adminMenu: Route[] = [...child,{
  path: 'dashboard', component: AdminDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}, {
  path: '', component: AdminDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}];
const businessDevelopmentMenu: Route[] = [...child, {
  path: 'dashboard', component: BdDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}, {
  path: '', component: BdDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}];
const productManagerMenu: Route[] = [...child, {
  path: 'dashboard', component: PmDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}, {
  path: '', component: PmDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}];
const onboardingMenu: Route[] = [...child, {
  path: 'dashboard', component: PmDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}, {
  path: '', component: PmDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}];
const accountManagerMenu: Route[] = [...child, {
  path: 'dashboard', component: AmDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}, {
  path: '', component: AmDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}];
const clientCommunicationManagerMenu: Route[] = [...child, {
  path: 'dashboard', component: CcDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}, {
  path: '', component: CcDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}];
const designerDeveloperMenu: Route[] = [...child, {
  path: 'dashboard', component: DevDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}, {
  path: '', component: DevDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}];
const seoEditorMenu: Route[] = [...child, {
  path: 'dashboard', component: SeoDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}, {
  path: '', component: SeoDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}];
const searchMarketingMenu: Route[] = [...child, {
  path: 'dashboard', component: SearchMktDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}, {
  path: '', component: SearchMktDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}];
const salesAndMarketingMenu: Route[] = [...child, {
  path: 'dashboard', component: SmDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}, {
  path: '', component: SmDashboardComponent, data: {
    name: 'Dashboard',
    menu: {
      title: 'Dashboard'
    }
  }
}];

export const routes: Route[] = [
  {
    path: '',
    component: Pages,
    data: {
      name: 'login'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      name: 'login'
    }
  },
  {
    path: 'set-password',
    component: SetPasswordComponent,
    data: {
      name: 'setPassword'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      name: 'forgotPassword'
    }
  },
  {
    path: '',
    loadChildren: null,
    data: {
      name: 'page'
    },
    children: [
      {
        path: '',
        redirectTo: RoleGuard['currentRole'] || 'admin',
        pathMatch: 'full',
        data: {
          name: ''
        }
      }, {
        path: 'admin',
        component: Pages,
        canActivate: [RoleGuard],
        data: {
          expectedRole: roles.admin.roleId,
          name: 'admin'
        },
        children: [...adminMenu]
      },
      {
        path: 'business-development',
        component: Pages,
        canActivate: [RoleGuard],
        data: {
          name: 'seo',
          expectedRole: roles.businessDevelopment.roleId
        },
        children: [...businessDevelopmentMenu]
      },
      {
        path: 'product-manager',
        component: Pages,
        canActivate: [RoleGuard],
        data: {
          name: 'product-manager',
          expectedRole: roles.productManager.roleId
        },
        children: [...productManagerMenu]
      },
      {
        path: 'onboarding',
        component: Pages,
        canActivate: [RoleGuard],
        data: {
          name: 'onboarding',
          expectedRole: roles.onboarding.roleId
        },
        children: [...onboardingMenu]
      },
      {
        path: 'account-manager',
        component: Pages,
        canActivate: [RoleGuard],
        data: {
          name: 'accountManager',
          expectedRole: roles.accountManagement.roleId
        },
        children: [...accountManagerMenu]
      },
      {
        path: 'client-communication-manager',
        component: Pages,
        canActivate: [RoleGuard],
        data: {
          name: 'client-communication-manager',
          expectedRole: roles.clientCommunicationManager.roleId
        },
        children: [...clientCommunicationManagerMenu]
      },
      {
        path: 'designer-developer',
        component: Pages,
        canActivate: [RoleGuard],
        data: {
          name: 'developer',
          expectedRole: roles.developer.roleId
        },
        children: [...designerDeveloperMenu]
      },
      {
        path: 'seo-editor',
        component: Pages,
        canActivate: [RoleGuard],
        data: {
          name: 'seo',
          expectedRole: roles.seo.roleId
        },
        children: [...seoEditorMenu]
      },
      {
        path: 'search-marketing',
        component: Pages,
        canActivate: [RoleGuard],
        data: {
          name: 'seo',
          expectedRole: roles.searchMarketing.roleId
        },
        children: [...searchMarketingMenu]
      },
      {
        path: 'salesAndMarketing',
        component: Pages,
        canActivate: [RoleGuard],
        data: {
          name: 'seo',
          expectedRole: roles.salesAndMarketing.roleId
        },
        children: [...salesAndMarketingMenu]
      },
      {
        path: 'client',
        component: Pages,
        canActivate: [RoleGuard],
        data: {
          expectedRole: [roles.client.roleId, roles.siteUser.roleId],
          name: 'client'
        },
        children: [
          {
            path: 'dashboard', component: CustomerDashboardComponent, data: {
              name: 'Dashboard',
              menu: {
                title: 'Dashboard'
              }
            }
          },
          {
            path: 'billing',
            component: SiteSettingComponent,
            data: {
              name: 'Billing',
              menu: {
                title: 'Billing',
              },
            },
            children: [{
              path: 'locations',
              component: ManageLocationsComponent,
              data: {
                productId: 9,
              },
            }],
          },
          {
            path: '', component: CustomerDashboardComponent, data: {
              name: 'Dashboard',
              menu: {
                title: 'Dashboard'
              }
            }
          }, {
            path: 'amrpc-report', component: AmrpcReportComponent, data: {
              name: 'AMRPC',
              menu: {
                title: 'amrpc'
              }
            }
          },
          {
            path: 'client-reports', component: ReportComponent, data: {
              name: 'Reports',
              menu: {
                title: 'Reporting'
              }
            }
          },
          {
            path: 'business-performance', component: BusinessPerformanceComponent, data: {
              name: 'business-performance',
              menu: {
                title: 'business'
              }
            }
          }, {
            path: 'sales-performance', component: SalesPerformanceComponent, data: {
              name: 'sales-performance',
              menu: {
                title: 'sales'
              }
            }
          },
          {
            path: 'users', component: ListComponent, data: {
              name: 'users',
              menu: {
                title: 'All Users'
              }
            }
          },
          {
            path: 'intakes', component: CustomerIntakesListComponent, data: {
              name: 'Intakes',
              menu: {
                title: 'Intakes'
              }
            }
          },
          {
            path: 'manage-card', component: ManageCardComponent, data: {
              name: 'Manage Card',
              menu: {
                title: 'Manage Cards'
              }
            }
          },
          {
            path: 'payment-history', component: PaymentHistoryComponent, data: {
              name: 'Payment History',
              menu: {
                title: 'Manage Cards'
              }
            }
          },
          {
            path: 'editor', component: ContentEditorComponent, data: {
              name: 'editor',
              menu: {
                title: 'editor'
              }
            }
          },
          {
            path: 'intakes/:id/:siteId', component: AddIntakesDataComponent, data: {
              name: 'addIntake',
              menu: {
                title: 'Intakes'
              }
            }
          },
          {
            path: 'intake-product-list/:id', component: IntakeProductListComponent, data: {
              name: 'Intake Product List',
              menu: {
                title: 'Intake Product List'
              }
            }
          },
          {
            path: 'ticket', component: ListContainerComponent, data: {
              name: 'Service Tickets',
              menu: {
                title: 'Ticket'
              }
            }
          },
          {
            path: 'ticket/:id', component: TicketDetailComponent, data: {
              name: '',
              menu: {
                title: 'Ticket'
              }
            }
          },
          {
            path: 'user-profile', component: UserProfileComponent, data: {
              name: '',
              menu: {
                title: 'User Profile'
              }
            }
          },
          {
            path: 'users/:id', component: ListComponent, data: {
              name: 'Users',
              menu: {
                title: 'Users'
              }
            }
          },
          {
            path: 'add-user', component: AddComponent, data: {
              name: 'Add User'
            }
          },
          {
            path: 'user/:id', component: AddComponent, data: {
              name: 'Users'
            }
          },
          {
            path: 'site-config/:siteId', component: SiteConfigComponent, data: {
              name: 'Site Config'
            }
          },
          {
            path: 'downgrade-product/:siteId', component: DowngradeProductComponent, data: {
              name: 'Downgrade-Product'
            }
          },
          {
            path: 'manage-user', component: SiteConfigComponent, data: {
              name: 'Manage User'
            }
          },
          {
            path: 'statement-of-work',
            component: StatementOfWorkComponent,
            data: {
              name: 'Statement of Work'
            }
          },
          {
            path: 'terms-and-conditions',
            component: TermsAndConditionsComponent,
            data: {
              name: 'Terms and Conditions'
            }
          },
          {
            path: 'exhibit/:type',
            component: ExhibitBComponent,
            data: {
              name: 'Terms and Conditions'
            }
          },
          {
            path: 'sites', component: SiteListComponent, data: {
              name: 'Manage Sites'
            }
          }
        ]
      },
      {
        path: 'corporate-partner',
        component: Pages,
        canActivate: [RoleGuard],
        data: {
          name: 'corporatePartner',
          expectedRole: [roles.corporatePartner.roleId, roles.corporatePartnerUser.roleId]
        },
        children: [
          {
            path: 'dashboard', component: CpDashboardComponent, data: {
              name: 'Dashboard',
              menu: {
                title: 'Dashboard'
              }
            }
          }, {
            path: 'billing', component: SiteSettingComponent, data: {
              name: 'Billing',
              menu: {
                title: 'Billing'
              }
            }
          },
          {
            path: '', component: CpDashboardComponent, data: {
              name: 'amDashboard',
              menu: {
                title: 'Dashboard'
              }
            }
          },
          {
            path: 'ticket', component: ListContainerComponent, data: {
              name: 'Service Tickets',
              menu: {
                title: 'Ticket'
              }
            }
          },
          {
            path: 'statement-of-work',
            component: StatementOfWorkComponent,
            data: {
              name: 'Statement of Work'
            }
          },
          {
            path: 'terms-and-conditions',
            component: TermsAndConditionsComponent,
            data: {
              name: 'Terms and Conditions'
            }
          },
          {
            path: 'ticket/:id', component: TicketDetailComponent, data: {
              name: '',
              menu: {
                title: 'Ticket'
              }
            }
          },
          {
            path: 'manage-client', component: SiteListComponent, data: {
              name: 'Manage Clients',
              menu: {
                title: 'Manage Clients'
              }
            }

          }, {
            path: 'audit-request', component: RequestListComponent, data: {
              name: 'Audits/Proposals',
              menu: {
                title: 'Audits/Proposals'
              }
            },
          }, {
            path: 'audit-request/:id', component: AuditRequestComponent, data: {
              name: 'Audits/Proposals',
              menu: {
                title: 'Audits/Proposals'
              }
            },
          },
          {
            path: 'client-details/:id', component: CustomerDetailsComponent, data: {
              name: '',
              menu: {
                title: 'Customer Details'
              }
            }
          },
          {
            path: 'user-profile', component: UserProfileComponent, data: {
              name: '',
              menu: {
                title: 'User Profile'
              }
            }
          },
          {
            path: 'site-config/:siteId', component: SiteConfigComponent, data: {
              name: 'Site Config'
            }
          },
          {
            path: 'downgrade-product/:siteId', component: DowngradeProductComponent, data: {
              name: 'Downgrade-Product'
            }
          },
          {
            path: 'sites', component: SiteListComponent, data: {
              name: 'Manage Sites'
            }
          },
          {
            path: 'payment-history', component: PaymentHistoryComponent, data: {
              name: 'Payment History',
              menu: {
                title: 'Manage Cards'
              }
            }
          },
          {
            path: 'client-reports', component: ReportComponent, data: {
              name: 'Reports',
              menu: {
                title: 'Reporting'
              }
            }
          }, {
            path: 'amrpc-report', component: AmrpcReportComponent, data: {
              name: 'AMRPC',
              menu: {
                title: 'amrpc'
              }
            }
          },
          {
            path: 'exhibit/:type',
            component: ExhibitBComponent,
            data: {
              name: 'Terms and Conditions'
            }
          },
          {
            path: 'projects', component: ProjectsComponent, data: {
              name: 'Projects'
            }
          }
        ]
      },
    ]
  },
  {
    path: 'review/:id/:intakeTypeId', component: MasterReviewComponent, data: {
      name: '',
      menu: {
        title: 'Review'
      }
    }
  }

];

export const routing: ModuleWithProviders<PagesModule> = RouterModule.forChild(routes);
