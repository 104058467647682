import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import { ReportingService } from '../services/reporting.service';
import * as _ from 'lodash';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '@services/http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddInsightDataComponent } from '../add-insight-data/add-insight-data.component';
import { ExcelService } from '../services/excel.service';
import * as tooltipText from '@shared/text/tooltips.json';
import { InstapageReport, Menu } from '@shared/interfaces/reports';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';

@Component({
  selector: 'app-ppc-report',
  templateUrl: './ppc-report.component.html',
  styleUrls: ['./ppc-report.component.css'],
})
export class PpcReportComponent implements OnInit, PageHasTable {
  @Input() siteId;

  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  columnHeadersArray: ColumnHeader[][];
  reportParams = {
    startDate: moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY'),
    endDate: moment(moment()).subtract(1, 'months').endOf('month').format('MM/DD/YYYY'),
    _startDate: moment().subtract(1, 'months').startOf('month').format('MM/DD/YY'),
    _endDate: moment(moment()).subtract(1, 'months').endOf('month').format('MM/DD/YY'),
  };
  clicksBreakdownByAge;
  clearData = {
    leads: false,
  };
  impressionsBreakdownByAge;
  @Output() openCallTraking = new EventEmitter<any>();
  clicksBarChart;
  costAreaChart;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  instapageReportData: InstapageReport[];
  costPerLeadChart;
  totalLeadsChart;
  insightData;
  avgCpcAreaChart;
  purpleShades = ['#3D2545', '#520C7B', '#7A28A9', '#943BC9', '#B65AE9', '#D576FF', '#D7AEEA'];
  greenShades = ['#0F3430', '#19473F', '#28675C', '#36877A', '#46A99C', '#4BB5A7', '#53C7B7'];
  reportData;
  analyticsReportData;
  isInstaPage = false;
  phoneCallsChart;
  isShowPMS=false;
  selectedMenu: Menu;
  submenu: Menu[] = [
    { id: 1, name: 'Leads' },
    { id: 2, name: 'Insights' },
    { id: 3, name: 'Campaign Performance' },
  ];
  tableData = [];
  today = new Date();
  ttText: any;

  constructor(
    private reportingService: ReportingService,
    private httpService: HttpService,
    public modalService: NgbModal,
    private toastrService: ToastrService,
    private excelService:ExcelService
  ) { }

  ngOnChanges(){
    this.selectedMenu = this.submenu[0];
    this.selectedMenu.id == 3 ? this.getGoogleAdsAnalytics(this.siteId, this.reportParams) :
                            this.isInstaPage ? this.getInstaPageReportData(this.siteId, this.reportParams) :
                            this.getGoogleAdsLeads(this.siteId, this.reportParams);
  }

  ngOnInit() {
    const date = new Date();

    this.today = new Date(date.getFullYear(), date.getMonth(), 0);
    this.ttText = (tooltipText as any).default;

    this.getInstaPageReportData(this.siteId, this.reportParams, false);
  }

  /**
   * Restructures Instapage Report Data into a format compatible with tables
   * @param {Object} instapageReport
   */
  formatInstapageReportDataForTable(instapageReport): Array<{}> {
    if (instapageReport && instapageReport.hasOwnProperty('data')) {
      const rows = instapageReport.data.map(a => ({subData : a.subData, leadType: a.leadType}));

      return rows.map((a) => {
        const keyValue = a.subData.map(({instaKey, instaValue, createdOn}) => ({[instaKey]: instaValue}));
        keyValue.unshift({ 'Lead Type': this.getLeadTypeFullName(a.leadType) });
        keyValue.push({ 'Created On': a.subData[0].createdOn.split('T')[0] });

        return keyValue.reduce((a, b) => ({...a, ...b}));
      });
    }
  }

  getColumnHeadersFromTableData(tableData: Array<{}>): ColumnHeader[] {
    if (tableData) {
      return Object.keys(tableData).map((a) => ({
        field: a,
        header: a,
      }));
    }
    
    this.getTableData();
  }

  getLeadTypeFullName(leadType): string {
    switch(leadType) { 
      case "BL": { 
         return "Bookable Lead"; 
      } 
      case "QL": { 
         return "Qualified Lead"; 
      } 
      default: { 
        return "Lead"; 
     } 
    } 
  }

  /**
   * Generates an array that provides the configuration for table headers
   * @param {string[]} headers
   * @return {ColumnHeader[]} Ana array that specifies the column header configuration
   */
  generateColumnHeaders(headers: string[]): ColumnHeader[] {
    const columnHeaders = [];

    const columnWidths = {
      'Full Name': 10,
      'Phone': 7,
    };

    for (let i = 0; i < headers.length; i++) {
      const header = {
        field: headers[i],
        header: headers[i],
      };

      if (columnWidths.hasOwnProperty(headers[i])) {
        header['width'] = columnWidths[headers[i]];
      }

      columnHeaders.push(header);
    }

    return [...columnHeaders, ...this.columnHeaders];
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      'Created On': 'dateLong',
    };
  }

  /**
   * Switches to the Campaign Performance tab and queries API if necessary
   */
  loadCampaignPerformanceTab(): void {
    this.selectedMenu = { id: 3, name: 'Campaign Performance'};
    this.isShowPMS = false;
    this.isInstaPage = false;

    if (!this.analyticsReportData) {
      this.getGoogleAdsAnalytics(this.siteId, this.reportParams);
    }
  }

  loadInsightsTab(menu: Menu): void {
    this.selectedMenu = menu;
    this.isShowPMS = false;
    this.isInstaPage = false;

    if (!this.insightData) {
      this.getInsightsData();
    }
  }

  /**
   * Loads any Leads related tab based on the menu data submitted
   *
   * @param {Menu} menu The menu item configuration parameters
   */
  loadLeadsTabs(menu: Menu): void {
    this.selectedMenu = menu;
    this.isShowPMS = false;
    this.isInstaPage = false;

    if (this.clearData.leads) {
      this.clearData.leads = false;
      this.getGoogleAdsLeads(this.siteId, this.reportParams);
    }
  }

  /**
   * The Event Handler that runs when you change the date in the date picker
   *
   * @param {Date[]} dateRange An array of 2 dates, for the range of dates in the date picker
   */
  onValueChange(dateRange: Date[]): void {
    this.reportParams['startDate'] = moment(dateRange[0]).format('MM/DD/YYYY');
    this.reportParams['endDate'] = moment(dateRange[1]).format('MM/DD/YYYY');
    this.reportParams['_startDate'] = moment(dateRange[0]).format('MM/DD/YY');
    this.reportParams['_endDate'] = moment(dateRange[1]).format('MM/DD/YY');

    // TODO: Refactor this to be clearer
    this.selectedMenu.id == 3 ? this.getGoogleAdsAnalytics(this.siteId, this.reportParams) :
                                this.isInstaPage ? this.getInstaPageReportData(this.siteId, this.reportParams) :
                                this.getGoogleAdsLeads(this.siteId, this.reportParams);

    // Clear data in tabs
    this.analyticsReportData = null;
    this.insightData = null;
    this.clearData.leads = true;

    // Retrieves data for current tab
    switch (this.selectedMenu.id) {
      case 3: this.getGoogleAdsAnalytics(this.siteId, this.reportParams); break;
      case 2: this.getInsightsData(); break;
      default: this.getGoogleAdsLeads(this.siteId, this.reportParams);
    }
  }

  getGoogleAdsLeads(siteId, params) {
    let that=this;
    params['formattedStartDate'] = moment(params.startDate).format('MM/DD/YYYY');
    params['formattedEndDate'] = moment(params.endDate).format('MM/DD/YYYY');

      this.reportingService.getGoogleAdsLeads(siteId, params).subscribe((res) => {
        if (res) {
          that.setLeadsData(res);
        }
      },
        err => {
          err.error.message ? this.httpService.openErrorPopup(err.error.message) : this.toastrService.warning(err.error.warning, 'Warning');
        })
  }

  setLeadsData=(res)=>{
    this.reportData = {};
    this.reportData = res;
    if(res.isOnlineAppointmentSetup){
    this.reportData['chart'] = {
      label: ['Qualified Form Leads', 'Qualified Call Leads','Online Appointment Booking'],
      data: [this.reportData['totalQualifiedFormLeads'], this.reportData['totalProspects'],this.reportData['onlineAppointment']],
      colors: [
        {
          backgroundColor: [
            '#F1911F',
            '#943BC9',
            '#46A99C'
          ]
        }
      ]
    };
  }
  else{
    this.reportData['chart'] = {
      label: ['Qualified Form Leads', 'Qualified Call Leads'],
      data: [this.reportData['totalQualifiedFormLeads'], this.reportData['totalProspects']],
      colors: [
        {
          backgroundColor: [
            '#F1911F',
            '#943BC9'
          ]
        }
      ]
    };
  }

  }
  getGoogleAdsAnalytics(siteId, params) {
    this.resetJSLegend();
    params['formattedStartDate'] = moment(params.startDate).format('MM/DD/YYYY');
    params['formattedEndDate'] = moment(params.endDate).format('MM/DD/YYYY');

      this.reportingService.getGoogleAdsAnalytics(siteId, params).subscribe((res) => {
        if (res) {
          this.analyticsReportData = res;
          this.clicksBarChart = this.getChartData(this.analyticsReportData.monthToMonthDetail.clicks, 'monthYear', 'click', 'bar', '#F1931F');
          this.avgCpcAreaChart = this.getChartData(this.analyticsReportData.monthToMonthDetail.avgCPC, 'monthYear', 'avgCPC', 'area');
          this.costAreaChart = this.getChartData(this.analyticsReportData.monthToMonthDetail.cost, 'monthYear', 'cost', 'area');
          this.totalLeadsChart = this.getChartData(this.analyticsReportData.monthToMonthDetail.totalLeads, 'monthYear', 'totalLeads', 'bar', '#3F84E1');
          this.phoneCallsChart = this.getChartData(this.analyticsReportData.monthToMonthDetail.phoneCalls, 'monthYear', 'phoneCalls', 'area', '#3F84E1');
          this.costPerLeadChart = this.getChartData(this.analyticsReportData.monthToMonthDetail.totalLeads, 'monthYear', 'costPerLead', 'area', '#3F84E1');
          this.clicksBreakdownByAge = this.getChartData(this.analyticsReportData.age_Breakdown, 'range', 'clickPercentage', 'dough', this.greenShades);
          this.impressionsBreakdownByAge = this.getChartData(this.analyticsReportData.age_Breakdown, 'range', 'impressionPercentag', 'dough', this.purpleShades);

        }
      },
        err => {
          err.error.message ? this.httpService.openErrorPopup(err.error.message) : this.toastrService.warning(err.error.warning, 'Warning');
        })
  }

  gotoCallTrackingReport(){
    this.reportParams['adSource'] = 'PPC';
    this.openCallTraking.emit({productId: 4, params: this.reportParams});
  }

  getChartData(data, label, dataItem, chartType?, colors?) {
    let obj = {
      label: [],
      data: [],
      colors: [
        {
          backgroundColor: colors || []
        }
      ]
    }
    _.forEach(data, (item) => {
      obj['label'].push(item[label]);
      obj['data'].push(item[dataItem].toFixed(2));
    })
    if (chartType == 'bar') {
      obj['data'] = [
        { data: obj['data'], borderWidth:1 }
      ];
    }

    return obj;
  }

  downloadReport(report: string) {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = 'none';
    var element = document.getElementById('report');
    var collapsedElements = document.getElementsByClassName('collapse');
    var showLess = document.querySelectorAll('.show-less, .titleWithLink');
    var titleWithoutLink = document.querySelectorAll('.titleWithoutLink');
    _.forEach(collapsedElements, (element) => {
      element.className += ' show';
    })
    _.forEach(showLess, (element: any) => {
      element.style.display = 'none';
    })
    _.forEach(titleWithoutLink, (element: any) => {
      element.style.display = 'block';
    })
    var pagebreak ={ mode: '', before: '.before', after: '.after', avoid: '.avoid' };
    var opt = {
      margin: 0.3,
      filename: `${this.selectedMenu.name}.pdf`,
      pagebreak: pagebreak,
      jsPDF: { unit: 'in', format: 'b3', orientation: 'portrait' }
    };
    if (report == 'print') {
      html2pdf().from(element).set(opt).output('dataurlnewwindow').then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        _.forEach(showLess, (element: any) => {
          element.style.display = 'block';
        })
        _.forEach(titleWithoutLink, (element: any) => {
          element.style.display = 'none';
        })
      });
    } else {
      html2pdf().from(element).set(opt).save().then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        _.forEach(showLess, (element: any) => {
          element.style.display = 'block';
        })
        _.forEach(titleWithoutLink, (element: any) => {
          element.style.display = 'none';
        })
      });
    }


  }

  getInstaPageReportData(siteId, reportParams, switchTabs = true) {
    this.reportingService.getInstaPageReportData(siteId, reportParams).subscribe(
      (res) => {
        if (res) {
          this.instapageReportData = res;
          // Get individual table data
          for (let i = 0; i < this.instapageReportData.length; i++) {
            this.instapageReportData[i].value = this.formatInstapageReportDataForTable(res[i]);

            const pageId = res[i].pageName;

            const dataArray = res[i].data;

            // Get individual table rows
            const tableData = [];
            for (let j = 0; j < dataArray.length; j++) {
              const subData = dataArray[j].subData;

              // Format table row data
              const tableRow = {};
              for (let k = 0; k < subData.length; k++) {
                const {instaKey, instaValue} = subData[k];

                tableRow[instaKey] = instaValue;
              }

              tableData.push(tableRow);
            }

            this.tableData[pageId] = tableData;
          }

          this.manipulateReportData(this.instapageReportData);

          if (switchTabs) {
            this.instapageReportData = res;
            this.isInstaPage = true;
          }
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      },
      () => {
        this.getTableData();
      }
    );
  }


  manipulateReportData(res) {
    if(_.isEmpty(res)){
      this.instapageReportData = [];
      return;
    }
    let tblData = {};
    _.forEach(res, (form) => {
      let completeData = [];
      tblData['title'] = [];
      tblData['values'] = [];
      tblData['createdOn'] = [];
      _.forEach(form.data, (dataItem) => {
        let value = {};
        _.forEach(dataItem.subData, (item) => {
          tblData['title'].push(item.instaKey);
          // tblData['createdOn'].push(item.createdOn);
          tblData['title'] = _.uniq(tblData['title']);
          value['createdOn'] = item.createdOn;
          value[item.instaKey] = tblData['title'].indexOf(item.instaKey) >= 0 ? item.instaValue : '';
        })
        tblData['values'].push(value);
      })
      form['title'] = _.cloneDeep(tblData['title']);
      form['values'] = _.cloneDeep(tblData['values']);
      // form['createdOn'] = _.cloneDeep(tblData['createdOn']);
    })
  }
  resetJSLegend() {
    let legends = document.querySelectorAll('[id^="js-legend"]');
    [].forEach.call(legends, function (element) {
      element.id = "js-legend";
    })
  }

  openInsightPopup(params){
    var model = this.modalService.open(AddInsightDataComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
    model.componentInstance.siteId = this.siteId;
    let insightData = params[0];
    insightData['masterTypeId'] = 43;
    insightData['startDate'] = this.reportParams.startDate;
    insightData['endDate'] = this.reportParams.endDate;
    model.componentInstance.params = model.componentInstance.existingData = insightData;
    // model.componentInstance.intake = intake;
    // model.componentInstance.masterType = this.masterType.name
    model.result.then((result) => {
      this.getInsightsData();
    }, (reason) => {
      
    });
  }

  getInsightsData(insight?, mode?){
    if(this.selectedMenu.id == 2){
      let params = {
        siteId: this.siteId,
        masterTypeId: 43,
        masterId: insight ? insight.masterId : '',
        startDate: this.reportParams.startDate,
        endDate: this.reportParams.endDate
      }
      let selectedInsight;
      this.reportingService.getWebsiteInsightsData(params).subscribe((res) => {
        if (!_.isEmpty(res)) {
          insight ? selectedInsight = res : this.insightData = res;
          if(mode == 'edit'){
            this.openInsightPopup(selectedInsight);
          }
        } else {
        }
      })
    }
  }
  exportToCsv(pageId) {
    let dummyreportData=_.cloneDeep(this.instapageReportData);
    let exelData = [];
    _.forEach(dummyreportData, (form) => {
      if(form.pageId==pageId)
      {
        form.title.splice(0,0,"Form Type");
      let arrObj = form.title.reduce((a,b)=> (a[b]='',a),{});
      for (let key in arrObj){
        arrObj[key] = key;
      }
      exelData.push(form.title.reduce((a,b)=> (a[b]='',a),{}), arrObj);
      _.forEach(form.values, (val) => {
          let data = _.pick(val, form.title);
          data['Form Type']=form.pageName;
          exelData.push(data);
        })
     } })
    this.excelService.exportAsExcelFile(exelData, 'InstaPage');

  }

  openPMS(): void {
    this.reportParams['adSource'] = 2;
    this.isShowPMS = true;
    this.isInstaPage = true;
  }
}
