import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ReportingService } from '../services/reporting.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';
import { HttpService } from '../../../services/http.service';
import * as tooltipText from '@shared/text/tooltips.json';

@Component({
  selector: 'app-ll-report',
  templateUrl: './ll-report.component.html',
  styleUrls: ['./ll-report.component.css']
})
export class LlReportComponent implements OnInit {
  @Input() siteId;
  @Output() openCallTraking = new EventEmitter<any>();
  reportData;
  locations;
  selectedLocation;
  currentYear = moment().year();
  reportParams = {
    quarter: moment().subtract(1, 'quarter').startOf('quarter').format('MM/DD/YY'),
    locationId:null
  };
  quarterData;
  callTracking;
  type=0;
  selectedMenu;
  ttText: any;
  submenu = [
    { id: 1, name: 'Leads' },
    { id: 2, name: 'Google Business Profile Performance' },
  ]
  constructor(
    private reportingService: ReportingService,
    private httpService: HttpService,
    private toastrService: ToastrService
  ) {
    this.selectedMenu = this.submenu[0];
  }

  ngOnChanges(){
    this.reportParams['siteId'] = this.siteId;
    this.getReport();
  }

  ngOnInit() {
    this.quarterData = this.reportingService.getQuarters();
    this.ttText = (tooltipText as any).default;
  }
  getReport(){
    if(this.selectedMenu.id==1)
      this.getGmbLead(this.reportParams);
    else
      this.getLocationData(this.siteId);
  }
  getLocationData(siteId){
    if(this.locations){
      this.getGmbReport(this.reportParams);
      return;
    }
    this.reportingService.getLocations(siteId).subscribe((res) => {
      if (res && res.length) {
        this.locations = res;
        this.reportParams['locationId'] = this.locations[0].locationId;
        this.getGmbReport(this.reportParams);
      }
      else
        this.httpService.openErrorPopup('This service has not launched. Please check back later for reporting.');
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  getGmbReport(reportParams) {
    this.resetJSLegend();
    this.selectedLocation = _.find(this.locations, (location) => {
      return location.locationId == this.reportParams['locationId'];
    })
    if (moment(reportParams['quarter'], "MM/DD/YY", true).isValid()) {
      reportParams['startDate'] = moment(reportParams['quarter']).startOf('quarter').format('MM/DD/YYYY');
      reportParams['endDate'] = moment(reportParams['quarter']).endOf('quarter').format('MM/DD/YYYY');
    }
    if(!reportParams['locationId']){
      this.httpService.openErrorPopup('This service has not launched. Please check back later for reporting.');
      return;
    }
    this.reportingService.getGmbReport(reportParams).subscribe((res) => {
      let that=this;
      if (res) {
       that.setGMBData(res);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }
  setGMBData(res){
    this.reportData={};
    this.reportData = res;
    _.forEach(this.reportData, (location) => {
      this.getChartData(location);
    })
  }

  getChartData(location) {
    location['chart'] = {};
    location['chart']['colors'] = [
      {
        backgroundColor: [
          '#00a99b',
          '#f7941e',
          '#0084e2'
        ]
      }
    ]
    location['chart']['label'] = ['Search Impressions', 'Map Impressions'];
    location['chart']['data'] = [((location['searchImpressions'] * 100) / location ['totalImpressions']).toFixed(2), 
      ((location['mapsImpressions'] * 100) / location ['totalImpressions']).toFixed(2)];
  }

  downloadReport(report?: string) {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = 'none';
    var element = document.getElementById('accordion');
    var collapsedElements = document.getElementsByClassName('collapse');
    var showLess = document.querySelectorAll('.show-less, .drop-icon');
    _.forEach(collapsedElements, (element) => {
      element.className += ' show';
    })
    _.forEach(showLess, (element: any) => {
      element.style.display = 'none';
    })
    var opt = {
      margin: 0,
      filename: 'google-my-business-'+this.selectedMenu.name+'.pdf',
      jsPDF: { unit: 'in', format: 'b3', orientation: 'portrait' }
    };
    if (report == 'print') {
      html2pdf().from(element).set(opt).output('dataurlnewwindow').then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        _.forEach(showLess, (element: any) => {
          element.style.display = 'block';
        })
      });
    } else {
      html2pdf().from(element).set(opt).save().then(() => {
        document.getElementById("downloadButton").style.display = 'block';
        _.forEach(showLess, (element: any) => {
          element.style.display = 'block';
        })
      });
    }


  }

  // onValueChange(event) {
  //   this.reportParams.startDate = moment(event[0]).format('MM/DD/YYYY');
  //   this.reportParams.endDate = moment(event[1]).format('MM/DD/YYYY');
  //   this.getGmbReport(this.reportParams);
  // }
  resetJSLegend() {
    let legends = document.querySelectorAll('[id^="js-legend"]');
    [].forEach.call(legends, function (element) {
      element.id = "js-legend";
    })
  }
  getGmbLead(reportParams){
    if (moment(reportParams['quarter'], "MM/DD/YY", true).isValid()) {
      reportParams['startDate'] = moment(reportParams['quarter']).startOf('quarter').format('MM/DD/YYYY');
      reportParams['endDate'] = moment(reportParams['quarter']).endOf('quarter').format('MM/DD/YYYY');
    }
    this.reportingService.getGmbLead(reportParams).subscribe(res=>{
      if(res){
          this.callTracking=res;
          this.callTracking['chart'] = {
                label: ['Qualified Phone Leads'],
                data: [ this.callTracking.Lead.qualifiedLeads],
                colors: [
                  {
                    backgroundColor: [
                      '#F1911F'
                    ]
                  }
                ]
              };
      }
    },err=>{

    })
  }
  gotoCallTrackingReport(){
    this.reportParams['adSource'] = 'Google My Business';
    this.openCallTraking.emit({productId: 4, params: this.reportParams});
  }

}
