import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ReportingService } from '../services/reporting.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { AddInsightDataComponent } from '../add-insight-data/add-insight-data.component';

@Component({
  selector: 'app-blog-posts',
  templateUrl: './blog-posts.component.html',
  styleUrls: ['./blog-posts.component.css'],
  providers: [DatePipe]
})
export class BlogPostsComponent implements OnInit {
  reportData;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));
  reportParams = {
    startDate: moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY'),
    endDate: moment(moment()).subtract(1, 'months').endOf('month').format('MM/DD/YYYY'),
    _startDate: moment().subtract(1, 'months').startOf('month').format('MM/DD/YY'),
    _endDate: moment(moment()).subtract(1, 'months').endOf('month').format('MM/DD/YY')
  }
  @Input() siteId;
  @Input() intakeTypeId;
  @Input() callTracking;
  @Output() openCallTracking = new EventEmitter<any>();
  selectedMenu;
  insightData;
  submenu = [
    { id: 1, name: 'Leads' },
    { id: 2, name: 'Analytics' },
    { id: 4, name: 'Content' }
  ]
  submenuPremium = [
    //{ id: 3, name: 'Insights' },
    { id: 5, name: 'Keyword Ranking' },
    { id: 6, name: 'Organic Search Performance' },
  ];
  quarterData;
  showFormListing:boolean=false;

  constructor(
    private datePipe: DatePipe,
    public modalService: NgbModal,
    private reportingService: ReportingService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.quarterData = this.reportingService.getQuarters();
    this.reportParams['quarter'] =moment().subtract(1, 'quarter').startOf('quarter').format('MM/DD/YY');
    this.selectedMenu = this.submenu[0];
  }

  backToContact(){
    this.selectedMenu = this.submenu[0];
    this.showFormListing=false;
  }
  eventFromChild(event) {
    this.reportParams=event.params;
    this.showFormListing=event.data;
  }
  gotoCallTrackingReport(event){
    this.reportParams['adSource'] = 'Website';
    this.openCallTracking.emit({productId: event.productId, params: event.params});
  }

  downloadReport(report: string) {
    window.scroll(0,0);
    document.getElementById("downloadButton").style.display = 'none';
    var element = document.getElementById('report');
    var collapsedElements = document.getElementsByClassName('collapse');
    _.forEach(collapsedElements, (element) => {
      element.className += ' show';
    })
    var opt = {
      margin: 0.2,
      filename: `${this.selectedMenu.name}.pdf`,
      jsPDF: { unit: 'in', format: 'b3', orientation: 'portrait' }
    };
    if (report == 'print') {
      html2pdf().from(element).set(opt).output('dataurlnewwindow').then(() => {
        document.getElementById("downloadButton").style.display = 'block';
      });
    } else {
      html2pdf().from(element).set(opt).save().then(() => {
        document.getElementById("downloadButton").style.display = 'block';
      });
    }
  }

  getInsightsData(insight?, mode?){
    if (moment(this.reportParams['quarter'], "MM/DD/YY", true).isValid()) {
      this.reportParams['startDate'] = moment(this.reportParams['quarter']).startOf('quarter').format('MM/DD/YYYY');
      this.reportParams['endDate'] = moment(this.reportParams['quarter']).endOf('quarter').format('MM/DD/YYYY');
      this.reportParams['_startDate'] = moment(this.reportParams['quarter']).startOf('quarter').format('MM/DD/YY');
      this.reportParams['_endDate'] = moment(this.reportParams['quarter']).endOf('quarter').format('MM/DD/YY');
    }
    if(this.selectedMenu && this.selectedMenu.id == 3){
      let params = {
        siteId: this.siteId,
        masterTypeId: 42,
        masterId: insight ? insight.masterId : '',
        startDate: this.reportParams.startDate,
        endDate: this.reportParams.endDate
      }
      let selectedInsight;
      this.reportingService.getWebsiteInsightsData(params).subscribe((res) => {
        if (!_.isEmpty(res)) {
          insight ? selectedInsight = res : this.insightData = res;
          if(mode == 'edit'){
            this.openInsightPopup(selectedInsight);
          }
        } else {
        }
      })
    }
  }

  openInsightPopup(params){
    var model = this.modalService.open(AddInsightDataComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg' });
    model.componentInstance.siteId = this.siteId;
    let insightData = params[0];
    insightData['masterTypeId'] = 42;
    insightData['quarter']=this.reportParams['quarter']
    model.componentInstance.params = model.componentInstance.existingData = insightData;
    model.result.then((result) => {
      this.getInsightsData();
    }, (reason) => {
      
    });
  }
}
