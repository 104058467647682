import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../../services/intakes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { roles } from '@shared/master-enum.enum';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ApprovalPopupComponent } from '../../approval-popup/approval-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { MenuService } from '@core/page-header/service/menu.service';
import { AssetsComponent } from '../../basic/content/assets/assets.component';
import { HttpService } from '@services/http.service';
import { ColumnDataTypes, ColumnHeader } from '@shared/interfaces/tables';
@Component({
  selector: 'app-review-re-design',
  templateUrl: './review-re-design.component.html',
  styleUrls: ['./review-re-design.component.css'],
})
export class ReviewReDesignComponent implements OnInit {
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  @Input() intakeTypeId;
  @Input()comments;
  Comment = '';
  roles = roles;
  baseUrl = environment.imgUrl;
  @Input()intakeId ;
  savedFormData = this.intakesService.basicInfo;
  reDesignData;
  commonObj = {};
  errorMsg = [];
  currentRole;
  selectedColorList;
  @Output() editClicked = new EventEmitter<object>();
  @Output() changeStatus= new EventEmitter<any>();
  photography;

  constructor(
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    public modalService: NgbModal,
    private menuService: MenuService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    public router: Router
  ) { }
  userInfo = localStorage.getItem("userInfo") != null ? JSON.parse(localStorage.getItem("userInfo")) : { "token": "", "userId": null, "roleId": null };
  @Input() status;
  @Input() isEdit;
  disabled;

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        this.getIntakeBasic(params.id);
        this.getReDesignData(params.id);
      }
      if (params.status) {
        this.status = params.status;
      }

    });
    this.disabled = this.status == 3 || this.status == 5 ? 'disabled' : '';

    this.getCurrentRole();

    this.getTableData();
  }

  getCurrentRole(){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if(userInfo){
      this.currentRole = _.find(roles, (role) => {
        return role.roleId == userInfo.roleId;
      })
    }
  }

  getIntakeBasic(intakeId) {
    this.intakesService.getIntakeBasicReview(intakeId).subscribe((res) => {
      if (res) {
        this.selectedColorList=res.colorPalette ? res.colorPalette.split(',') : [];
        this.intakeId = res.intakeId;
        this.savedFormData = res;
        this.photography = res.photography ? res.photography.split(',') : [];
        _.assign(this.commonObj, this.savedFormData);
      }
    },
      err => {
        
      });
  }
  getReDesignData(intakeId){
    this.intakesService.getRedesignIntake(intakeId).subscribe((res)=>{
      if(res){
        this.reDesignData=res;
        _.assign(this.commonObj, this.reDesignData);
      }
    },
    err=>{
        this.toastrService.error(err.errorMsg.message,'Error');
    })
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      date: 'date',
    };

    this.columnHeaders = [
      { field: 'statusName', header: 'Status' },
      { field: 'date', header: 'Date' },
      { field: 'comment', header: 'Comment' },
    ];
  }

  checkValidation() {
    this.intakesService.updateRequiredFieldsStatus(this.commonObj);
    this.errorMsg = [];
    _.forEach(this.intakesService.basicInfoRequiredData, (menu: any) => {
      _.forEach(menu.submenu, (data) => {
        _.forEach(data.requiredFields, (item) => {
          if (!item.status) {
            this.errorMsg.push(item['errorMsg']);
          }
        });
      })
    })

    return this.errorMsg.length ? false : true;
  }

  updateIntakeApproval(data, redirectTo?) {
  //by pass validation for intake approval process
  let formValid = true;
  if(data.Status==3)
    formValid = this.checkValidation();
    if (formValid) {
      this.intakesService.updateIntakeApproval(data).subscribe((res) => {
        if (res) {
          this.changeStatus.next();
          if(data.Status==3){
            let sectionStatus = {
              "IntakeId": data.IntakeId,
              "lstIntakeSection": [{
                "ProductSectionId": 52,
                "Status": 2,
                "Percentage": 100,
                "ParentId": 50
              }]
            };
            this.postIntakeSection(sectionStatus);
            this.getIntakeProductList(this.intakeId, true);
          }
          this.toastrService.success(this.message, 'Success');
          if(redirectTo){
            this.router.navigate([redirectTo]);
          }
        }
      },
        err => {
          
        });

    } else {
      this.toastrService.error("Please complete substeps first", 'Error');
    }

  }
  //flag:Observable<any>;
  message='';
  reviewIntake() {
    this.message='Intake has been submitted successfully'
    let data = {
      IntakeId: this.intakeId,
      FamilyId: this.intakeTypeId,
      Status: 3,
      Comment: this.Comment,
      UserId: this.userInfo.userId,
      IsSubmit: 1
    };
    this.updateIntakeApproval(data);

  }

  acceptIntake() {
    this.message='Intake has been Accepted';
    let data = {
      IntakeId: this.intakeId,
      FamilyId: this.intakeTypeId,
      Status: 5,
      Comment: this.Comment,
      UserId: this.userInfo.userId,
      IsSubmit: 0
    };
    this.updateIntakeApproval(data, `${this.currentRole.url}/intake-details`);
  }
  rejectIntake() {
    this.message='Intake has been Rejected'
    let data = {
      IntakeId: this.intakeId,
      FamilyId: this.intakeTypeId,
      Status: 4,
      Comment: this.Comment,
      UserId: this.userInfo.userId,
      IsSubmit: 0
    };
    this.updateIntakeApproval(data, `${this.currentRole.url}/intake-details`);
  }


  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.intakeTypeId, sectionStatus.IntakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }



  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.updateRequiredFieldsStatus(this.intakesService.commonBasicInfoObj);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }
  Practice = true;
  style = false;
  PracticeActive = 'active';
  styleActive = '';
  openPage(menu) {
    switch (menu) {
      case 'practice':
        this.style = false;
        this.Practice = true;
        this.PracticeActive = 'active';
        this.styleActive = '';
        break;
      case 'style':
        this.style = true;
        this.Practice = false;
        this.PracticeActive = '';
        this.styleActive = 'active';
        break;
    }
  }
  goToIntakePage(menuId, sectionId){
    this.editClicked.emit({"menuId": menuId, "sectionId": sectionId});
  }

  openApprovalPopup(){
    var modal = this.modalService.open(ApprovalPopupComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    modal.componentInstance.approve = (menu) => {
      // let pendingMenu = _.find(menu, (item) => {return !item.hasOwnProperty('approvalStatus')})
      // if(!pendingMenu){
        let reqData = {
          ApprovalStatus: _.map(menu, (menuItem) => {
          return {
            intakeId: this.intakeId,
            familyId: menuItem.familyId,
            ProductSectionId: menuItem.id,
            status: menuItem.approvalStatus
          };
        })
      }
      this.postApprovalStatus(reqData);
      modal.close();
      // }else{
      //   modal.componentInstance.errorMsg = "Please fill mandatory fields";
      // }

    };
    modal.componentInstance.disapprove = () => {
    modal.close();
    };
  }

  postApprovalStatus(reqData){
    this.intakesService.postApprovalStatus(reqData).subscribe((res)=>{
      if(res){
        this.reviewIntake();
        // this.toastrService.success("df",'Success');
        // this.premiumData=res;
      }
    },
    err=>{
      this.toastrService.error(err.error.message,'Error');
    });
  }

  getIntakeProductList(intakeId, isSubmit?) {
    this.intakesService.getIntakeProductList(intakeId).subscribe((res) => {
      if (res) {
        let selectedItem = _.find(res['sfIntakeProductFamily'], (item) => {
          return item.intakeTypeId == this.intakeTypeId;
        })

        this.changeStatus.next(isSubmit);
        this.menuService.intakeProductFamily.next({ family: res['sfIntakeProductFamily'], selectedProduct: selectedItem });
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  openPhotographyPopup(photography) {
    let model = this.modalService.open(AssetsComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
        model.componentInstance.title='Your Photography';
        photography.split(",").forEach(function (file) {
          model.componentInstance.assets.push({ path: file, name: file.split('/')[file.split('/').length - 1] })
        });
        model.componentInstance.source = 'review';
  }
}
