import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import * as _ from 'lodash';
import { SiteService } from '../service/site.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from '@services/http.service';
import { DataService } from '@shared/data-service.service';
import {
  InvoiceAddress,
  InvoiceCoupon,
  InvoiceListLineItem,
  InvoiceProduct,
  InvoiceSalesTaxData,
} from '@shared/interfaces/invoices';
import { InvoiceService } from '@shared/services/invoice/invoice.service';
import { Observable } from 'rxjs';
import { ClinicLocationsService } from '@shared/services/clinic-locations/clinic-locations.service';
import { SiteDataForInvoices } from '@shared/interfaces/site';
import { InputGeneric } from '@shared/interfaces/forms';
import { DataResponse } from '@shared/interfaces/responses';
import { AddressData } from '@shared/interfaces/addresses';
import { SelectComponent } from '@shared/components/forms/select/select.component';
import { CorporatePartnerService } from 'app/components/admin/manage-corporate-partner/services/corporate-partner.service';
import { CorporatePartner } from '@shared/interfaces/client';
import { IntakesService } from 'app/components/customer/intakes/services/intakes.service';

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.css'],
})
export class CreateInvoiceComponent implements OnInit {
  @Input() corporatePartner: CorporatePartner;
  @Input() selectedSiteId;
  @Input() site: SiteDataForInvoices;

  @Output() backTopaymentHistory = new EventEmitter<any>();

  @ViewChild('locationSelectMenu') locationSelectMenu: SelectComponent;

  createInvoiceForm: UntypedFormGroup;
  canPayByCp = false;
  canPayByCard = false;
  isLoadingProducts = true;
  invoiceProducts: InvoiceProduct[] = [];
  selectedProduct: InvoiceProduct;
  pendingInvoice;// siteId;
  productList: InputGeneric[] = [];
  productListEnabled: boolean = false;
  salesTaxButtonMessage = 'Update Sales Tax';
  salesTaxIsCalculated = false;
  salesTaxTotal = 0;
  selectedShippingLocation: AddressData;
  shippingLocations: InputGeneric[] = [];
  shippingLocationsQueryComplete = false;
  shippingLocationRegistry: AddressData[] = [];
  siteIsLock: boolean;
  subtotalAmount = 0;
  totalAmount = 0;
  paymentMode;
  couponList: Observable<any>;
  discountAmount = 0;

  constructor(
    public activeModal: NgbActiveModal,
    private clinicLocationsService: ClinicLocationsService,
    private corporatePartnerService: CorporatePartnerService,
    private formBuilder: UntypedFormBuilder,
    private invoiceService: InvoiceService,
    private siteService: SiteService,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private dataService: DataService,
    private customerIntakesService: IntakesService
  ) { }

  ngOnInit() {
    this.siteIsLock = this.siteService.siteIsLock;

    this.getOneTimeCouponList();
    if (this.site !== null && this.site !== undefined) {
      this.initializeForm();
      this.customerIntakesService.GetCustomerPayInfo(this.site?.siteId).subscribe((res) => {
        if (res !== null && "isCardExists" in res) {
          this.canPayByCard = <boolean>res["isCardExists"];
        }
      });
    }

    // Initialize the product list
    this.productList = [];

    this.invoiceService.getProducts().subscribe(
      (result: DataResponse<InvoiceProduct>) => {
        if (result.success) {
          this.invoiceProducts = result.data;

          // Define the desired order of elements
          const featuredElements = [
            'google ads management',
            'google ads ad spend',
            'facebook ads management',
            'facebook ads ad spend',
            'custom design services',
            'custom development services',
            'custom content writing',
          ];

          // Sort the array based on the custom order
          this.invoiceProducts.sort((a, b) => {
            const aIndex = featuredElements.indexOf(a.name.toLowerCase());
            const bIndex = featuredElements.indexOf(b.name.toLowerCase());

            // If both elements are in the list, sort based on their order
            if (aIndex !== -1 && bIndex !== -1) {
              return aIndex - bIndex;
            }

            // If only one element is in the list, prioritize it
            if (aIndex !== -1) {
              return -1;
            }

            if (bIndex !== -1) {
              return 1;
            }

            // If none of the elements are in the list, maintain their original order
            return 0;
          });

          // Construct the dropdown list
          for (let i = 0; i < this.invoiceProducts.length; i++) {
            // TODO: Change the value of `value` to `this.invoiceProducts[i].id when the
            //  endpoint changes to accept tax data
            this.productList.push({
              text: this.invoiceProducts[i].name,
              value: this.invoiceProducts[i].id,
            });
          }
        }
      },
      (error) => {},
      () => {
        this.isLoadingProducts = false;
      }
    );

    this.getShippingLocations();
    if (this.corporatePartner == undefined) {
      this.corporatePartnerService.getCorporatePartners().subscribe((cpList) => {
        this.corporatePartner = cpList.find((cp) => cp.id == this.site.corporatePartnerId);
      })
    }
  }

  callFromParent(site: any): void {
    if (site && site.id) {
      this.site = site;
      this.selectedSiteId = site.id;
      this.siteIsLock = site.isLock;
    } else {
      this.backTopaymentHistory.next(1);
    }
    this.initializeForm();
    this.customerIntakesService.GetCustomerPayInfo(this.site.siteId).subscribe((res) => {
      if (res !== null && "isCardExists" in res) {
        this.canPayByCard = <boolean>res["isCardExists"];
      }
    });
  }

  /**
   * Returns the IDs of all the coupons in array
   *
   * @return {string[]} An array of coupon IDs
   */
  get couponIds(): string[] {
    const couponIds = [];
    const couponFormData: InvoiceCoupon = this.createInvoiceForm.get('coupon').value;

    if (couponFormData) {
      couponIds.push(couponFormData.id);
    }

    return couponIds;
  }
  /**
   * Returns the value of the coupon form Data.
   * Not sure if this will grab the sum or just the first one tbh
   * but since we only support one coupon at a time through the UI, this
   * should work for now.
   */
  get couponsValue() : number {
    const couponFormData : InvoiceCoupon = this.createInvoiceForm.get('coupon').value;
    if (couponFormData){
      return couponFormData.amount;
    }
  }

  /**
   * Whether or not the product select menus should be enabled
   */
  get enableProductSelect(): boolean {
    return this.createInvoiceForm.get('paymentMode').valid;
  }

  /**
   * Returns true if there any addresses retrieved
   */
  get hasLocations(): boolean {
    return this.shippingLocations.length > 0;
  }

  /**
   * Determines if the invoice form is valid based the sales tax calculation
   *
   * @return {Boolean} The the form validation status
   */
  get invoiceFormIsValid(): boolean {
    return this.createInvoiceForm.valid && (this.salesTaxIsCalculated || this.clientOrCPIsTaxExempt()) && this.invoiceFormPositive;
  }

  /**
   * Ensure that the invoice amount is non-negative and greater than 0.
   */
  get invoiceFormPositive(): boolean {
    return this.totalAmount > 0.0;
  }

  /**
   * The sales tax object submitted to the API
   *
   * @return {InvoiceSalesTaxData}
   */
  get invoiceSalesTaxData(): InvoiceSalesTaxData {
    return {
      address: this.addressDataToInvoiceAddress(this.selectedShippingLocation),
      couponIds: this.couponIds,
      couponsValue : this.couponsValue,
      lstLineItem: this.createInvoiceForm.get('lstLineItem').value ?? [],
      paymentMode: this.createInvoiceForm.get('paymentMode').value ?? '',
      siteId: this.selectedSiteId,
      taxExempt: this.clientOrCPIsTaxExempt() ?? null,
    };
  }

  /**
   * Flag for if payment mode for the invoice has been selected
   *
   * @return {Boolean} If the payment mode has been selected
   */
  get paymentModeIsNotSet(): boolean {
    return this.createInvoiceForm.get('paymentMode').value === '';
  }

  /**
   * Determines if the Update Sales Tax button is enabled or disabled
   *
   * @return {Boolean} If the button is meant to be disabled, this will return true
   */
  get updateSalesTaxButtonIsEnabled(): boolean {
    if (this.createInvoiceForm.valid && !this.salesTaxIsCalculated && !this.clientOrCPIsTaxExempt()) {
      return this.isTaxableAddress(this.selectedShippingLocation);
    }

    return false;
  }

  /**
   * Returns if an address is elegable for tax collection
   *
   * @param {AddressData} location The address to verified for taxability
   *
   * @return {Boolean} If the supplied address is taxable
   */
  isTaxableAddress(location: AddressData): boolean {
    if (location) {
      return ['ca', 'us'].includes(location.country.toLowerCase());
    }

    return false;
  }

  /**
   * Creates the Invoice form
   */
  initializeForm(): void {
    // allow Pay By CP if there is a value for stripeCustomerId2
    this.canPayByCp = Boolean(this.site?.oSiteSetting?.stripeCustomerId2);

    this.createInvoiceForm = this.formBuilder.group({
      invoiceDescription: new UntypedFormControl(''),
      paymentMode: new UntypedFormControl('', Validators.required),
      address: new UntypedFormControl('', [Validators.required]),
      siteId: new UntypedFormControl(this.selectedSiteId),
      lstLineItem: this.formBuilder.array([
        this.generateListItemFormGroup(),
      ]),
      coupon: new UntypedFormControl(),
    });
    this.validatePaymentMode();

    this.createInvoiceForm.valueChanges.subscribe((formValue: any) => {
      this.validatePaymentMode();
      this.updateTotalAmount();

      if(!this.selectedShippingLocation){
        this.setTextAddress()
      }
      if (this.isTaxableAddress(this.selectedShippingLocation)) {
        this.salesTaxIsCalculated = false;
      } else {
        this.salesTaxIsCalculated = true;
      }
    });
  }

  validatePaymentMode(emitEvents: boolean = false) {
    let payMode = this.createInvoiceForm.get("paymentMode").value;
    let opts = {emitEvent: emitEvents};

    if (payMode === null || payMode === '') {
      this.productListEnabled = false;
      (this.createInvoiceForm.controls.lstLineItem as UntypedFormArray).controls.forEach(lineItem => {
        lineItem.disable(opts);
        Object.keys((lineItem as UntypedFormGroup).controls).forEach(key => {
          lineItem.get(key).disable(opts);
        });
      });
    } else {
      this.productListEnabled = true;
      (this.createInvoiceForm.controls.lstLineItem as UntypedFormArray).controls.forEach(lineItem => {
        lineItem.enable(opts);
        Object.keys((lineItem as UntypedFormGroup).controls).forEach(key => {
          if(key === 'taxAmount'){
            lineItem.get(key).disable(opts);
          } else {
            lineItem.get(key).enable(opts);
          }
        });
      });
    }
  }

  resetTaxValues(){
    // reset tax values in the form
    const lineItemsFormArray = this.createInvoiceForm.get('lstLineItem') as UntypedFormArray;
    lineItemsFormArray?.controls.forEach(lineItem => {
      lineItem.get('taxAmount').setValue(0.00);
    });
    this.salesTaxTotal = 0.00;
    this.updateTotalAmount();
  }

  /**
   * Returns an object with the necessary address information
   *
   * @param {AddressData} address The unformatted address information
   *
   * @return {InvoiceAddress} The address information in the correct format
   */
  addressDataToInvoiceAddress(address: AddressData): InvoiceAddress {
    return {
      name: address.name,
      addressId: address.addressId,
      address1: address.address1,
      address2: address.address2,
      address3: address.address3,
      city: address.city,
      state: address.state,
      zip: address.zip,
      country: address.country,
      isActive: address.isActive,
      isBillingAddress: address.isBillingAddress,
      isClinicLocation: address.isClinicLocation,
      isMailingAddress: address.isMailingAddress,
      isPrimary: address.isPrimary,
    };
  }

  checkLength(value, index) {
    if (value?.toString().length > 12) {
      this.createInvoiceForm.controls.lstLineItem['controls'][index].patchValue({
        amount: value.toString().slice(0, 12),
      });
    }
  }

  /**
   * Returns if the client or CP is Tax exempt
   *
   * @return {Boolean} If the client or their CP is tax exempt
   */
  clientOrCPIsTaxExempt(): boolean {
    const paymentModeIsCorporatePartner = this.createInvoiceForm.get('paymentMode').value == 0;
    const paymentModeIsDirectToCustomer = this.createInvoiceForm.get('paymentMode').value > 0;

    if (paymentModeIsCorporatePartner) {
      return this.corporatePartner?.taxExempt ?? false;
    } else if (paymentModeIsDirectToCustomer) {
      return this.site.taxExempt;
    } else {
      return false;
    }
  }

  /**
   * Submits Invoice data to API for processing
   */
  createInvoice(): void {
    // If sales tax is required, but not calculated
    if (!this.invoiceSalesTaxData.taxExempt && !this.salesTaxIsCalculated) {
      this.toastrService.warning(
        'Please update the sales tax for this invoice',
        'Sales Tax Error'
      );

      throw new Error('The sales tax for this invoice has not been calculated');
    }

    this.createInvoiceForm.get('lstLineItem').updateValueAndValidity();

    if (this.createInvoiceForm.valid) {
      const invoice = this.createInvoiceForm.value;

      // API accepts list of couponIds for future ability to add multiple coupons if desired. We are
      // limiting to only one coupon per invoice currently in our UI.
      invoice.couponIds = [invoice.coupon?.id];
      invoice.couponsValue = invoice.coupon?.amount;
      // set address to be entire address object
      invoice.address = this.selectedShippingLocation;
      invoice.totalTaxAmount = this.salesTaxTotal;

      if (invoice) {
        this.siteService.createInvoice(invoice).subscribe(res => {
          if (res) {
            this.toastrService.success('Invoice has been created successfully', 'Success');
            this.backTopaymentHistory.next(1);
          }
        }, err => {
          this.httpService.openErrorPopup(err.error.message);
        });
      }
    } else {
      console.error('invalid form submitted');
      this.httpService.openErrorPopup(this.dataService.requiredMsg);
    }
  }

  /**
   * Generates a Form Group for each list item, ready to appended to the parent.
   *
   * @return {FormGroup} The configured formgroup for a list item
   */
  generateListItemFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      amount: new UntypedFormControl('', [Validators.required, Validators.min(.01)]),
      product: new UntypedFormControl(''),
      // productSelect and descriptionText get combined into product. They are ignored by the API.
      productSelect: new UntypedFormControl('', [Validators.required]),
      descriptionText: new UntypedFormControl(''),
      quantity: new UntypedFormControl(1, [Validators.required, Validators.min(1)]),
      taxCode: new UntypedFormControl(''),
      taxAmount: new UntypedFormControl(0.00),
      productName: new UntypedFormControl(''),
      couponAmount: new UntypedFormControl(''),
    });
  }

  /**
   * Calculates the total of a line items
   *
   * @param {InvoiceListLineItem} item The line item data
   *
   * @return {Number} The line item total
   */
  getLineItemTotal(itemIndex: number) {//: InvoiceListLineItem) {
    // Get the raw value of the form, regardless of if it is disabled.
    var item = this.createInvoiceForm.getRawValue().lstLineItem[itemIndex];
    if (item) {
      let amount = item.amount !== '' ? item.amount : 0;
      let quantity = item.quantity !== '' ? item.quantity : 0;
      return amount * quantity + (item.taxAmount ?? 0);
    }

    return 0;
  }

  /**
   * Retrieves the locations associoted with client from the API
   */
  getShippingLocations(): void {
    this.shippingLocationsQueryComplete = false;

    this.clinicLocationsService.getAddressesBySite(this.selectedSiteId).subscribe(
      (res) => {
        if (res) {
          res.map((address: AddressData) => {
            const addressData = {
              text: address.name,
              value: address.addressId,
            };

            if (address.isBillingAddress) {
              addressData.text += ' (Billing Address)';

              this.shippingLocations.unshift(addressData);
            } else {
              this.shippingLocations.push(addressData);
            }

            this.shippingLocationRegistry.push(address);
          });
        }
      },
      (error => console.error(error)),
      () => {
        this.shippingLocationsQueryComplete = true;

        if (this.shippingLocations.length > 0 && this.locationSelectMenu) {
          this.locationSelectMenu.enableSelectMenu();
        }
      }
    );
  }

  removeProduct(index) {
    this.createInvoiceForm.controls.lstLineItem['controls'].splice(index, 1);
    this.createInvoiceForm.get('lstLineItem').updateValueAndValidity();
  }

  /**
   * Pulls the correct shipping address from the registry and makes it available to the template
   */
  setTextAddress(): void {
    const shippingLocationId = this.createInvoiceForm.get('address').value;

    if (this.shippingLocationRegistry.some(location => location.addressId == shippingLocationId)) {
      this.selectedShippingLocation = this.shippingLocationRegistry.find(
        (location) => location.addressId == shippingLocationId
      );

      if (this.isTaxableAddress(this.selectedShippingLocation)) {
        this.salesTaxIsCalculated = false;
        this.salesTaxButtonMessage = 'Update Sales Tax';
      } else {
        this.salesTaxIsCalculated = true;
        this.salesTaxButtonMessage = 'Tax Exempt';
      }
    }
  }

  isProductSelectedFor(index: number) {
    if (this.selectedProduct) {
      return true;
    }
    return false;
  }

  /**
   * Calculates the product name from the product description and dropdown text
   *
   * @param {InvoiceProduct} invoiceProduct
   */
  updateProduct(invoiceProduct: UntypedFormGroup): void {
    this.selectedProduct = this.invoiceProducts.find(iProduct => iProduct.id == invoiceProduct.get('productSelect').value);
    var inlineMemo = invoiceProduct.get('descriptionText').value;
    invoiceProduct.get('taxCode').setValue(this.selectedProduct.taxCode);
    invoiceProduct.get('product').setValue(this.selectedProduct.name + (inlineMemo ? ' - ' : '') + inlineMemo);
    invoiceProduct.get('productName').setValue(this.selectedProduct.name);
  }

  /**
   * Calculates the sales tax for all of the invoice items
   */
  updateSalesTax(): void {
    const invoiceData = this.invoiceSalesTaxData;

    if (invoiceData.taxExempt) {
      this.toastrService.info('This client is tax exempt', 'Tax Exemption');

      this.salesTaxIsCalculated = true;
    } else {
      invoiceData.siteId = invoiceData.siteId.toString();

      this.invoiceService.getSalesTax(invoiceData).subscribe(
        (response) => {
          // set the sale tax form control for each line item and for the whole invoice
          // whole invoice
          this.salesTaxTotal = response.data.totalTax;

          // each line
          const productFormArray = this.createInvoiceForm.get('lstLineItem') as UntypedFormArray;

          productFormArray.controls.map((lineItem: UntypedFormGroup, i: number) => {
            // Finds the item in the avalara response. Avalara doesn't return the item amount,
            // just the total, so you have query the response for the product name + quantity +
            // the calculated total, as a sort of composite key.
            const avalaraItem = response.data.lines.find(
              aLineItem => {
                return (aLineItem.lineNumber == (i+1).toString())
              }
            );

            let taxValue = 0;

            if (avalaraItem && avalaraItem.hasOwnProperty('tax')) {
              taxValue = avalaraItem.tax;
            }

            lineItem.get('taxAmount').setValue(taxValue);
          });

          this.createInvoiceForm.get('lstLineItem').updateValueAndValidity();

          this.updateTotalAmount();
        },
        () => {},
        () => {
          this.salesTaxIsCalculated = true;
        }
      );
    }
  }

  /**
   * Calculates the subtotal value for each line item
   */
  updateTotalAmount(): void {
    // Reset totals
    this.subtotalAmount = 0;

    // Subtotal is the sum of each line item amount multiplied by quantity
    _.forEach(this.createInvoiceForm.value.lstLineItem, (item: InvoiceListLineItem) => {
      this.subtotalAmount += item.quantity * item.amount;
    });

    // Subtract coupon amount (if there is one) to get total
    this.totalAmount = this.subtotalAmount + this.salesTaxTotal;

    if (this.createInvoiceForm.value.coupon) {
      this.discountAmount = this.createInvoiceForm.value.coupon.amount ?
        this.createInvoiceForm.value.coupon.amount:
        ((this.createInvoiceForm.value.coupon.percentOff/100) * (this.subtotalAmount * 100)) / 100;
      this.totalAmount = this.subtotalAmount - this.discountAmount + this.salesTaxTotal;
    } else {
      this.discountAmount = 0;
    }
  }

  addNewProduct() {
    const listLineItem = this.createInvoiceForm.get('lstLineItem') as UntypedFormArray;
    const newRow = this.generateListItemFormGroup();

    listLineItem.push(newRow);

    this.createInvoiceForm.get('lstLineItem').updateValueAndValidity();
    newRow.updateValueAndValidity();
    this.updateTotalAmount();
  }

  getOneTimeCouponList(): void {
    this.couponList = this.siteService.getCoupons('once');
  }
}
