import { Component, OnInit,Output,EventEmitter, Input,ViewChild,AfterViewChecked} from '@angular/core';
import { UntypedFormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { IntakesService } from '../../../../services/intakes.service';
import { DataService } from '@shared/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { staffModel } from '../models/staff';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { HttpService } from '../../../../../../../services/http.service';
import { FileUploadComponent } from '@shared/file-upload/file-upload.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-staff-bios',
  templateUrl: './staff-bios.component.html',
  styleUrls: ['./staff-bios.component.css']
})
export class StaffBiosComponent implements OnInit,AfterViewChecked  {

  @ViewChild('formRef') staffForm:NgForm;
  @Input() intakeTypeId;
  intakeId:number=null;
  siteId:number=null;
  staffLists: Array<Object>=[];
  locationLists:Array<Object>=[];
  newStaff = new staffModel();
  imageSrc;
  baseUrl = environment.imgUrl;
  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public dataService:DataService,
    private httpService: HttpService,
    public modalService: NgbModal,
    ) {
      this.activatedRoute.params.subscribe(params => {
        if (params.id && params.siteId) {
          this.intakeId = params.id;
          this.siteId = params.siteId;
        }
      });

     }

  ngOnInit() {
    this.getLocation(this.intakeId, this.intakeTypeId);
    this.getStaff(this.intakeId);
  }
  ngAfterViewChecked(){
    this.textAreaAdjustonLoad();
  }

  getLocation(intakeId, intakeType) {
    this.intakesService.getLocation(intakeId, intakeType).subscribe((res: any) => {
      if (res) {
        this.locationLists = res.lstLocation;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  };
  getStaff(intakeId){
    this.intakesService.getStaff(intakeId).subscribe((res:any) => {
      if (res) {
         this.staffLists=res;
         if(this.staffLists.length ==0)
         this.staffLists.push(this.newStaff);
         
      }
    },
     err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  deleteStaff(index){
    this.staffLists.splice(index, 1);
  }
  addNewSaff(){
    if (this.staffLists == null){
       this.staffLists = [];
    }
    this.newStaff = new staffModel();
    this.staffLists.push(this.newStaff);
  }
  
  saveStaff(formData){

    if(this.staffLists){
   
     if(formData.valid){

      let allStaffLists={
        "intakeId": this.intakeId,
        "lstStaff":this.staffLists
      }
        
        this.intakesService.postStaff(allStaffLists).subscribe((response)=>{
          if(response){
            this.toastrService.success('Staff added successfully', 'Success');
          }
      },err => {
          
          this.httpService.openErrorPopup(err.error.message);
      });

   }else{
    this.httpService.openErrorPopup(this.dataService.requiredMsg);
     for (let i = 0; i < this.staffLists.length; i++) {
       let staffName = `staffName${i}`;
       let title = `title${i}`;
       //let email = `email${i}`;
       formData.controls[staffName].markAsTouched();
       formData.controls[title].markAsTouched();
       //formData.controls[email].markAsTouched();
     }
   }
  }
  }

  openUploadedFilesPopup(index){

    let model = this.modalService.open(FileUploadComponent, { size: 'lg', backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
    model.componentInstance.siteId = this.siteId;
    model.result.then((result) => {      
      if(result){
        this.imageSrc=result[0];
       _.forEach(this.staffLists, (staffList: any,itemIndex) => {
        if(index===itemIndex){
          staffList.picture=result[0]
         }
       })

      }
    }, (reason) => {
      
    });
  }
  textAreaAdjust(id) {
    let  textArea = document.getElementById(id)
    let height = parseInt(textArea.style.height, 10);
    let scrollHeight = textArea.scrollHeight;
    textArea.style.overflow = 'hidden';
    textArea.style.height = '1px';
    textArea.style.height =(height>scrollHeight?height: scrollHeight) + 'px';
  }
  textAreaAdjustonLoad() {
    for(let id=0;id<this.staffLists.length;id++ ){
      this.textAreaAdjust(id.toString());
    }
  }
}
