import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SectionStatus } from '../basic/review/section-model';
import * as _ from 'lodash';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '@shared/services/http/http.service';
import { roles } from '@shared/master-enum.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApprovalPopupComponent } from '../approval-popup/approval-popup.component';
import { IntakesService } from '../../services/intakes.service';
import { ColumnDataTypes, ColumnHeader } from '@shared/interfaces/tables';
import { MenuService } from '@core/page-header/service/menu.service';
import { environment } from 'environments/environment';
import { LlRequiredData } from '@shared/interfaces/intakes';
import { FileUploadService } from '@shared/services/file-upload.service';
import { saveAs } from 'file-saver';
import { UploadedFile } from '@shared/models/UploadedFile';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-review-local-listings',
  templateUrl: './review-ll.component.html',
  styleUrls: ['./review-ll.component.css'],
})
export class ReviewLocalListingsComponent implements OnInit {
  // inputs
  @Input() intakeTypeId;
  @Input() intakeId;
  @Input() status;
  @Input() isEdit;
  @Input() comments;
  @Input() sectionId;
  loading: boolean = false;
  errorOccurred: boolean = false;
  errorMessage: string = '';

  // outputs
  @Output() changeStatus = new EventEmitter<any>();
  @Output() editClicked = new EventEmitter<object>();
  // local variables
  userInfo = localStorage.getItem('userInfo') != null
    ? JSON.parse(localStorage.getItem('userInfo'))
    : {'token': '', 'userId': null, 'roleId': null};
  siteId;
  roles = roles;

  currentRole;
  errorMsg;
  Comment = '';
  disabled = '';
  message = '';
  selectedIntakeData;
  baseUrl = environment.imgUrl;
  columnDataTypes?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  localListingsData: any = {};
  tagTypes = [];
  // the tags for the forms but split up by type
  tagsByType: {[key: number]: Object[]} = {};
  llRequiredData: LlRequiredData;
  downloadImageErrorMessage = '';
  uploadedFilesList: UploadedFile[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private intakesService: IntakesService,
    private menuService: MenuService,
    private modalService: NgbModal,
    private router: Router,
    private toastrService: ToastrService,
    public dataService: DataService,
    private fileUploadService: FileUploadService,
    private ref: ChangeDetectorRef
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id && params.siteId) {
        this.intakeId = params.id;
        this.siteId = params.siteId;
      }
    });
  }

  ngOnInit() {
    // get local listings intake data

    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        this.getTagTypes();
        this.getLocalListingsData(params.id);
        this.disabled = this.status == 3 || this.status == 5 ? 'disabled' : '';
      }
      if (params.status) {
        this.status = params.status;
      }

    });
    this.disabled = this.status == 3 || this.status == 5 ? 'disabled' : '';

    this.getCurrentRole();

    this.getTableData();
    this.ref.detectChanges();
  }

  getLocalListingsData(intakeId) {
    this.loading = true;
    this.errorOccurred = false; // Reset error state on new load attempt

    this.intakesService.getLocalListingsIntake(intakeId).pipe(
      retry(3) // Retry up to 3 times immediately upon failure
    ).subscribe({
      next: (res) => {
        this.localListingsData = res;
        this.loading = false; // Data loaded, loading complete

        if (!this.checkValidation()) {
          this.llRequiredData = new LlRequiredData(this.localListingsData);
          this.intakesService.updateRequiredFieldsStatus(this.llRequiredData);
        }

        this.splitTagsByType();
        this.splitOfficePhotosList();
      },
      error: (err) => {
        this.loading = false; // Stop loading indication
        this.errorOccurred = true; // Set error state to true after retries have failed
        this.errorMessage = err.error.message || 'Failed to load data after retries'; // Capture and display error message
        console.error('Failed to fetch local listings data after retries:', err);
      }
    });
  }



  retryLoad() {
    // Use this.intakeId here, as it's been set at the class level
    if (this.intakeId) {
      this.getLocalListingsData(this.intakeId);
    } else {
      console.error('No intakeId available for retry');
    }
  }

  getTagTypes() {
    this.intakesService.getLocalListingsTagTypes().subscribe((res) => {
      this.tagTypes = res;
      this.tagTypes.forEach(tagType => {
        // defines a dictionary property of tagsByType with the id of the tagType (id from ad_general_master where type = 51) and
        // sets it to an empty array so it can accept tags to be pushed to it if there are any of that type.
        this.tagsByType[tagType.id] = [];
      });
    });
  }

  hasBusinessTagsByType(typeId: number): boolean {
    return this.tagsByType[typeId].length > 0
  }

  splitTagsByType() {
    // split business tags
    if (this.localListingsData && this.localListingsData.business_tags) {
      // loop through tags for the business form
      this.localListingsData.business_tags.forEach(tag => {
        // loop through the tagType list to find a match
        this.tagTypes.forEach(tagType => {
          if (tagType.id === tag.tagInfo.tag_type) {
            // push tag object to tagsByType with the key of the tagType id.
            this.tagsByType[tagType.id].push(tag);
          }
        });
      });
    }
    // split each location's tags
    if (this.localListingsData && this.localListingsData.locations) {
      // loop through location list
      this.localListingsData.locations.forEach(location => {
        if (location.location_tags) {
          // loop through tags for the location
          location.location_tags.forEach(tag => {
            // loop through the tagType list to find a match
            this.tagTypes.forEach(tagType => {
              if (tagType.id === tag.tagInfo.tag_type) {
                // push tag object to tagsByType with the key of the tagType id.
                this.tagsByType[tagType.id].push(tag);
              }
            });
          });
        }
      });
    }
  }

  splitOfficePhotosList(){
    if(this.localListingsData?.locations){
      this.localListingsData.locations.forEach(location => {
        if(location.office_photos && location.office_photos?.includes(',')){
          location.officePhotos = location.office_photos.split(',');
        } else {
          location.officePhotos = location.office_photos;
        }
      });
    }
  }

  getCurrentRole() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      this.currentRole = _.find(roles, (role) => {
        return role.roleId == userInfo.roleId;
      });
    }
  }

  openApprovalPopup() {
    var modal = this.modalService.open(ApprovalPopupComponent, {
      size: 'lg',
      backdrop: 'static',
      ariaLabelledBy: 'modal-basic-title',
    });
    modal.componentInstance.approve = (menu) => {
      let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.sectionId, this.intakeId);
      this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
          if (res) {
            this.getProductSectionData(this.intakeTypeId, this.intakeId);
            let reqData = {
              ApprovalStatus: _.map(menu, (menuItem) => {
                return {
                  intakeId: this.intakeId,
                  familyId: menuItem.familyId,
                  ProductSectionId: menuItem.id,
                  status: 1,
                };
              }),
            };
            this.postApprovalStatus(reqData);
            modal.close();
          }
        },
        err => {
          console.error(err.error.message);
          modal.componentInstance.disapprove = () => {
            modal.close();
          };
        });
    };
  }

  acceptIntake() {
    this.message = 'Intake has been Accepted';
    let data = {
      IntakeId: this.intakeId,
      FamilyId: this.intakeTypeId,
      Status: 5,
      Comment: this.Comment,
      UserId: this.userInfo.userId,
      IsSubmit: 0,
    };
    this.updateIntakeApproval(data, `${ this.currentRole.url }/intake-details`);
  }

  rejectIntake() {
    this.message = 'Intake has been Rejected';
    let data = {
      IntakeId: this.intakeId,
      FamilyId: this.intakeTypeId,
      Status: 4,
      Comment: this.Comment,
      UserId: this.userInfo.userId,
      IsSubmit: 0,
    };
    this.updateIntakeApproval(data, `${ this.currentRole.url }/intake-details`);
  }

  reviewIntake() {
    this.message = 'Intake has been submitted successfully';
    let data = {
      IntakeId: this.intakeId,
      FamilyId: this.intakeTypeId,
      Status: 3,
      Comment: this.Comment,
      UserId: this.userInfo.userId,
      IsSubmit: 1,
    };
    this.updateIntakeApproval(data);
  }

  goToIntakePage(menuId, sectionId) {
    this.editClicked.emit({'menuId': menuId, 'sectionId': sectionId});
  }

  updateIntakeApproval(data, redirectTo?) {
    let formValid = true;
    if (data.Status == 3) {
      formValid = this.checkValidation();
    }
    if (formValid) {
      this.intakesService.updateIntakeApproval(data).subscribe((res) => {
        if (res) {
          // change status to submitted
          this.changeStatus.next(true);
          if (data.Status == 3) {
            let sectionStatus = {
              "IntakeId": data.IntakeId,
              "lstIntakeSection": [{
                "ProductSectionId": 80,
                "Status": 2,
                "Percentage": 100,
                "ParentId": 79
              }]
            };
            this.postIntakeSection(sectionStatus);
            this.getIntakeProductList(this.intakeId, true);

            }
            this.toastrService.success(this.message, 'Success');
            if (redirectTo) {
              this.router.navigate([redirectTo]);
            }
          }
        },
        err => {
          
        });

    } else {
      this.toastrService.error('Please complete substeps first', 'Error');
    }
  }

  postApprovalStatus(reqData) {
    this.intakesService.postApprovalStatus(reqData).subscribe((res) => {
        if (res) {
          this.reviewIntake();
        }
      },
      err => {
        this.toastrService.error(err.error.message, 'Error');
      });
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {
      date: 'date',
    };

    this.columnHeaders = [
      {field: 'statusName', header: 'Status'},
      {field: 'date', header: 'Date'},
      {field: 'comment', header: 'Comment'},
    ];
  }

  checkValidation() {
    this.errorMsg = [];
    _.forEach(this.intakesService.basicInfoRequiredData, (menu: any) => {
      _.forEach(menu.submenu, (data) => {
        _.forEach(data.requiredFields, (item) => {
          if (!item.status) {
            this.errorMsg.push(item['errorMsg']);
          }
        });
      });
    });

    return this.errorMsg.length ? false : true;
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
        if (res) {
          this.getProductSectionData(this.intakeTypeId, sectionStatus.IntakeId);
        }
      },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error('Site doesn\'t exist', 'Error');
      }
    });
  }

  getIntakeProductList(intakeId, isSubmit?) {
    this.intakesService.getIntakeProductList(intakeId).subscribe((res) => {
        if (res) {
          this.selectedIntakeData = res;
          let selectedItem = _.find(res['sfIntakeProductFamily'], (item) => {
            return item.intakeTypeId == this.intakeTypeId;
          });

          this.changeStatus.next(isSubmit);
          this.menuService.intakeProductFamily.next({
            family: res['sfIntakeProductFamily'],
            selectedProduct: selectedItem,
          });
        }
      },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  downloadImage(imgUrl) {
    if(!imgUrl){
      this.downloadImageErrorMessage = "No image to download.";
      return;
    }
    this.fileUploadService.getUploadedFileData(imgUrl).subscribe((res: any) => {
      const fileData = new UploadedFile(res[0]);
      const blob = this.fileUploadService.b64toBlob(fileData.dataInBytes);
      saveAs(blob, fileData.name);
    },
    (error: any) => {
      this.downloadImageErrorMessage = "Failed to retrieve image data.";
      if(error && error.message){
        this.downloadImageErrorMessage += " Error message: " + error.message;
      }
    });
  }

  downloadImages(filePath) {
    if(!filePath){
      this.downloadImageErrorMessage = "No image to download.";
      return;
    }
    this.fileUploadService.getMultipleUploadedFileData(filePath).subscribe((res: any) => {

      res.forEach(fileByteArray => {
        const fileData = new UploadedFile(fileByteArray);
        const blob = this.fileUploadService.b64toBlob(fileData.dataInBytes);
        saveAs(blob, fileData.name);
      });
    },
    (error: any) => {
      this.downloadImageErrorMessage = "Failed to retrieve image data.";
      if(error && error.message){
        this.downloadImageErrorMessage += " Error message: " + error.message;
      }
    });
  }
}
