import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError
} from 'rxjs/operators';

import { HttpService } from '@shared/services/http/http.service';
import { CorporatePartnerService } from '../../admin/manage-corporate-partner/services/corporate-partner.service';
import { SiteService } from '../service/site.service';

import { PaymentHistoryComponent } from '../../customer';
import { ManageCardComponent } from '../../customer/manage-card/manage-card.component';
import { DowngradeProductComponent } from '../site-config/downgrade-product/downgrade-product.component';
import { SiteProductComponent } from '../site-product/site-product.component';
import { StartBillingComponent } from '../start-billing/start-billing.component';
import { SubscriptionPausedComponent } from '../subscription-paused/subscription-paused.component';
import { RefundsComponent } from '../refunds/refunds.component';

import { hasPermission } from '@shared/policies/permissions';
import { getCurrentRole } from '@shared/security/role-guards.service';
import { ProductListItem, Result } from '@shared/interfaces/billing';
import { CorporatePartner } from '@shared/interfaces/client';
import { SiteInfo } from '@shared/interfaces/site';
import { CreateInvoiceComponent } from '../create-invoice/create-invoice.component';

/**
 * The Billing Page's Parent Component.
 *
 * This component controls the overall layout of site-related billing actions (e.g. Payment History,
 * Manage Cards, Downgrades, etc.). We exclusively rely on query params for `siteId` and `productId`.
 *
 * Storing the site data in localStorage is done ONLY so other parts of the app that still depend
 * on localStorage won't break. This component does not read the site from localStorage. We
 * fetch site data from the backend service using the `siteId` from the query params.
 */
@Component({
  selector: 'app-site-setting',
  templateUrl: './site-setting.component.html',
  styleUrls: ['./site-setting.component.css']
})
export class SiteSettingComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * References to each child component in the template. We call their `callFromParent(...)` methods
   * whenever the parent needs them to refresh (e.g. when a new site is selected).
   */
  @ViewChild(PaymentHistoryComponent) private PaymentHistoryComponent: PaymentHistoryComponent;
  @ViewChild(ManageCardComponent) private ManageCardComponent: ManageCardComponent;
  @ViewChild(DowngradeProductComponent) private DowngradeProductComponent: DowngradeProductComponent;
  @ViewChild(SiteProductComponent) private SiteProductComponent: SiteProductComponent;
  @ViewChild(StartBillingComponent) private StartBillingComponent: StartBillingComponent;
  @ViewChild(SubscriptionPausedComponent) private SubscriptionPausedComponent: SubscriptionPausedComponent;
  @ViewChild(RefundsComponent) private RefundsComponent: RefundsComponent;
  @ViewChild(CreateInvoiceComponent) private CreateInvoiceComponent: CreateInvoiceComponent;

  /**
   * The input element for searching sites by name/domain.
   */
  @ViewChild('siteSettingSearchViewChildId') siteSettingSearchField: ElementRef;

  /**
   * Contains the complete list of corporate partners. We fetch it at init,
   * so we can display relevant data and identify the partner for the selected site.
   */
  corporatePartners: CorporatePartner[];

  /**
   * If the selected site is locked, we store that here.
   */
  siteIsLock: boolean;

  /**
   * The selected site data. This is updated whenever we pick a new site from
   * the search typeahead or the query params.
   */
  selectedSite: SiteInfo = null;

  /**
   * Synchronized with the `siteId` query param. If present, we fetch and display
   * that site’s data. If absent, no site is currently selected.
   */
  siteId: number;

  /**
   * Synchronized with the `productId` query param. This determines which "tab"
   * or section is currently displayed (Payment History, Manage Cards, etc.).
   */
  productId: number;

  /**
   * If the selected site is tied to a Corporate Partner, we store that partner’s
   * data here so child components and the template can leverage it.
   */
  corporatePartnerBelongingToSite: CorporatePartner;

  /**
   * The list of available product or billing tabs. We show these in the UI
   * so the user can switch between them.
   *
   * Note: Payment History (id=1), Manage Cards (id=2), and Start Billing (id=3)
   * are not in the ProductLists array. Instead, they're rendered by their own <li>
   * elements in the template. This way, we can manage their positions, permissions,
   * and custom logic differently, while the remaining items (4, 5, 6, 7, 8, 9) are
   * grouped together in ProductLists for convenience and iteration.
   */
  ProductLists: ProductListItem[] = [
    { id: 4, name: 'Billing Subscription' },
    { id: 5, name: 'Downgrade Package' },
    { id: 6, name: 'Create Invoice' },
    { id: 7, name: 'Paused Subscription' },
    { id: 8, name: 'Refunds' },
    { id: 9, name: 'Manage Locations' }
  ];

  /**
   * Minimal info about the current user. We do not rely heavily on this data here,
   * but it may be used to control access for certain actions or tabs.
   */
  userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : { token: '', userId: null, roleId: null };

  /**
   * If there's any error message to display in the UI, we set it here.
   */
  errorMessage: string;

  /**
   * Permission checker for our template to conditionally show/hide elements.
   */
  hasPermission = hasPermission;

  /**
   * Whether or not this user is recognized as a Corporate Partner. Determined
   * at the time of component creation.
   */
  isCpUser = false;

  /**
   * Minimal constructor
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private siteService: SiteService,
    private corporatePartnerService: CorporatePartnerService,
    private httpService: HttpService,
    private cdr: ChangeDetectorRef
  ) {
    this.isCpUser = getCurrentRole() === 'corporate-partner';
  }

  /**
   * During initialization, we read the `siteId` and `productId` from the query params.
   * If `siteId` is found, we fetch that site’s info from the backend. We also retrieve
   * the corporate partners data for potential display or usage elsewhere.
   */
  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.siteId = Number(params.get('siteId')) || null;
      this.productId = Number(params.get('productId')) || 1;

      // If siteId is available, fetch it from the backend
      if (this.siteId) {
        this.getSiteInfo(this.siteId);
      }
    });

    // Fetch corporate partners to identify the CP associated with the site
    this.getCorporatePartners();
  }

  /**
   * After the view fully initializes, we set focus to the site search field,
   * giving the user an immediate way to search/select a site.
   */
  ngAfterViewInit(): void {
    if (this.siteSettingSearchField?.nativeElement) {
      this.siteSettingSearchField.nativeElement.focus();
    }
  }

  /**
   * Clean up references on destroy
   */
  ngOnDestroy(): void {
    this.errorMessage = null;
  }

  /**
   * Retrieves the specified site’s data from our backend. Then we store
   * that site data in our component state and in localStorage (write-only).
   */
  private getSiteInfo(siteId: number): void {
    if (!siteId) return;

    this.siteService.getSiteInfo(siteId).subscribe(
      (siteDetails) => {
        this.selectedSite = siteDetails;
        this.siteIsLock = siteDetails.isLock;
        // keep the service in sync with the current lock state
        this.siteService.siteIsLock = siteDetails.isLock;

        // Write only: do not read from localStorage
        localStorage.setItem('selectedSite', JSON.stringify(siteDetails));

        // Identify the corporate partner for this site
        this.setCorporatePartnerFromSite();

        // Refresh child components
        this.refreshComponents(siteDetails);
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  /**
   * Tells each child component to update itself based on the current site
   */
  private refreshComponents(siteDetails: SiteInfo | null): void {
    if (this.PaymentHistoryComponent) {
      this.PaymentHistoryComponent.callFromParent(siteDetails);
    }
    if (this.ManageCardComponent) {
      this.ManageCardComponent.callFromParent(siteDetails);
    }
    if (this.DowngradeProductComponent) {
      this.DowngradeProductComponent.callFromParent(siteDetails);
    }
    if (this.SiteProductComponent) {
      this.SiteProductComponent.callFromParent(siteDetails);
    }
    if (this.StartBillingComponent) {
      this.StartBillingComponent.callFromParent(siteDetails);
    }
    if (this.SubscriptionPausedComponent) {
      this.SubscriptionPausedComponent.callFromParent(siteDetails);
    }
    if (this.RefundsComponent) {
      this.RefundsComponent.callFromParent(siteDetails);
    }
    if (this.CreateInvoiceComponent) {
      this.CreateInvoiceComponent.callFromParent(siteDetails);
    }

    /**
      The "Manage Locations" component is conditionally created
      only when productId === 9 (due to *ngSwitchCase="9").
      This means that each time productId changes to 9, Angular
      destroys the previous component (if any) and creates a new
      ManageLocationsComponent. Therefore, it runs its constructor
      and ngOnInit() every time, so there is no need for an
      explicit callFromParent(...) to refresh data.
    */

    this.cdr.detectChanges();
  }

  /**
   * Retrieves the list of corporate partners, used to identify which partner
   * belongs to the selected site
   */
  private getCorporatePartners(): void {
    this.corporatePartnerService.getCorporatePartners().subscribe(
      (res) => {
        if (res) {
          this.corporatePartners = res;
        }
      },
      (err) => console.error(err)
    );
  }

  /**
   * Sets the corporate partner belonging to this site, if any
   */
  private setCorporatePartnerFromSite(): void {
    if (!this.selectedSite || !this.corporatePartners) return;
    const match = this.corporatePartners.find(
      (cp) => cp.id === this.selectedSite.corporatePartnerId
    );
    if (match) {
      this.corporatePartnerBelongingToSite = match;
    }
  }

  /**
   * Clears the currently selected site from both our state and the query params,
   * then resets the active product tab to 1 (Payment History).
   */
  resetSiteSelection(): void {
    this.selectedSite = null;
    this.siteId = null;
    this.siteIsLock = false;

    // Remove from local storage as well
    localStorage.removeItem('selectedSite');

    // Reset query params
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { siteId: null, productId: 1 },
      queryParamsHandling: 'merge'
    });

    // Clear child components
    this.refreshComponents(null);
  }

  /**
   * Changes the active tab by updating the productId in the query params
   */
  changeTabs(productId: number): void {
    this.productId = productId;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { productId },
      queryParamsHandling: 'merge'
    });

    // Notify children of tab switch
    this.refreshComponents(this.selectedSite);
  }

  /**
   * Typeahead for searching sites
   */
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((term) =>
        term.length < 3
          ? of([])
          : this.siteService.searchSites(0, true, term).pipe(
              catchError(() => {
                console.error('Error searching sites');
                return of([]);
              })
            )
      )
    );

  /**
   * When a site is selected from the typeahead, we set localStorage
   * (for legacy code) and update the query param with the new siteId.
   */
  onSelectItem(item: SiteInfo): void {
    this.selectedSite = item;
    this.siteId = item.id;
    this.productId = 1;

    localStorage.setItem('selectedSite', JSON.stringify(item));


    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { siteId: item.id, productId: 1 },
      queryParamsHandling: 'merge'
    });
    this.refreshComponents(this.selectedSite)
  }

  /**
   * Format sites for the dropdown
   */
  formatter = (result: Result) => `${result.businessName} (${result.domainUrl})`;

  /**
   * How the selection displays in the input field
   */
  inputFormatter = (result: string) => `${result['name']}`;

  /**
   * If a child component notifies us that it changed something that might
   * require reloading the site or changing tabs, we handle that event here.
   */
  backToCancel(event: { productId: number; siteId: number }): void {
    this.productId = event.productId;
    this.getSiteInfo(event.siteId);

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { productId: event.productId },
      queryParamsHandling: 'merge'
    });
  }
}
