import { Component, OnInit, Input, Renderer2, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import * as _ from 'lodash';
import { SectionStatus } from '../review/section-model';
import { DataService } from '@shared/data-service.service';
import { HttpService } from '../../../../../../services/http.service';
@Component({
  selector: 'app-enhanced',
  templateUrl: './enhanced.component.html',
  styleUrls: ['./enhanced.component.css']
})
export class EnhancedComponent implements OnInit {
  @Input() submenu;
  @Input() menuId;
  @Input() intakeTypeId;
  @Input() selectedMenu;
  enhancedPages;
  premiumPages;
  premiumPagesOther;
  intakeId;
  selectedItem = {};
  enhancedForm: UntypedFormGroup;
  @Output() continue = new EventEmitter<any>();
  @Output() back = new EventEmitter<any>();
  @Output() changeStatus = new EventEmitter<any>();
  existingData;
  requiredFields = [
    { key: 'referencedContentId', errorMsg: 'This is Required Field', sectionId: 97, status: false },
    { key: 'contentWrites', errorMsg: 'This is Required Field', sectionId: 97, status: false, property: 'notes' }
  ]

  constructor(
    private intakesService: IntakesService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private _renderer2: Renderer2,
    private httpService: HttpService,
    private formBuilder: UntypedFormBuilder,
    public dataService:DataService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        this.getEnhancedData(this.intakeId);
      }
    });
    this.intakesService.progressBar.subscribe(obj => {
      if (obj != null) {
        
        _.forEach(obj.basicMenu, (menu) => {
          if (this.selectedMenu && menu.id == this.selectedMenu.id) {
            this.selectedMenu.submenu = menu.submenu;
          }
        })
      }
    });
  }

  ngOnInit() {
    
    this.initializeForm();
    this.enhancedPages = ['Hearing Aids Overview', 'Services Overview', 'Resources Overview'];
    this.setContentWritesControl(this.enhancedPages);
    this.getPremiumPagesData();
    this.getRequiredFieldData();
    if (this.selectedMenu.selectedSectionId) {
      this.selectedItem = _.find(this.selectedMenu.submenu, (item) => { return item.id == this.selectedMenu.selectedSectionId });
    }
  }

  getRequiredFieldData(gmb?) {
    let requiredFields = _.cloneDeep(this.requiredFields);
    _.forEach(this.submenu, (menu) => {
      menu.requiredFields = _.filter(requiredFields, (field: any) => {
        field.percentage = menu.percentage / requiredFields.length;
        return field.sectionId == menu.id;
      })
    })
  }

  ngAfterViewInit() {
    this.addSendibleConnect();
  }

  initializeForm() {
    this.enhancedForm = this.formBuilder.group({
      intakeId: this.intakeId ? this.intakeId : null,
      referencedContentId: new UntypedFormControl(this.existingData ? this.existingData.referencedContentId : ''),
      referencedContentOther: new UntypedFormControl(this.existingData ? this.existingData.referencedContentOther : ""),
      contentWrites: new UntypedFormControl()
    })
  }

  getEnhancedData(intakeId) {
    this.intakesService.getEnhancedData(intakeId).subscribe((res) => {
      if (res) {
        this.existingData = res;
        this.initializeForm();
        this.setContentWritesControl(this.enhancedPages);
        
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getEnhancedPagesData() {
    this.intakesService.getEnhancedPages().subscribe((res) => {
      if (res) {
        this.enhancedPages = res['masterList'];

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  setContentWritesControl(contentWrites?) {
    if (this.existingData && this.existingData.contentWrites) {
      contentWrites = this.existingData.contentWrites;
    }
    this.enhancedForm.setControl('contentWrites', this.formBuilder.array(contentWrites.map(c => this.formBuilder.group({
      title: new UntypedFormControl(c.title || ''),
      id: new UntypedFormControl(c.id || 0),
      notes: new UntypedFormControl(c.notes || '')
    }))));
  }

  getExistingNotes(id) {
    if (this.existingData) {
      let content = _.find(this.existingData.contentWrites, (data) => {
        return data.masterId == id;
      })
      return content.notes;
    }

  }

  getPremiumPagesData() {
    this.intakesService.getReferenceContent().subscribe((res) => {
      if (res) {
        this.premiumPages = res['masterList'];
        this.premiumPagesOther = _.find(this.premiumPages, (page) => {
          return page.name.toLowerCase() == 'other';
        });
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  saveFormData(form, mode?) {
    this.intakesService.saveEnhancedIntakeData(form.value).subscribe(res => {
      if (res) {
        this.toastrService.success(this.dataService.successMsg, 'Success');
        this.intakesService.updateRequiredFieldsStatus(this.existingData);
        let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.submenu[0].id, this.intakeId);
        this.postIntakeSection(sectionStatus);
        if (mode == 'continue') {
          this.goToNext('parent');
        }
      } else {

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      })
  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.submenu[0].familyId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  addSendibleConnect() {
    let script = this._renderer2.createElement('script');
    let widget = document.getElementById('client-connect-widget');
    script.type = `text/javascript`;
    script.text = `!function(){var e=document,t=e.createElement("iframe");window.addEventListener("message",function(e){if(!e || !e.data || !e.data.height){return;}t.height=(parseInt(e.data.height, 10)+45)+"px"},!1),t.style.border="0",t.style.width="100%",t.id="client-connect-iframe",t.src="https://api.sendible.com/api/v2/client_connect_widget.json?id=89",e.getElementById("client-connect-widget").appendChild(t)}();
		`;
    this._renderer2.appendChild(widget.parentNode, script);
  }
  goBack(menu?) {
    if (menu == 'parent') {
      this.back.emit();
    } else {
      let selectedItem = this.selectedItem;
      if (selectedItem['id'] !== 97) {
        let index = _.findIndex(this.selectedMenu.submenu, function (menu: any) {
          return menu.id == selectedItem['id'];
        });
        this.selectedItem = this.selectedMenu.submenu[index - 1];
      } else {
        this.selectedItem = {};
      }
      window.scroll(0,0);
    }
  }
  goToNext(menu?) {
    if (menu == 'parent') {
      this.continue.emit();
    } else {
      let selectedItem = this.selectedItem;
      let index = _.findIndex(this.selectedMenu.submenu, function (men: any) {
        return menu.id == selectedItem['id'];
      });
      this.selectedItem = this.selectedMenu.submenu[index + 1];
      window.scroll(0,0);
    }
  }
  openPage(item?) {
    this.selectedItem = item ? item : {id: 0};
  }
  changeIntakeStatus() {
    this.changeStatus.next();
  }
}
