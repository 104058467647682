import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CorporatePartnerService } from './services/corporate-partner.service';
import { UserService } from '../users/services/user-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { roles } from '@shared/master-enum.enum';
import { HttpService } from '@services/http.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCpComponent } from './add-cp/add-cp.component';
import { SiteService } from '../../site/service/site.service';
import { ColumnDataTypes, ColumnHeader, PageHasTable } from '@shared/interfaces/tables';
import { Program } from '@shared/interfaces/programs';
import { TableGenericComponent } from '@shared/components/tables/table-generic/table-generic.component';

@Component({
  selector: 'app-manage-corporate-partner',
  templateUrl: './manage-corporate-partner.component.html',
  styleUrls: ['./manage-corporate-partner.component.css'],
})
export class ManageCorporatePartnerComponent implements OnInit, PageHasTable {
  columnDataTypes ?: ColumnDataTypes;
  columnHeaders: ColumnHeader[];
  cps: Program[];
  currentRole;
  nestedTableColumnHeaders: ColumnHeader[];
  returnUrl;
  userInfo = JSON.parse(localStorage.getItem('userInfo'));

  @ViewChild('dit') table: TableGenericComponent;
  constructor(
    private corporatePartnerService:CorporatePartnerService,
    private toastrService: ToastrService,
    private userService: UserService,
    private router: Router,
    private httpService: HttpService,
    private siteService: SiteService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      this.currentRole = urlPath[urlPath.length - 1].path;
    });
  }

  ngOnInit() {
    this.getCorporatePartner();

    this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');

    this.getTableData();
  }

  /**
   * Retrieves all of the Programs from the API
   */
  getCorporatePartner(): void {
    this.corporatePartnerService.getCpData().subscribe(
      (res) => {
        if (res) {
          this.cps = res;

          for (let i = 0; i < this.cps.length; i++) {
            const {name, users} = this.cps[i];

            this.cps[i]['nameWithUsersTotal'] = `${name} (${users.length} users)`;
          }
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      }
    );
  }

  /**
   * Gets data for the tables and sets the column headers
   */
  getTableData(): void {
    this.columnDataTypes = {};

    this.columnHeaders = [
      { field: 'nameWithUsersTotal', header: 'Name' },
    ];

    this.nestedTableColumnHeaders = [
      { field: 'userName', header: 'Username' },
      { field: 'phone', header: 'Phone' },
      { field: 'email', header: 'Email' },
    ];
  }

  impersonate(cp) {
    localStorage.setItem('parentInfo', localStorage.getItem('userInfo'));

    const data = {
      userId: cp.userId,
    };

    this.userService.impersonateUser(data).subscribe(
      (res) => {
        if (res) {
          localStorage.setItem('userInfo', JSON.stringify(res));

          this.getUserProfile(res);
        }
      },
      (err) => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getUserProfile(user) {
    this.userService.getUserProfile(user.userId).subscribe((res) => {
      if (res) {
        this.userService.UserProfile = res;
        let site: any;
        if (res.site.length && user.roleId != roles.corporatePartner.roleId && user.roleId != roles.corporatePartnerUser.roleId) {
          let impersonateSite: any = _.find(res.site, (site) => {
            return site.siteId == localStorage.getItem('impersonateSite');
          })
          site = {
            siteId: impersonateSite ? impersonateSite.siteId : res.site[0].siteId,
            domainUrl: impersonateSite ? impersonateSite.siteName : res.site[0].domainUrl
          }
          localStorage.setItem('selectedSite', JSON.stringify(site));
        }
        this.goToDashboard(user);
      }
    },
      err => {
        throw err;
      })
  }

  goToDashboard(res) {
    if (this.returnUrl)
      this.router.navigateByUrl(this.returnUrl);
    else {
      let role = _.find(roles, (role) => {
        return role.roleId == res.roleId;
      })
      this.router.navigateByUrl(role.url);
    }
  }

  openAddCpPopup(){
    var model = this.modalService.open(AddCpComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    model.result.then((cp) => {
      if (cp) {
        cp = JSON.parse(cp);
        let body = {
          AccountSfId: cp.id,
          Name: cp.name
        }
        this.siteService.addCp(body).subscribe(res => {
          if (res) {
            this.toastrService.success('CP has been added successfully', 'Success');
            this.getCorporatePartner();
          }
        }, err => {
          throw err;
        })
      }
    }, (reason) => {
      
    });
  }

}
