import { Component, OnInit, Renderer2, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { IntakesService } from '../../../services/intakes.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, UntypedFormControl, Validators } from '@angular/forms';
import { HttpService } from '../../../../../../services/http.service';
import * as _ from 'lodash';
import { SectionStatus } from '../review/section-model';

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.css']
})
export class PremiumComponent implements OnInit {

  intakeId;
  premiumForm: UntypedFormGroup;
  specialServiceData;
  existingData;
  referenceData;
  referenceDataOther;
  premiumPages;
  selectedIntakeData;
  selectedItem = {};
  @Input() intakeTypeId;
  @Input() selectedMenu;
  @Input() submenu;
  @Output() continue = new EventEmitter<object>();
  @Output() back = new EventEmitter<object>();
  @Input() menuId;
  @Output() changeStatus = new EventEmitter<any>();
  requiredFields = [
    
  ]

  constructor(
    private intakesService: IntakesService,
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private _renderer2: Renderer2,
    private toastrService: ToastrService
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.intakeId = params.id;
        this.getIntakeProductList(this.intakeId);
        this.initializeForm();
      }
    });
    this.intakesService.progressBar.subscribe(obj => {
      if (obj != null) {
        
        _.forEach(obj.basicMenu, (menu) => {
          if (this.selectedMenu && menu.id == this.selectedMenu.id) {
            this.selectedMenu.submenu = menu.submenu;
          }
        })
      }
    });
  }

  ngOnInit() {
    this.getSpecialServiceData();
    this.getPremiumPagesData();
    this.getReferenceContentData();
    this.getRequiredFieldData();
    this.getIntakePremium(this.intakeId);
    if (this.selectedMenu.selectedSectionId) {
      this.selectedItem = _.find(this.selectedMenu.submenu, (item) => { return item.id == this.selectedMenu.selectedSectionId });
    }
  }
  getIntakeProductList(intakeId) {
    this.intakesService.getIntakeProductList(intakeId).subscribe((res) => {
      if (res) {
        this.selectedIntakeData = res;
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }
  getRequiredFieldData() {
    let requiredFields = _.cloneDeep(this.requiredFields);
    _.forEach(this.submenu, (menu) => {
      menu.requiredFields = _.filter(requiredFields, (field) => {
        field.percentage = menu.percentage / requiredFields.length;
        return field.sectionId == menu.id;
      })
    })
    let premiumMenu: any = _.find(this.intakesService.basicInfoRequiredData, (data: any) => {
      return data.id == this.menuId;
    })
    premiumMenu.submenu = this.submenu;
  }

  ngAfterViewInit() {
    // this.addSendibleConnect();
  }

  initializeForm() {
    this.premiumForm = this.formBuilder.group({
      intakeId: this.intakeId,
      isGmb: new UntypedFormControl(),
      gmbEmail: new UntypedFormControl(this.existingData ? this.existingData.gmbEmail : ''),
      gmbPassword: new UntypedFormControl(),
      gmbConfirmPassword: new UntypedFormControl(),
      gmbRecoveryEmail: new UntypedFormControl(this.existingData ? this.existingData.gmbRecoveryEmail : ''),
      gmbdob: new UntypedFormControl(this.existingData ? this.existingData.gmbdob : ''),
      gmbGender: new UntypedFormControl(this.existingData ? this.existingData.gmbGender : ''),
      gmbRecoveryMobile: new UntypedFormControl(this.existingData ? this.existingData.gmbRecoveryMobile : ''),
      firstName: new UntypedFormControl(this.existingData ? this.existingData.firstName : ''),
      lastName: new UntypedFormControl(this.existingData ? this.existingData.lastName : ''),
      email: new UntypedFormControl(this.existingData ? this.existingData.email : ''),
      dob: new UntypedFormControl(this.existingData ? this.existingData.dob : ''),
      gender: new UntypedFormControl(this.existingData ? this.existingData.gender : ''),
      recoveryMobile: new UntypedFormControl(this.existingData ? this.existingData.recoveryMobile : ''),
      businessName: new UntypedFormControl(this.existingData ? this.existingData.businessName : ''),
      hoursOperation: new UntypedFormControl(this.existingData ? this.existingData.hoursOperation : ''),
      businessOwner: new UntypedFormControl(this.existingData ? this.existingData.businessOwner : ''),
      isPrevBusinessName: new UntypedFormControl((this.existingData && this.existingData.prvBusinessName) ? true : ''),
      isPrevBusinessNumber: new UntypedFormControl((this.existingData && this.existingData.prvBusinessNumber) ? true : ''),
      isPrevBusinessUrl: new UntypedFormControl((this.existingData && this.existingData.prvBusinessUrl) ? true : ''),
      prvBusinessName: new UntypedFormControl(this.existingData ? this.existingData.prvBusinessName : ''),
      prvBusinessNumber: new UntypedFormControl(this.existingData ? this.existingData.prvBusinessNumber : ''),
      prvBusinessUrl: new UntypedFormControl(this.existingData ? this.existingData.prvBusinessUrl : ''),
      businessEstablishmentYear: new UntypedFormControl(this.existingData ? this.existingData.businessEstablishmentYear : ''),
      specialServices: new UntypedFormControl(this.existingData ? this.existingData.specialServices : ''),
      referencedContentId: new UntypedFormControl(),
      referencedContentOther: new UntypedFormControl(this.existingData ? this.existingData.referencedContentOther : ''),
      websitePromotion: new UntypedFormControl(),
      contentWrites: new UntypedFormControl()
    }, { validator: this.checkPasswords('gmbPassword', 'gmbConfirmPassword') })
  }

  checkPasswords(firstField, secondField) { // here we have the 'passwords' group
    return (c: UntypedFormGroup) => {

      return (c.controls && c.controls[firstField].value == c.controls[secondField].value) ? null : {
        passwordsEqual: {
          valid: false
        }
      };
    }
  }

  getSpecialServiceData() {
    this.intakesService.getSmmSpecialServiceData().subscribe((res) => {
      if (res) {
        this.specialServiceData = res['masterList'];
        _.forEach(this.specialServiceData, (service) => {
          if (service.name.includes('{xx}')) {
            service['textbox'] = '';
            service.tempName = service.name.replace(/{xx}/g, "");
          }
        });
        this.setPromotionControl();
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getPremiumPagesData() {
    this.intakesService.getPremiumPages().subscribe((res) => {
      if (res) {
        this.premiumPages = res['masterList'];
        // this.setContentWritesControl();
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  setPromotionControl() {
    this.premiumForm.setControl('websitePromotion', this.formBuilder.array(this.specialServiceData.map(c => this.formBuilder.group({
      checked: new UntypedFormControl(this.getExistingData('websitePromotion', 'checked', c)),
      name: new UntypedFormControl(c.name),
      tempName: new UntypedFormControl(c.tempName ? c.tempName : c.name),
      textbox: new UntypedFormControl(this.getExistingData('websitePromotion', 'textbox', c) || c.textbox),
      id: new UntypedFormControl(c.id),
      masterTypeId: new UntypedFormControl(c.masterTypeId),
      description: new UntypedFormControl(c.description)
    }))))
  }

  getExistingData(control, field, value) {
    if (this.existingData) {
      let data = _.find(this.existingData[control], (item) => {
        return item.id == value.id;
      })
      if (data) {
        if (field == 'checked') {
          let checked = true;
          return checked;
        }
        else if (field == 'textbox') {
          return data.name.substring(data.name.indexOf("{") + 1, data.name.indexOf("}"));
        }
      }
    }

  }

  getReferenceContentData() {
    this.intakesService.getReferenceContent().subscribe((res) => {
      if (res) {
        this.referenceData = res['masterList'];
        this.referenceDataOther = _.find(this.referenceData, (page) => {
          return page.name.toLowerCase() == 'other';
        });
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getIntakePremium(intakeId) {
    this.intakesService.getIntakePremium(intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.existingData = res;
        // this.initializeForm();
        // this.setContentWritesControl();
        // this.setPromotionControl();
        this.intakesService.updateRequiredFieldsStatus(res);
      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  // setContentWritesControl() {
  //   let data = this.existingData;
  //   this.premiumForm.setControl('contentWrites', this.formBuilder.array(this.premiumPages.map(c => this.formBuilder.group({
  //     masterId: new FormControl(c.id),
  //     notes: new FormControl(data ? data.contentWrites[_.findIndex(data.contentWrites, {masterId: c.id})].notes : '')
  //   }))));
  // }

  saveFormData(form) {
    form.value.websitePromotion = this.getSpecialServiceCheckedData(form.value.websitePromotion);
    this.intakesService.postPremiumPagesData(form.value).subscribe((res) => {
      if (res) {
        this.toastrService.success("Content Saved", 'Success');
        this.intakesService.updateRequiredFieldsStatus(form.value);
        // let invalidVal = _.find(form.value.contentWrites, (content) => {
        //   return _.isEmpty(content.notes);
        // })
        // if (invalidVal) {
        //   let m = _.find(this.submenu[0].requiredFields, (data) => {
        //     return data.key == 'contentWrites';
        //   })
        //   m.status = false;
        //   m.updatedPercentage = 0;
        //   this.submenu[0].status = 1
        //   this.submenu[0].updatedPercentage = this.submenu[0].updatedPercentage - m.percentage;
        // }

        let sectionStatus = new SectionStatus(this.intakesService.basicInfoRequiredData, this.submenu[0].id, this.intakeId);
        this.postIntakeSection(sectionStatus);

      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  getSpecialServiceCheckedData(specialService) {
    let specialServiceData = [];
    _.forEach(specialService, (service) => {
      service.textbox = service.textbox ? `{${service.textbox}}` : service.textbox;
      if (service.checked) {
        let value = `${service.id}${(service.textbox ? service.textbox : '')}`;
        specialServiceData.push(value);

      }
    })
    let dataString = specialServiceData.join();
    return dataString;
  }

  addSendibleConnect() {
    let script = this._renderer2.createElement('script');
    let widget = document.getElementById('client-connect-widget');
    script.type = `text/javascript`;
    script.text = `!function(){var e=document,t=e.createElement("iframe");window.addEventListener("message",function(e){if(!e || !e.data || !e.data.height){return;}t.height=(parseInt(e.data.height, 10)+45)+"px"},!1),t.style.border="0",t.style.width="100%",t.id="client-connect-iframe",t.src="https://api.sendible.com/api/v2/client_connect_widget.json?id=89",e.getElementById("client-connect-widget").appendChild(t)}();
		`;
    this._renderer2.appendChild(widget.parentNode, script);
  }

  getProductSectionData(familyId, intakeId) {
    this.intakesService.getProductList(familyId, intakeId).subscribe((res) => {
      if (!_.isEmpty(res)) {
        this.intakesService.basicMenu = this.intakesService.groupMenu(res);
        this.intakesService.updateSectionstatus(this.intakesService.basicMenu);
        this.intakesService.progressBar.next({ basicMenu: this.intakesService.basicMenu, overAllPercentage: res.OverAllPercentage });

      } else {
        this.toastrService.error("Site doesn't exist", 'Error');
      }
    })
  }

  postIntakeSection(sectionStatus) {
    this.intakesService.postIntakeSection(sectionStatus).subscribe((res) => {
      if (res) {
        this.getProductSectionData(this.submenu[0].familyId, this.intakeId);
      }
    },
      err => {
        this.httpService.openErrorPopup(err.error.message);
      });
  }

  validateField(selectedInput, textField, data) {
    if (data) {
      data && selectedInput == data.id ? this.premiumForm.get(textField).setValidators([Validators.required]) : this.premiumForm.get(textField).clearValidators();
    } else {
      selectedInput ? this.premiumForm.get(textField).setValidators([Validators.required]) : this.premiumForm.get(textField).clearValidators();
    }
    this.premiumForm.controls[textField].setValue('');
    this.premiumForm.get(textField).updateValueAndValidity();
  }

  validateListField(selectedInput, textField, list) {
    if (selectedInput) {
      let checkedField = _.filter(this.premiumForm.controls[list]['controls'], (item) => { return item.value.checked && item.value.textbox != null });
      if (checkedField) {
        _.forEach(checkedField, (field) => {
          field.get(textField).setValidators([Validators.required]);
          field.get(textField).updateValueAndValidity();
        })
      }
    } else {
      let checkedField = _.filter(this.premiumForm.controls[list]['controls'], (item) => { return !item.value.checked && item.value.textbox != null });
      if (checkedField) {
        _.forEach(checkedField, (field) => {
          field.get(textField).clearValidators();
          field.get(textField).setValue('');
          field.get(textField).updateValueAndValidity();
        })
      }
    }
  }
  goBack(menu?) {
    if (menu == 'parent') {
      this.back.emit();
    } else {
      let selectedItem = this.selectedItem;
      if (selectedItem['id'] !== 87) {
        let index = _.findIndex(this.selectedMenu.submenu, function (menu: any) {
          return menu.id == selectedItem['id'];
        });
        this.selectedItem = this.selectedMenu.submenu[index - 1];
      } else {
        this.selectedItem = {};
      }
      window.scroll(0,0);
    }
  }
  openPage(item?) {
    this.selectedItem = item ? item : {id: 0};
  }
  goToNext(menu?) {
    if (menu == 'parent') {
      this.continue.emit();
    } else {
      let selectedItem = this.selectedItem;
      let index = _.findIndex(this.selectedMenu.submenu, function (menu: any) {
        return menu.id == selectedItem['id'];
      });
      this.selectedItem = this.selectedMenu.submenu[index + 1];
      window.scroll(0,0);
    }
  }
  changeIntakeStatus() {
    this.changeStatus.next();
  }

}
