import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpService } from '@services/http.service';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
menuList;
public intakeProductFamily: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(
    private httpService: HttpService
  ) { }

  getMenu(roleId): Observable<any[]> {
    return this.httpService.getApi(`master/Menu/${roleId}`)
    .pipe(
      tap(res => {
        if(res){

          return res;
        }

    })
  );
  }
  getSiteProductTypes(siteId): Observable<any[]> {
    return this.httpService.getApi(`Intake/GetSiteProductTypes?siteId=${siteId}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

  getNotifications(SiteId,pageNo): Observable<any[]> {
    return this.httpService.getApi(`site/Notification?SiteId=${SiteId}&PageNo=${pageNo}`)
      .pipe(
        tap(res => {
          if (res) {

            return res;
          }

        })
      );
  }

}
